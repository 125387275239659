import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTime } from 'luxon';
import { useProjectImports } from '../../../hooks';
import { paths } from '../../../constants/paths';
import { DataTable } from '../../../components';
import { ProjectImportsActionsColumn } from '../components/ActionsColumn';
import { PermissionWrapper } from '../../../containers/PermissionWrapper';
import { allAdminRoles } from '../../../constants/permissionGroups';
import { useFilterParams } from '../../../hooks/filterParams';
import { Flow, ProjectImportStatus } from '../../../constants/enum';
import { DATE_FORMAT } from '../../../constants/date';

export const ListProjectImports = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [{ order, where }] = useFilterParams();
  const { data, isLoading, isFetching } = useProjectImports({
    // Refetch every 2 secs if for any retured project import work order parsing is in progress
    refetchInterval(data) {
      if (
        data?.data.some((projectImport) => projectImport.status === ProjectImportStatus.IN_PROGRESS)
      ) {
        return 2000;
      }
      return false;
    },
  });

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
        filterList: where?.id ? [where.id] : undefined,
      },
    },
    {
      label: t('translation.label.flow'),
      name: 'flow',
      options: {
        setCellProps: () => ({ style: { minWidth: '120px' } }),
        filterList: where?.flow ? [where.flow] : undefined,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                variant="standard"
                select
                label={t('translation.label.flow')}
                name="flow"
                value={filterList[index][0] ?? ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                {Object.keys(Flow).map((flow) => (
                  <MenuItem key={flow} value={flow}>
                    {t(`translation.enum.${flow}`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
        customBodyRender: (value) => <Box component="span">{t(`translation.enum.${value}`)}</Box>,
      },
    },
    {
      label: t('translation.label.projectImportStartDate'),
      name: 'startedDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '150px' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
        filterList: where?.startedDateRange
          ? Array.isArray(where?.startedDateRange)
            ? where?.startedDateRange
            : [where?.startedDateRange]
          : undefined,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0] && value[1]) {
              return [
                `${t('translation.label.minProjectImportStartDate')}: ${DateTime.fromISO(
                  value[0],
                ).toFormat(DATE_FORMAT)}`,
                `${t('translation.label.maxProjectImportStartDate')}: ${DateTime.fromISO(
                  value[1],
                ).toFormat(DATE_FORMAT)}`,
              ];
            } else if (value[0]) {
              return `${t('translation.label.minProjectImportStartDate')}: ${DateTime.fromISO(
                value[0],
              ).toFormat(DATE_FORMAT)}`;
            } else if (value[1]) {
              return `${t('translation.label.maxProjectImportStartDate')}: ${DateTime.fromISO(
                value[1],
              ).toFormat(DATE_FORMAT)}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            return (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
                  <Grid container>
                    <Grid item xs={6} paddingRight={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.minProjectImportStartDate')}
                        value={DateTime.fromISO(filterList[index][0]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][0] = newValue.toISO();
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} paddingLeft={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.maxProjectImportStartDate')}
                        value={DateTime.fromISO(filterList[index][1]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][1] = newValue.toISO();
                              if (!filterList[index][0]) {
                                filterList[index][0] = DateTime.fromISO('2000-01-01').toISO();
                              }
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </DemoContainer>
              </LocalizationProvider>
            );
          },
        },
      },
    },

    {
      label: t('translation.label.mapping'),
      name: 'mapping.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '150px' } }),
        filterList: where?.mapping ? [where.mapping] : undefined,
      },
    },
    {
      label: t('translation.label.hasHeaders'),
      name: 'hasHeaders',
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: '120px' } }),
        filterList: where?.hasHeaders ? [where.hasHeaders] : undefined,
        customBodyRender: (value) => (
          <Box component="span">{t(`translation.common.${value ? 'yes' : 'no'}`)}</Box>
        ),
      },
    },
    {
      label: t('translation.label.filename'),
      name: 'filename',
      options: {
        setCellProps: () => ({
          style: lessThanSmall
            ? { minWidth: '100%', overflowWrap: 'break-word', wordBreak: 'break-all' }
            : {
                minWidth: '200px',
                maxWidth: '400px',
                overflowWrap: 'break-word',
                wordBreak: 'break-all',
              },
        }),
        filterList: where?.filename ? [where.filename] : undefined,
      },
    },
    {
      label: t('translation.label.projectImportStatus'),
      name: 'status',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px' } }),
        filterList: where?.status ? [where.status] : undefined,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                variant="standard"
                select
                label={t('translation.label.projectImportStatus')}
                name="status"
                value={filterList[index][0] ?? ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                {Object.keys(ProjectImportStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(`translation.enum.${status}`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
        customBodyRender: (value) => {
          switch (value) {
            case ProjectImportStatus.PARSE_SUCCESS:
            case ProjectImportStatus.COMPLETE:
              return (
                <Box component="span" sx={{ color: 'success.main' }}>
                  {t(`translation.enum.${value}`)}
                </Box>
              );
            case ProjectImportStatus.ERROR:
              return (
                <Box component="span" sx={{ color: 'error.main' }}>
                  {t('translation.enum.ERROR')}
                </Box>
              );
            default:
              return <span>{t(`translation.enum.${value}`)}</span>;
          }
        },
      },
    },
    {
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: lessThanSmall ? { width: '100%' } : { width: '180px' } }),
        customBodyRenderLite: (dataIndex) => {
          const id = data?.data[dataIndex].id;
          const status = data?.data[dataIndex].status;
          const mapping = data?.data[dataIndex].mapping;
          const flow = data?.data[dataIndex].flow;
          if (id && status && flow) {
            return (
              <ProjectImportsActionsColumn
                projectImportId={data?.data[dataIndex].id}
                status={status}
                hasMapping={!!mapping}
                flow={flow}
              />
            );
          }
          return null;
        },
      },
    },
  ];

  // table component returs weird params on filter change
  // iterating over 2d array of filter values and constructing
  // filter object with properties of format { [columnName]: filterValue }
  const buildFilters = (filterList: Array<Array<string>>) => {
    if (filterList.length > 0) {
      const filters: Record<string, any> = {};
      filterList.forEach((filter, index) => {
        if (filter.length > 0) {
          const colName = columns[index].name;
          if (colName === 'startedDate') {
            const key = `where[${colName}Range]`;
            filters[key] = [];
            if (DateTime.fromISO(filter[0]).isValid) {
              filters[key].push(DateTime.fromISO(filter[0]).toFormat('y-LL-dd'));
            }
            if (DateTime.fromISO(filter[1]).isValid) {
              filters[key].push(DateTime.fromISO(filter[1]).toFormat('y-LL-dd'));
            }
          } else {
            const key = colName === 'mapping.name' ? 'where[mappingName]' : `where[${colName}]`;
            filters[key] = filter[0];
          }
        }
      });
      if (Object.keys(filters).length < 1) {
        return null;
      }
      return filters;
    }
    return null;
  };

  const buildSortOrder = (property: string, direction: string) => {
    if (property && direction) {
      const colName = property === 'mapping.name' ? 'mappingName' : property;
      const key = `order[${colName}]`;
      return { [key]: direction };
    }
    return null;
  };

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <PermissionWrapper whitelist={allAdminRoles}>
            <Grid item xs={12} sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                component={Link}
                state={location.search || null}
                to={paths.PROJECT_IMPORT_CREATE}
              >
                {t('translation.button.importNew')}
              </Button>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12}>
            <DataTable
              data={data?.data}
              columns={columns}
              count={data?.meta.count}
              isLoading={isLoading}
              isFetching={isFetching}
              customBuildFilters={buildFilters}
              customBuildSortOrder={buildSortOrder}
              title="translation.projectImportsListPage.title"
              options={{
                sortOrder: order
                  ? Object.entries(order).map(([key, value]) => ({
                      name: key === 'mappingName' ? 'mapping.name' : key,
                      direction: value.toLowerCase(),
                    }))[0]
                  : undefined,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
