import { FC, ReactNode, useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const TableActionsMenu: FC<{ children: ReactNode }> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-controls="more"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
        sx={{ p: 0.5 }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        sx={{ marginTop: 1 }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="more"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
};
