import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useCompaniesSummary,
  useDeleteProjectDocument,
  useProjectUpdateSubcontractors,
  useUploadProjectDocuments,
} from '../../../../hooks';
import { CompanyType } from '../../../../constants/enum';
import { useProjectDetailsContext } from './useProjectDetailsContext';
import { DateTime, DateTimeUnit, Interval } from 'luxon';
import { DATE_FORMAT } from '../../../../constants/date';
import { MUIDataTableColumn } from 'mui-datatables';

export const useProjectPreview = () => {
  const { projectId } = useParams();
  const {
    project,
    projectDetails,
    workOrdersPieData,
    projectDocuments,
    isDocumentsLoading,
    isProjectDetailsLoading,
    granularity,
    companyId,
    zoneId,
    setGranularity,
  } = useProjectDetailsContext();
  const { t } = useTranslation();

  const { mutate: updateSubcontractors, isLoading: isSubcontractorsUpdating } =
    useProjectUpdateSubcontractors();
  const { data: subcontractorData, isLoading: isSubcontractorsLoading } = useCompaniesSummary([
    CompanyType.SUBCONTRACTOR,
  ]);
  const { mutate: deleteDocument, isLoading: isDeletingDocument } = useDeleteProjectDocument(
    Number(projectId),
  );
  const { mutateAsync: uploadDocuments, isLoading: isUploadingDocuments } =
    useUploadProjectDocuments(Number(projectId));

  useEffect(() => {
    setSelectedSubcontractors(
      project?.subcontractors.map((sub) => ({ id: sub.id, name: sub.name })) || [],
    );
  }, [project?.subcontractors]);

  const subcontractors = useMemo(() => {
    return subcontractorData?.map((company) => ({ id: company.id, name: company.name })) || [];
  }, [subcontractorData]);

  const [openSubcontractorsModal, setOpenSubcontractorsModal] = useState(false);
  const [selectedSubcontractors, setSelectedSubcontractors] = useState(
    project?.subcontractors.map((sub) => ({ id: sub.id, name: sub.name })) || [],
  );

  const pieData = useMemo(
    () => [
      { name: t('translation.enum.ASSIGNED').toUpperCase(), value: workOrdersPieData?.assigned },
      {
        name: t('translation.enum.COMPLETED').toUpperCase(),
        value: (workOrdersPieData?.completed || 0) + (workOrdersPieData?.returned || 0),
      },
      { name: t('translation.enum.RTU').toUpperCase(), value: workOrdersPieData?.rtu },
      { name: t('translation.enum.UNABLE').toUpperCase(), value: workOrdersPieData?.unable },
      {
        name: t('translation.enum.UNASSIGNED').toUpperCase(),
        value: workOrdersPieData?.unassigned,
      },
    ],
    [workOrdersPieData, t],
  );

  const chartData = useMemo(() => {
    if (!projectDetails?.chartData || !project) {
      return [];
    }

    const unit: DateTimeUnit = granularity ? (granularity.toLowerCase() as DateTimeUnit) : 'day';

    const startDate = DateTime.min(
      DateTime.fromISO(project.startDate ?? ''),
      ...projectDetails.chartData.map((dataPoint) => DateTime.fromISO(dataPoint.date)),
    );

    const dueDate = DateTime.max(
      DateTime.fromISO(project.dueDate ?? ''),
      ...(projectDetails.chartData
        .map((dataPoint) =>
          DateTime.fromISO(dataPoint.date).isValid ? DateTime.fromISO(dataPoint.date) : null,
        )
        .filter(Boolean) as DateTime[]),
    ).plus({ [unit]: 1 });

    const intervals = Interval.fromDateTimes(startDate, dueDate)
      .splitBy({ [unit]: 1 })
      .map((d) => d.start);

    return intervals.map((date) => {
      const total = projectDetails.chartData?.find((dataPoint) =>
        DateTime.fromISO(dataPoint.date).hasSame(date, unit),
      )?.total;
      return { date: date.toFormat(DATE_FORMAT), total: total || 0 };
    });
  }, [project, projectDetails, granularity]);

  const handleSubcontractorsModalOpen = useCallback(() => {
    setOpenSubcontractorsModal(true);
  }, []);

  const handleSubcontractorsModalClose = useCallback(() => {
    setSelectedSubcontractors(
      project?.subcontractors.map((sub) => ({ id: sub.id, name: sub.name })) || [],
    );
    setOpenSubcontractorsModal(false);
  }, [project?.subcontractors]);

  const handleSubcontractorsSubmit = useCallback(() => {
    if (!isSubcontractorsUpdating) {
      updateSubcontractors({
        projectId: Number(projectId),
        subcontractors: selectedSubcontractors.map((sub) => sub.id),
      });
      handleSubcontractorsModalClose();
    }
  }, [
    projectId,
    selectedSubcontractors,
    isSubcontractorsUpdating,
    updateSubcontractors,
    handleSubcontractorsModalClose,
  ]);

  const subcontractorAverages = useMemo(() => {
    if (!projectDetails?.subcontractorAverages) {
      return [];
    }

    const averages = [...projectDetails.subcontractorAverages];

    if (!companyId && !zoneId) {
      project?.subcontractors.forEach((sub) => {
        if (!averages.map((a) => a.name).includes(sub.name)) {
          averages.push({
            name: sub.name,
            companyId: sub.id,
            last7Days: {
              total: 0,
              min: 0,
              max: 0,
            },
            last30Days: {
              total: 0,
              min: 0,
              max: 0,
            },
            remaining: 0,
            completed: 0,
          });
        }
      });
    }

    const sum = {
      name: t('translation.common.all'),
      last7Days: {
        min: averages.reduce((a, b) => a + b.last7Days.min, 0),
        max: averages.reduce((a, b) => a + b.last7Days.max, 0),
      },
      last30Days: {
        min: averages.reduce((a, b) => a + b.last30Days.min, 0),
        max: averages.reduce((a, b) => a + b.last30Days.max, 0),
      },
      remaining: averages.reduce((a, b) => a + b.remaining, 0),
      completed: averages.reduce((a, b) => a + b.completed, 0),
    };

    const sortedAverages = averages.sort((a, b) => a.name.localeCompare(b.name));

    return !companyId && !zoneId ? [...sortedAverages, sum] : sortedAverages;
  }, [t, projectDetails?.subcontractorAverages, project?.subcontractors, companyId, zoneId]);

  const subcontractorsSummaries = useMemo(() => {
    if (!projectDetails?.subcontractorsSummary) {
      return [];
    }

    const summaries = [...projectDetails.subcontractorsSummary].sort((a, b) =>
      a.companyName.localeCompare(b.companyName),
    );

    if (!companyId && !zoneId) {
      project?.subcontractors.forEach((sub) => {
        if (!summaries.map((a) => a.companyName).includes(sub.name)) {
          summaries.push({
            companyName: sub.name,
            total: 0,
            completed: 0,
            assigned: 0,
            pendingReturn: 0,
            unassigned: 0,
            returned: 0,
            completionRate: 0,
            unable: 0,
            rtu: 0,
          });
        }
      });
    }

    return summaries;
  }, [projectDetails, project?.subcontractors, companyId, zoneId]);

  const subcontractorAveragesColumns: MUIDataTableColumn[] = useMemo(
    () => [
      {
        label: t('translation.label.subcontractor'),
        name: 'name',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.maxMonthly'),
        name: 'last30Days.max',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.minMonthly'),
        name: 'last30Days.min',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.maxWeekly'),
        name: 'last7Days.max',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.minWeekly'),
        name: 'last7Days.min',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.remaining'),
        name: 'remaining',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.completed'),
        name: 'completed',
        options: {
          setCellHeaderProps: () => ({ style: { textTransform: 'uppercase !important' } }),
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
    ],
    [t],
  );

  const subcontractorSummaryColumns: MUIDataTableColumn[] = useMemo(
    () => [
      {
        label: t('translation.label.subcontractor'),
        name: 'companyName',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.meterChange'),
        name: 'completed',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.enum.RTU'),
        name: 'rtu',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.enum.UNABLE'),
        name: 'unable',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
    ],
    [t],
  );

  return {
    projectId: Number(projectId),
    project,
    projectDetails,
    isProjectDetailsLoading,
    pieData,
    projectDocuments,
    isDocumentsLoading,
    chartData,
    subcontractors,
    subcontractorsSummaries,
    subcontractorAverages,
    subcontractorAveragesColumns,
    subcontractorSummaryColumns,
    isSubcontractorsLoading,
    granularity,
    setGranularity,
    selectedSubcontractors,
    setSelectedSubcontractors,
    openSubcontractorsModal,
    setOpenSubcontractorsModal,
    handleSubcontractorsModalOpen,
    handleSubcontractorsModalClose,
    handleSubcontractorsSubmit,
    deleteDocument,
    isDeletingDocument,
    uploadDocuments,
    isUploadingDocuments,
  };
};
