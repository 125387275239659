import { FC, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline, ModeEditOutline } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../components';
import { paths } from '../../../../constants/paths';
import { useDeleteProject } from '../../../../hooks';

interface ProjectsActionsColumnProps {
  projectId: number;
  allowEdit: boolean;
}

export const ProjectsActionsColumn: FC<ProjectsActionsColumnProps> = ({ projectId, allowEdit }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useDeleteProject();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'space-evenly' } }}>
      <Tooltip title={t('translation.button.updateProject')} placement="top" arrow>
        <IconButton
          component={Link}
          size="small"
          sx={{ mx: { xs: 1, md: 0 } }}
          disabled={!allowEdit}
          to={paths.build(paths.PROJECT_UPDATE, projectId)}
          state={location.search || null}
          color="primary"
        >
          <ModeEditOutline fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('translation.button.deleteProject')} placement="top" arrow>
        <IconButton
          size="small"
          disabled={isLoading}
          onClick={handleClickOpen}
          sx={{ mx: { xs: 1, md: 0 } }}
          color="error"
        >
          <DeleteOutline fontSize="small" />
        </IconButton>
      </Tooltip>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        title="translation.projectListPage.deleteProjectModal.title"
        text="translation.projectListPage.deleteProjectModal.text"
        confirmActionButton={
          <LoadingButton
            loading={isLoading}
            color="error"
            variant="text"
            onClick={() => {
              if (!isLoading) {
                mutate(projectId);
                handleClose();
              }
            }}
          >
            {t('translation.button.delete')}
          </LoadingButton>
        }
      />
    </Box>
  );
};
