import {
  Grid,
  useTheme,
  Paper,
  Autocomplete,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button,
} from '@mui/material';
import { DataTable, PhotosAndDocuments, WorkOrdersPieChart } from '../../../components';
import { useProjectPreview } from './hooks/useProjectPreview';
import { t } from 'i18next';
import { ProjectDetailsActionModal } from './components/ProjectDetailsActionModal';
import { WorkOrdersLineChart } from './components/WorkOrdersLineChart';
import { ZoneCard } from './components/ZoneCard';
import { TimePeriod } from '../../../constants/enum';
import { PermissionWrapper } from '../../../containers';
import { allAdminRoles } from '../../../constants/permissionGroups';

export const ProjectPreview = () => {
  const theme = useTheme();

  const {
    project,
    subcontractorsSummaries,
    subcontractorAverages,
    isProjectDetailsLoading,
    pieData,
    projectDocuments,
    chartData,
    subcontractors,
    projectDetails,
    granularity,
    setGranularity,
    subcontractorAveragesColumns,
    subcontractorSummaryColumns,
    isSubcontractorsLoading,
    selectedSubcontractors,
    setSelectedSubcontractors,
    openSubcontractorsModal,
    handleSubcontractorsModalOpen,
    handleSubcontractorsModalClose,
    handleSubcontractorsSubmit,
    deleteDocument,
    uploadDocuments,
    isUploadingDocuments,
  } = useProjectPreview();

  return (
    <>
      <Paper
        sx={{
          background: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
          p: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={8} pl={{ lg: 1, xl: 1.5 }} mb={{ xs: 4, md: 2 }}>
            <Typography fontSize={20} variant="h6">
              {t('translation.projectDetailsPage.chartTitle')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            pr={{ lg: 1, xl: 1.5 }}
            mb={{ xs: 4, md: 2 }}
            display="flex"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            <ToggleButtonGroup
              value={granularity ?? TimePeriod.DAY}
              color="primary"
              exclusive
              aria-label="granularity"
              size="small"
              onChange={(_, value: TimePeriod) => setGranularity(value)}
            >
              <ToggleButton value={TimePeriod.DAY} sx={{ width: 120 }}>
                {t('translation.button.daily')}
              </ToggleButton>
              <ToggleButton value={TimePeriod.WEEK} sx={{ width: 120 }}>
                {t('translation.button.weekly')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={12} lg={8} pl={{ lg: 1, xl: 1.5 }} display="flex">
            <WorkOrdersLineChart chartData={chartData} />
          </Grid>
          <Grid item xs={12} md={12} lg={4} pr={{ lg: 1, xl: 1.5 }}>
            <WorkOrdersPieChart pieData={pieData} />
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography fontSize={20} variant="h6" mb={2}>
              {t('translation.projectDetailsPage.averageDailyChanges')}
            </Typography>
            <DataTable
              data={subcontractorAverages}
              columns={subcontractorAveragesColumns}
              isLoading={isProjectDetailsLoading}
              isFetching={false}
              options={{
                serverSide: false,
                filter: false,
                responsive: 'vertical',
                pagination: false,
                onTableChange: undefined,
              }}
            />
          </Grid>
        </Grid>
        <ProjectDetailsActionModal
          open={openSubcontractorsModal}
          handleClose={handleSubcontractorsModalClose}
          handleSubmit={handleSubcontractorsSubmit}
          title={t('translation.projectDetailsPage.updateSubcontractorsModal.title')}
          isSubmitting={false}
        >
          <Grid container>
            <Grid item xs={12} pb={2}>
              <Typography>
                {t('translation.projectDetailsPage.updateSubcontractorsModal.text')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="subcontractors"
                loading={isSubcontractorsLoading}
                loadingText={t('translation.common.loading')}
                options={subcontractors ?? []}
                getOptionLabel={(option) => option.name}
                value={selectedSubcontractors}
                onChange={(_, value) => {
                  setSelectedSubcontractors(value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label={t('translation.label.subcontractors')} />
                )}
              />
            </Grid>
          </Grid>
        </ProjectDetailsActionModal>
      </Paper>
      <Paper
        sx={{
          background: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
          p: 2,
          mt: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} maxHeight={450}>
            <Typography fontSize={20} variant="h6" mb={2}>
              {t('translation.projectDetailsPage.zoneSummary')}
            </Typography>
            {projectDetails?.zoneSummary && projectDetails?.zoneSummary.length > 0 ? (
              <Box sx={{ overflowY: 'auto', maxHeight: 380 }}>
                {projectDetails?.zoneSummary.map((zone) => (
                  <ZoneCard key={zone.zoneId ?? 'noZone'} zone={zone} />
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography mb={2}>{t('translation.projectDetailsPage.noZoneData')}</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} maxHeight={450}>
            <Grid container>
              <Grid item xs={12} sm={5} mb={2}>
                <Typography fontSize={20} variant="h6">
                  {t('translation.projectDetailsPage.subcontractors')}
                </Typography>
              </Grid>
              <PermissionWrapper whitelist={allAdminRoles} ownerId={project.company.id}>
                <Grid item xs={12} sm={7} mb={2} display="flex" justifyContent="flex-end">
                  <Button
                    sx={{ minWidth: 120 }}
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={handleSubcontractorsModalOpen}
                  >
                    {t('translation.button.changeSubcontractor')}
                  </Button>
                </Grid>
              </PermissionWrapper>
              <Grid item xs={12}>
                {subcontractorsSummaries.length > 0 ? (
                  <DataTable
                    data={subcontractorsSummaries}
                    columns={subcontractorSummaryColumns}
                    isLoading={isProjectDetailsLoading}
                    isFetching={false}
                    options={{
                      serverSide: false,
                      filter: false,
                      responsive: 'vertical',
                      pagination: false,
                      onTableChange: undefined,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: 100,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography mb={2}>
                      {t('translation.projectDetailsPage.noSubcontractorsData')}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          background: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
          p: 2,
          mt: 3,
        }}
      >
        <PhotosAndDocuments
          documents={projectDocuments ?? []}
          deleteDocument={deleteDocument}
          uploadDocuments={uploadDocuments}
          isUploadingDocuments={isUploadingDocuments}
        />
      </Paper>
    </>
  );
};
