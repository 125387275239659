import { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useRestoreProject } from '../../../hooks';
import { ConfirmationModal } from '../../../components';

interface RestoreButtonColumnProps {
  projectId: number;
}

export const RestoreButtonColumn: FC<RestoreButtonColumnProps> = ({ projectId }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useRestoreProject();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'space-evenly' } }}>
      <Button
        size="small"
        disabled={isLoading}
        onClick={handleClickOpen}
        sx={{ mx: { xs: 1, md: 0 }, minWidth: 120 }}
        color="primary"
        variant="contained"
      >
        {t('translation.button.restore')}
      </Button>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        title="translation.projectRecoveryPage.restoreProjectModal.title"
        text="translation.projectRecoveryPage.restoreProjectModal.text"
        confirmActionButton={
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => {
              if (!isLoading) {
                mutate(projectId);
                handleClose();
              }
            }}
          >
            {t('translation.button.restore')}
          </LoadingButton>
        }
      />
    </Box>
  );
};
