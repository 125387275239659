import { useCallback, useState } from 'react';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { DataTable } from '../../../components';
import { useAssetClassifications, useAssetManufacturers, useAssetSizes } from '../../../hooks';
import { ComponentsActionsColumn } from './components/ComponentsActionsColumn';
import { CreateUpdateModal } from './components/CreateUpdateModal';
import { AssetClassificationData } from '../../../hooks/assetClassifications';
import { AssetManufacturerData } from '../../../hooks/assetManufacturers';
import { AssetSizeData } from '../../../hooks/assetSizes';

export const AssetComponentsList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const assetClassifications = useAssetClassifications();
  const assetManufacturers = useAssetManufacturers();
  const assetSizes = useAssetSizes();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'Classification' | 'Manufacturer' | 'Size'>();
  const [editData, setEditData] = useState<
    AssetClassificationData | AssetSizeData | AssetManufacturerData
  >();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenEdit = useCallback(
    (
      currentType: 'Classification' | 'Manufacturer' | 'Size',
      data?: AssetClassificationData | AssetSizeData | AssetManufacturerData,
    ) => {
      setType(currentType);
      setEditData(data);
      handleOpen();
    },
    [handleOpen],
  );

  const buildTableColumns = (type: 'Classification' | 'Manufacturer' | 'Size') => {
    const columns: MUIDataTableColumn[] = [
      {
        label: t('translation.label.id'),
        name: 'id',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.name'),
        name: 'name',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
    ];

    if (type === 'Manufacturer') {
      columns.push({
        label: t('translation.label.classification'),
        name: 'classifications',
        options: {
          sort: false,
          setCellProps: () => ({ style: { minWidth: '170px' } }),
          customBodyRenderLite: (dataIndex) => {
            return assetManufacturers.data?.[dataIndex].classifications
              .map((c) => c.name)
              .sort()
              .join(', ');
          },
        },
      });
    }

    columns.push({
      label: ' ',
      name: 'id',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: lessThanSmall ? { minWidth: '55px' } : { width: '75px' } }),
        customBodyRender: (id, { tableData }) => {
          if (id) {
            const item = tableData.find((items) => items.id === id);
            return (
              <ComponentsActionsColumn data={item} type={type} handleOpenEdit={handleOpenEdit} />
            );
          }
          return null;
        },
      },
    });

    return columns;
  };

  const customFooter = (rowCount: number) => (
    <Grid
      component="tfoot"
      container
      sx={{ display: 'flex', justifyContent: 'right', py: 2, px: 4 }}
    >
      <Grid item component="tr">
        <Typography variant="body2" component="td">
          {t('translation.table.pagination.rows')}: {rowCount}
        </Typography>
      </Grid>
    </Grid>
  );

  const tableConfig: MUIDataTableOptions = {
    serverSide: false,
    filter: false,
    responsive: 'standard',
    pagination: false,
    tableBodyHeight: '450px',
    customFooter,
    onTableChange: undefined,
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
  };

  return (
    <>
      <Grid container mb={2} spacing={2} justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ mb: 2, display: { xs: 'flex', sm: 'none' }, justifyContent: 'right' }}
            >
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                onClick={() => handleOpenEdit('Manufacturer')}
              >
                {t('translation.button.add')} +
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                title={t('translation.assetModelsListPage.assetManufacturers')}
                data={assetManufacturers.data ?? []}
                columns={buildTableColumns('Manufacturer')}
                isLoading={assetManufacturers.isLoading}
                isFetching={false}
                options={{
                  serverSide: false,
                  filter: false,
                  responsive: 'simple',
                  pagination: false,
                  tableBodyMaxHeight: '450px',
                  customFooter,
                  onTableChange: undefined,
                  sortOrder: {
                    name: 'name',
                    direction: 'asc',
                  },
                  customToolbar: () => (
                    <Button
                      sx={{ minWidth: 120, display: { xs: 'none', sm: 'inline-flex' } }}
                      variant="contained"
                      onClick={() => handleOpenEdit('Manufacturer')}
                    >
                      {t('translation.button.add')} +
                    </Button>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={5}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ mb: 2, display: { xs: 'flex', sm: 'none' }, justifyContent: 'right' }}
            >
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                onClick={() => handleOpenEdit('Classification')}
              >
                {t('translation.button.add')} +
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                title={t('translation.assetModelsListPage.assetClassifications')}
                data={assetClassifications.data ?? []}
                columns={buildTableColumns('Classification')}
                isLoading={assetClassifications.isLoading}
                isFetching={false}
                options={{
                  ...tableConfig,
                  customToolbar: () => (
                    <Button
                      sx={{ minWidth: 120, display: { xs: 'none', sm: 'inline-flex' } }}
                      variant="contained"
                      onClick={() => handleOpenEdit('Classification')}
                    >
                      {t('translation.button.add')} +
                    </Button>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ mb: 2, display: { xs: 'flex', sm: 'none' }, justifyContent: 'right' }}
            >
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                onClick={() => handleOpenEdit('Size')}
              >
                {t('translation.button.add')} +
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                title={t('translation.assetModelsListPage.assetSizes')}
                data={assetSizes.data ?? []}
                columns={buildTableColumns('Size')}
                isLoading={assetSizes.isLoading}
                isFetching={false}
                options={{
                  ...tableConfig,
                  customToolbar: () => (
                    <Button
                      sx={{ minWidth: 120, display: { xs: 'none', sm: 'inline-flex' } }}
                      variant="contained"
                      onClick={() => handleOpenEdit('Size')}
                    >
                      {t('translation.button.add')} +
                    </Button>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <CreateUpdateModal type={type} data={editData} open={open} handleClose={handleClose} />
      </Grid>
    </>
  );
};
