import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';

export interface AssetManufacturerData {
  id: number;
  name: string;
  classifications: Array<{
    id: number;
    name: string;
  }>;
}

export interface AssetManufacturerInput extends Pick<AssetManufacturerData, 'name'> {
  classifications: Array<{ id: number }>;
}

const getAssetManufacturers = async () => {
  try {
    const { data } = await axiosInstance.get('/manufacturer');

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createAssetManufacturer = async (assetManufacturer: AssetManufacturerInput) => {
  try {
    const { data } = await axiosInstance.post(`/manufacturer`, assetManufacturer);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateAssetManufacturer = async (id: number, assetManufacturer: AssetManufacturerInput) => {
  try {
    const { data } = await axiosInstance.put(`/manufacturer/${id}`, assetManufacturer);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteAssetManufacturer = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/manufacturer/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useAssetManufacturers(
  options?: UseQueryOptions<AssetManufacturerData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<AssetManufacturerData[], CustomApiError>(
    ['asset-manufacturers'],
    () => getAssetManufacturers(),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      ...options,
    },
  );
}

export function useCreateAssetManufacturer(
  options?: UseMutationOptions<AssetManufacturerData, CustomApiError, AssetManufacturerInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AssetManufacturerData, CustomApiError, AssetManufacturerInput>(
    (data) => createAssetManufacturer(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newAssetManufacturer) => {
        // ✅ update detail view directly
        queryClient.setQueryData(
          ['asset-manufacturers'],
          (oldData: AssetManufacturerData[] | undefined) =>
            oldData ? [newAssetManufacturer, ...oldData] : oldData,
        );
        enqueueSnackbar(t('translation.notification.assetManufacturerCreated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useUpdateAssetManufacturer(
  options?: UseMutationOptions<
    AssetManufacturerData,
    CustomApiError,
    { id: number; data: AssetManufacturerInput }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<
    AssetManufacturerData,
    CustomApiError,
    { id: number; data: AssetManufacturerInput }
  >(({ id, data }) => updateAssetManufacturer(id, data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (updatedManufacturer) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['asset-manufacturers'],
        (oldData: AssetManufacturerData[] | undefined) => {
          if (oldData) {
            const updatedAssetManufacturers = [...oldData];
            const index = updatedAssetManufacturers.findIndex(
              (manufacturer) => manufacturer.id === updatedManufacturer.id,
            );

            if (index !== -1) {
              updatedAssetManufacturers[index] = {
                ...updatedAssetManufacturers[index],
                ...updatedManufacturer,
              };
            }
            return updatedAssetManufacturers;
          }
          return oldData;
        },
      );
      enqueueSnackbar(t('translation.notification.assetManufacturerUpdated'), {
        variant: 'success',
      });
    },
    ...options,
  });
}

export function useDeleteAssetManufacturer(
  options?: UseMutationOptions<number, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (assetManufacturerId) => deleteAssetManufacturer(assetManufacturerId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar(t('translation.notification.assetManufacturerDeleted'), {
          variant: 'success',
        });
        queryClient.invalidateQueries({ queryKey: ['asset-manufacturers'] });
      },
      ...options,
    },
  );
}
