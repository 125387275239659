import { FC } from 'react';
import { Box, debounce } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { CustomHeader } from './components/CustomHeader';
import { useFilterParams } from '../../hooks/filterParams';

interface DataTableProps {
  data: (object | string[] | number[])[] | undefined;
  title?: string;
  columns: MUIDataTableColumn[];
  isLoading: boolean;
  isFetching: boolean;
  count?: number;
  rowsPerPage?: number;
  options?: Partial<MUIDataTableOptions>;
  customBuildFilters?: (filterList: string[][]) => Record<string, string> | null;
  customBuildSortOrder?: (
    property: string,
    direction: string,
  ) => {
    [x: string]: string;
  } | null;
}

export const DataTable: FC<DataTableProps> = ({
  data,
  columns,
  title,
  isLoading,
  isFetching,
  count,
  rowsPerPage = 20,
  options,
  customBuildFilters,
  customBuildSortOrder,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useFilterParams();

  const buildSortOrder = (property: string, direction: string) => {
    if (property && direction) {
      const key = `order[${property}]`;
      return { [key]: direction };
    }
    return null;
  };

  // table component returs weird params on filter change
  // iterating over 2d array of filter values and constructing
  // filter object with properties of format { [columnName]: filterValue }
  const buildFilters = (filterList: string[][]) => {
    if (filterList.length > 0) {
      const filters: Record<string, string> = {};
      filterList.forEach((filter, index) => {
        if (filter[0]) {
          const colName = columns[index].name;
          const key = `where[${colName}]`;
          filters[key] = filter[0];
        }
      });
      if (Object.keys(filters).length < 1) {
        return null;
      }
      return filters;
    }
    return null;
  };

  // debounce new requests when users type in filter fields
  const debouncedFilterChange = debounce((props) => {
    setSearchParams(props);
  }, 500);

  return (
    <Box sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
      <MUIDataTable
        title={title ? <CustomHeader title={t(title)} isLoading={isFetching} /> : ''}
        data={data ?? []}
        columns={columns}
        options={{
          textLabels: {
            body: {
              noMatch: isLoading
                ? t('translation.common.loading')
                : t('translation.table.body.noMatch'),
              toolTip: t('translation.table.body.toolTip'),
              columnHeaderTooltip: (column) =>
                `${t('translation.table.body.columnHeaderTooltip')} ${column.label}`,
            },
            pagination: {
              next: t('translation.table.pagination.next'),
              previous: t('translation.table.pagination.previous'),
              rowsPerPage: t('translation.table.pagination.rowsPerPage'),
              displayRows: t('translation.table.pagination.displayRows'),
            },
            toolbar: {
              search: t('translation.table.toolbar.search'),
              downloadCsv: t('translation.table.toolbar.downloadCsv'),
              print: t('translation.table.toolbar.print'),
              viewColumns: t('translation.table.toolbar.viewColumns'),
              filterTable: t('translation.table.toolbar.filterTable'),
            },
            filter: {
              all: t('translation.table.filter.all'),
              title: t('translation.table.filter.title'),
              reset: t('translation.table.filter.reset'),
            },
            viewColumns: {
              title: t('translation.table.viewColumns.title'),
              titleAria: t('translation.table.viewColumns.titleAria'),
            },
            selectedRows: {
              text: t('translation.table.selectedRows.text'),
              delete: t('translation.table.selectedRows.delete'),
              deleteAria: t('translation.table.selectedRows.deleteAria'),
            },
          },
          search: false,
          confirmFilters: false,
          filterType: 'textField',
          print: false,
          download: false,
          viewColumns: false,
          count: count,
          selectableRows: 'none',
          serverSide: true,
          rowsPerPage: searchParams.take ?? rowsPerPage,
          rowsPerPageOptions: [20, 50, 100],
          enableNestedDataAccess: '.',
          responsive: 'vertical',
          tableBodyMaxHeight: '450px',
          page: searchParams.page ? searchParams.page - 1 : undefined,
          sortOrder: searchParams.order
            ? Object.entries(searchParams.order).map(([key, value]) => ({
                name: key,
                direction: value.toLowerCase(),
              }))[0]
            : undefined,
          setTableProps: () => {
            return {
              size: 'small',
            };
          },
          onTableChange: (action, tableState) => {
            const sortOrder =
              typeof customBuildSortOrder === 'function'
                ? customBuildSortOrder(tableState.sortOrder.name, tableState.sortOrder.direction)
                : buildSortOrder(tableState.sortOrder.name, tableState.sortOrder.direction);
            const filterList =
              typeof customBuildFilters === 'function'
                ? customBuildFilters(tableState.filterList)
                : buildFilters(tableState.filterList);

            switch (action) {
              case 'sort':
              case 'changePage':
              case 'changeRowsPerPage':
              case 'filterChange':
                if (filterList) {
                  debouncedFilterChange({
                    ...filterList,
                    ...sortOrder,
                    ...(tableState.rowsPerPage !== rowsPerPage && {
                      take: tableState.rowsPerPage.toString(),
                    }),
                    ...(tableState.page + 1 > 1 && {
                      page: (tableState.page + 1).toString(),
                    }),
                  });
                } else {
                  setSearchParams({
                    ...sortOrder,
                    ...(tableState.rowsPerPage !== rowsPerPage && {
                      take: tableState.rowsPerPage.toString(),
                    }),
                    ...(tableState.page + 1 > 1 && {
                      page: (tableState.page + 1).toString(),
                    }),
                  });
                }
                break;
              case 'resetFilters':
                setSearchParams({
                  ...sortOrder,
                  ...(tableState.rowsPerPage !== rowsPerPage && {
                    take: tableState.rowsPerPage.toString(),
                  }),
                  ...(tableState.page + 1 > 1 && {
                    page: (tableState.page + 1).toString(),
                  }),
                });
                break;
            }
          },
          ...options,
        }}
      />
    </Box>
  );
};
