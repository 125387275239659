import { useState } from 'react';
import { Paper, Grid, Link, Typography, Button } from '@mui/material';
import { Link as RouterLink, Navigate, useParams, useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { paths } from '../../../constants/paths';
import {
  useDeleteProjectImport,
  useProjectImportErrors,
  useSingleProjectImport,
} from '../../../hooks/';
import { ProjectImportStatus } from '../../../constants/enum';
import { ErrorsList } from './components/ErrorsList';
import { ConfirmationModal } from '../../../components';

export const ProjectImportErrors = () => {
  const { projectImportId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  const {
    data,
    isLoading: isPiLoading,
    isFetching: isPiFetching,
  } = useSingleProjectImport(Number(projectImportId));
  const { mutate, isLoading: isDeleteLoading } = useDeleteProjectImport();

  const {
    data: errorData,
    isLoading,
    isFetching,
  } = useProjectImportErrors(Number(projectImportId));

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isPiLoading || isPiFetching) {
    return null;
  }

  if (
    (projectImportId && isNaN(Number(projectImportId))) ||
    data?.status !== ProjectImportStatus.ERROR
  ) {
    return <Navigate to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }} />;
  }

  return (
    <>
      <Paper sx={{ px: { xs: 3, md: 6 }, py: 2, borderRadius: 0 }}>
        <Grid container>
          <Grid item xs={6} display="flex" alignItems="center">
            <Link
              component={RouterLink}
              to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }}
              color="inherit"
              sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              <ArrowBack sx={{ mr: 1 }} />
              {t('translation.common.goBack')}
            </Link>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button color="error" variant="text" onClick={handleClickOpen}>
              {t('translation.button.delete')}
            </Button>
            <ConfirmationModal
              open={open}
              handleClose={handleClose}
              title="translation.projectImportErrorsListPage.deleteprojectImportModal.title"
              text="translation.projectImportErrorsListPage.deleteprojectImportModal.text"
              confirmActionButton={
                <LoadingButton
                  loading={isDeleteLoading}
                  color="error"
                  variant="text"
                  onClick={() => {
                    if (!isDeleteLoading) {
                      mutate(Number(projectImportId));
                      handleClose();
                    }
                  }}
                >
                  {t('translation.button.delete')}
                </LoadingButton>
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Typography component="span" mr={1}>
              {t(`translation.label.mappingName`)}:
            </Typography>
            <Typography component="span" fontWeight="bold" fontSize={18}>
              {data?.mapping?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3} sx={{ mb: 0.5 }}>
            <Typography component="span" mr={1}>
              {t(`translation.label.hasHeaders`)}:
            </Typography>
            <Typography component="span" fontWeight="bold">
              {t(`translation.common.${data?.hasHeaders ? 'yes' : 'no'}`)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={4} sx={{ mb: 0.5 }}>
            <Typography component="span" mr={1}>
              {t(`translation.label.status`)}:
            </Typography>
            <Typography component="span" fontWeight="bold" textTransform="uppercase" color="error">
              {t(`translation.enum.ERROR`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={5} md={4} lg={3} sx={{ mb: 0.5 }}>
            <Typography component="span" mr={1}>
              {t(`translation.label.flow`)}:
            </Typography>
            <Typography component="span" fontWeight="bold">
              {t(`translation.enum.${data?.flow}`)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={8} sx={{ mb: 0.5 }}>
            <Typography component="span" mr={1}>
              {t(`translation.label.filename`)}:
            </Typography>
            <Typography component="span" fontWeight="bold" sx={{ wordBreak: 'break-all' }}>
              {data?.filename}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <ErrorsList data={errorData} isLoading={isLoading} isFetching={isFetching} />
    </>
  );
};
