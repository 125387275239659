import { ChangeEvent, FC, FormEventHandler, useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  TextField,
  Button,
  DialogActions,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { AssetModelCard } from '../AssetModelCard';
import { AssetModelModal } from '../AssetModelModal';
import { AssetModelData } from '../../hooks/assetModels';
import { ReplacementGroupData, ReplacementGroupInput } from '../../hooks/replacementGroups';

interface ReplacementGroupModalProps {
  open: boolean;
  toggleModal: () => void;
  replacementGroup?: ReplacementGroupData;
  assetModels: AssetModelData[];
  onSubmitGroup: (group: ReplacementGroupInput) => void;
}

export const ReplacementGroupModal: FC<ReplacementGroupModalProps> = ({
  open,
  toggleModal,
  replacementGroup,
  assetModels,
  onSubmitGroup,
}) => {
  const initialState = {
    projectId: replacementGroup?.projectId || 0,
    name: replacementGroup?.name || '',
    notes: replacementGroup?.notes || '',
    assetModels: replacementGroup?.assetModels || [],
  };

  const [group, setGroup] = useState<Omit<ReplacementGroupData, 'id'>>(initialState);

  const [openAssetModal, setOpenAssetModal] = useState(false);

  useEffect(() => {
    setGroup({
      projectId: replacementGroup?.projectId || 0,
      name: replacementGroup?.name || '',
      notes: replacementGroup?.notes || '',
      assetModels: replacementGroup?.assetModels || [],
    });
  }, [replacementGroup]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroup((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (group.name && group.assetModels.length > 0) {
      onSubmitGroup(group);
      setGroup(initialState);
      toggleModal();
    }
  };

  const handleCloseModal = () => {
    setGroup(initialState);
    toggleModal();
  };

  const toggleAssetModal = useCallback(() => {
    setOpenAssetModal((prevState) => !prevState);
  }, []);

  const handleAddAsset = useCallback((assetModel: AssetModelData) => {
    setGroup((prevState) => ({
      ...prevState,
      assetModels: [...prevState.assetModels, assetModel].sort((a, b) =>
        a.name > b.name ? 1 : -1,
      ),
    }));
  }, []);

  const handleRemoveAsset = useCallback((index: number) => {
    setGroup((prevState) => {
      const newAssetList = [...prevState.assetModels];
      newAssetList.splice(index, 1);
      return {
        ...prevState,
        assetModels: newAssetList,
      };
    });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { width: 700, maxWidth: '100%' },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {replacementGroup
          ? t('translation.createProjectPage.replacementGroupModal.titleEdit')
          : t('translation.createProjectPage.replacementGroupModal.titleAdd')}
      </DialogTitle>
      <DialogContent>
        <Box component="form" id="replacementGroup" onSubmit={handleSubmit}>
          <Grid container my={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.replacementGroupName')}
                name="name"
                value={group.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.notes')}
                name="notes"
                value={group.notes}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} my={3}>
              {group.assetModels.length < 1 && (
                <Typography textAlign="center">
                  {t('translation.createProjectPage.replacementGroupModal.noAssetModels')}
                </Typography>
              )}
              {group.assetModels.map((assetModel, index) => (
                <AssetModelCard
                  key={assetModel.id}
                  preview={false}
                  assetModel={assetModel}
                  index={index}
                  onDeleteAsset={handleRemoveAsset}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                sx={{ minWidth: 120 }}
                onClick={toggleAssetModal}
              >
                {t('translation.button.addAsset')} +
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button color="inherit" variant="text" onClick={handleCloseModal}>
          {t('translation.button.cancel')}
        </Button>
        <Button
          type="submit"
          form="replacementGroup"
          variant="contained"
          disabled={!group.name || group.assetModels.length < 1}
        >
          {replacementGroup ? t('translation.button.editGroup') : t('translation.button.addGroup')}
        </Button>
      </DialogActions>
      <AssetModelModal
        open={openAssetModal}
        toggleModal={toggleAssetModal}
        assetModels={assetModels.filter(
          (assetModel) =>
            !group.assetModels.some((selectedAsset) => assetModel.id === selectedAsset.id),
        )}
        onSubmit={handleAddAsset}
      />
    </Dialog>
  );
};
