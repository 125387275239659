import { styled } from '@mui/system';
import TabUnstyled from '@mui/base/TabUnstyled';

export const CustomTab = styled(TabUnstyled)(({ theme }) => ({
  display: 'inline-block',
  minWidth: 140,
  cursor: 'pointer',
  fontSize: 16,
  textAlign: 'center',
  marginRight: 4,
  padding: 8,
  borderRadius: '4px 4px 0px 0px',
  boxShadow: 'none',
  background: theme.palette.mode === 'dark' ? '#3d4347' : '#FFFFFF',
  backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
  color: 'inherit',
  border: 0,
  borderTop: 4,
  borderStyle: 'solid',
  borderColor: 'transparent',
  textDecoration: 'none',

  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
  },

  '&.Mui-selected': {
    borderColor: theme.palette.primary.main,
  },
}));
