import { FormEventHandler, useState } from 'react';
import { Alert, Box, TextField, Typography } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { AuthBoxLayout } from '../../../containers';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const { error, submitForm, resendCode, isPending } = useAuthenticator();

  const [submit, setSubmit] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSubmit(true);
    if (
      password &&
      confirmPassword &&
      confirmationCode &&
      !isPending &&
      password === confirmPassword
    ) {
      submitForm({ password, ConfirmationCode: confirmationCode });
    }
  };

  return (
    <AuthBoxLayout
      cardActions={
        <>
          <LoadingButton
            loading={isPending}
            sx={{ minWidth: 120, textTransform: 'none' }}
            color="inherit"
            variant="text"
            onClick={resendCode}
          >
            <Trans i18nKey="translation.resetPasswordPage.resendCode" />
          </LoadingButton>
          <LoadingButton
            form="resetPassword"
            loading={isPending}
            sx={{ minWidth: 120 }}
            variant="contained"
            type="submit"
          >
            <Trans i18nKey="translation.resetPasswordPage.resetPassword" />
          </LoadingButton>
        </>
      }
    >
      <Typography sx={{ my: 3, fontWeight: 'bold' }} variant="h5" component="div">
        <Trans i18nKey="translation.resetPasswordPage.title" />
      </Typography>
      {error && (
        <Alert sx={{ mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Box>
        <form id="resetPassword" onSubmit={handleSubmit}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="confirmation_code"
            autoComplete="confirmation_code"
            label={t('translation.label.confirmationCode')}
            name="confirmation_code"
            error={submit && !confirmationCode}
            onChange={(event) => {
              setSubmit(false);
              setConfirmationCode(event.target.value);
            }}
          />
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="new-password"
            autoComplete="new-password"
            label={t('translation.label.newPassword')}
            name="new-password"
            type="password"
            error={submit && !password}
            onChange={(event) => {
              setSubmit(false);
              setPassword(event.target.value);
            }}
          />
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="confirm-password"
            autoComplete="new-password"
            label={t('translation.label.confirmPassword')}
            name="confirm-password"
            type="password"
            error={submit && (!confirmPassword || password !== confirmPassword)}
            onChange={(event) => {
              setSubmit(false);
              setConfirmPassword(event.target.value);
            }}
          />
        </form>
      </Box>
    </AuthBoxLayout>
  );
};
