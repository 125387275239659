import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { paths } from '../constants/paths';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';

export interface AssetModelData {
  manufacturer: {
    id: number;
    name: string;
  } | null;
  classification: {
    id: number;
    name: string;
  } | null;
  size: {
    id: number;
    name: string;
  } | null;
  manufacturerClassificationId: number | null;
  id: number;
  name: string;
  partNumber: string;
  deletedAt: string;
}

export interface AssetModelInput extends Pick<AssetModelData, 'name' | 'partNumber'> {
  classificationId: number | null;
  manufacturerId: number | null;
  sizeId: number | null;
}

const getAssetModels = async () => {
  try {
    const { data } = await axiosInstance.get('/asset-model');

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getSingleAssetModel = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/asset-model/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createAssetModel = async (assetModelData: AssetModelInput) => {
  try {
    const { data } = await axiosInstance.post(`/asset-model`, assetModelData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateAssetModel = async (id: number, assetModelData: AssetModelInput) => {
  try {
    const { data } = await axiosInstance.put(`/asset-model/${id}`, assetModelData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteAssetModel = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/asset-model/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useAssetModels(options?: UseQueryOptions<AssetModelData[], CustomApiError>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['asset-models'],
    queryFn: () => getAssetModels(),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useSingleAssetModel(
  id: number,
  options?: UseQueryOptions<AssetModelData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['asset-models', id],
    queryFn: () => getSingleAssetModel(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      navigate(paths.ASSET_MODELS);
    },
    ...options,
    enabled: !!id,
  });
}

export function useCreateAssetModel(
  options?: UseMutationOptions<AssetModelData, CustomApiError, AssetModelInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation<AssetModelData, CustomApiError, AssetModelInput>(
    (data) => createAssetModel(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newAssetModel) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['asset-models', newAssetModel.id], newAssetModel);
        enqueueSnackbar(t('translation.notification.assetModelCreated'), {
          variant: 'success',
        });
        navigate({ pathname: paths.ASSET_MODELS });
      },
      ...options,
    },
  );
}

export function useUpdateAssetModel(
  options?: UseMutationOptions<
    AssetModelData,
    CustomApiError,
    { id: number; data: AssetModelInput }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation<AssetModelData, CustomApiError, { id: number; data: AssetModelInput }>(
    ({ id, data }) => updateAssetModel(id, data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newAssetModel) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['asset-models', newAssetModel.id], newAssetModel);
        enqueueSnackbar(t('translation.notification.assetModelUpdated'), {
          variant: 'success',
        });
        navigate({ pathname: paths.ASSET_MODELS });
      },
      ...options,
    },
  );
}

export function useDeleteAssetModel(options?: UseMutationOptions<number, CustomApiError, number>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (assetModelId) => deleteAssetModel(assetModelId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar(t('translation.notification.assetModelDeleted'), {
          variant: 'success',
        });
        queryClient.invalidateQueries({ queryKey: ['asset-models'] });
      },
      ...options,
    },
  );
}
