import { Button, Grid } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAssetModels } from '../../../hooks';
import { paths } from '../../../constants/paths';
import { DataTable } from '../../../components';
import { AssetModelsActionsColumn } from './components/AssetModelsActionsColumn';
import { PermissionWrapper } from '../../../containers/PermissionWrapper';
import { allAdminRoles } from '../../../constants/permissionGroups';

export const AssetModelsList = () => {
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useAssetModels();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
      },
    },
    {
      label: t('translation.label.name'),
      name: 'name',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
      },
    },
    {
      label: t('translation.label.partNumber'),
      name: 'partNumber',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
      },
    },
    {
      label: t('translation.label.classification'),
      name: 'classification.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
      },
    },
    {
      label: t('translation.label.manufacturer'),
      name: 'manufacturer.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
      },
    },
    {
      label: t('translation.label.size'),
      name: 'size.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
      },
    },
    {
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '50px' } }),
        customBodyRenderLite: (dataIndex) => {
          if (data?.[dataIndex].id) {
            return <AssetModelsActionsColumn assetModelId={data?.[dataIndex].id} />;
          }
          return null;
        },
      },
    },
  ];

  return (
    <Grid container>
      <PermissionWrapper whitelist={allAdminRoles}>
        <Grid item xs={12} sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
          <Button
            sx={{ minWidth: 120 }}
            variant="contained"
            component={Link}
            to={paths.ASSET_MODEL_CREATE}
          >
            {t('translation.button.add')} +
          </Button>
        </Grid>
      </PermissionWrapper>
      <Grid item xs={12}>
        <DataTable
          data={data ?? []}
          columns={columns}
          count={undefined}
          isLoading={isLoading}
          isFetching={isFetching}
          title="translation.assetModelsListPage.title"
          options={{
            serverSide: false,
            onTableChange: undefined,
            sortOrder: {
              name: 'name',
              direction: 'asc',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
