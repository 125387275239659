import { FC, useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  useTheme,
  TableHead,
  TableBody,
  Button,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import queryString from 'qs';
import { LoadingButton } from '@mui/lab';
import { paths } from '../../../../constants/paths';
import { useCreateMapping, useFinalizeProjectImport } from '../../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import { MappingInput } from '../../../../hooks/mappings';
import { ProjectImportData } from '../../../../hooks/projectImports';
import { CompanySummaryData } from '../../../../hooks/company';
import { Flow } from '../../../../constants/enum';

interface MappingTableProps {
  headers: string[];
  rows: string[][];
  mappingFields: Record<string, number | null>;
  projectImport: ProjectImportData;
  companies: CompanySummaryData[];
}

export const MappingTable: FC<MappingTableProps> = ({
  headers,
  rows,
  mappingFields,
  projectImport,
  companies,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const { projectImportId } = useParams();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { mappingName } = queryString.parse(searchParams.toString());

  const [hasHeaders, setHasHeaders] = useState(false);
  const [companyId, setCompanyId] = useState<number>();
  const [submitted, setSubmitted] = useState(false);

  const { mutate, isLoading } = useCreateMapping({
    onSuccess: (newMapping) => {
      queryClient.setQueryData(['mappings', newMapping.id], newMapping);
      finalizeImport({
        id: Number(projectImportId),
        data: { hasHeaders, mappingId: newMapping.id, companyId },
      });
    },
  });
  const { mutate: finalizeImport, isLoading: isFinalizeLoading } = useFinalizeProjectImport();

  const filteredRows = hasHeaders ? rows.filter((_, index) => index !== 0) : rows;

  const [headerValues, setHeaderValues] = useState(() => {
    const values = Array(headers.length).fill('');
    if (!projectImport.mapping || mappingName) {
      return values;
    }
    Object.entries(projectImport.mapping).forEach(([key, value]) => {
      if (
        Object.keys(mappingFields).includes(key) &&
        typeof value === 'number' &&
        value <= headers.length - 1
      ) {
        values[value] = key;
      }
    });
    return values;
  });

  const backgroundColor = theme.palette.mode === 'dark' ? 'background.default' : 'white';

  const valueSelectedColor = (value: string) => {
    if (headerValues.includes(value)) {
      return 'success.main';
    }
    if (submitted) {
      return 'error.main';
    }
    return 'text.primary';
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const requiredValues = ['accountNumber'];
    if (projectImport.flow === Flow.IMPORT) {
      requiredValues.push('customerName', 'assetType', 'assetMake', 'address');
    }
    if (!isLoading && !isFinalizeLoading && requiredValues.every((v) => headerValues.includes(v))) {
      if (mappingName) {
        const mappingInput: Record<string, any> = {
          name: mappingName,
          flow: projectImport.flow,
        };
        headerValues.forEach((field: string, index) => {
          if (field) {
            mappingInput[field] = index;
          }
        });
        mutate(mappingInput as MappingInput);
      } else {
        finalizeImport({
          id: Number(projectImportId),
          data: { hasHeaders },
        });
      }
    }
  };

  const renderRequiredFields = (display = { xs: 'none', md: 'flex' }) => {
    return (
      <Grid
        item
        xs={12}
        sm={projectImport.flow === Flow.UPDATE ? 6 : 12}
        xl={6}
        mt={3}
        display={display}
        alignItems="center"
      >
        <Box>
          {t('translation.projectImportMappingPage.text2')}{' '}
          <Box sx={{ display: projectImport.flow === Flow.IMPORT ? 'inline' : 'inline-block' }}>
            <Box component="span" color={valueSelectedColor('accountNumber')}>
              {t('translation.label.accountNumber')}
            </Box>
            {projectImport.flow === Flow.IMPORT && (
              <>
                ,{' '}
                <Box component="span" color={valueSelectedColor('customerName')}>
                  {t('translation.label.customerName')}
                </Box>
                ,{' '}
                <Box component="span" color={valueSelectedColor('assetType')}>
                  {t('translation.label.assetType')}
                </Box>
                ,{' '}
                <Box component="span" color={valueSelectedColor('assetMake')}>
                  {t('translation.label.assetMake')}
                </Box>{' '}
                {t('translation.common.and')}{' '}
                <Box component="span" color={valueSelectedColor('address')}>
                  {t('translation.label.address')}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item>{t('translation.projectImportMappingPage.text1')}</Grid>
      {renderRequiredFields({ xs: 'flex', md: 'none' })}
      <Grid item mt={3}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Grid container alignItems="center" sx={{ backgroundColor, px: 3, minHeight: 64 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                {t('translation.projectImportMappingPage.title')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  sx={{ justifyContent: 'right' }}
                  control={<Checkbox />}
                  label={t('translation.label.hasHeaders')}
                  name="hasHeaders"
                  checked={hasHeaders}
                  onChange={(_, checked) => {
                    setHasHeaders(checked);
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Box sx={{ display: 'table', tableLayout: 'fixed', width: '100%', overflowY: 'scroll' }}>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headerValues.map((value, index) => (
                      <TableCell key={index} sx={{ minWidth: 150, backgroundColor }}>
                        <TextField
                          id="field"
                          select
                          label={t('translation.label.select')}
                          size="small"
                          fullWidth
                          variant="standard"
                          value={value}
                          disabled={!!projectImport.mapping && !mappingName}
                          onChange={(event) => {
                            if (!projectImport.mapping || mappingName) {
                              setHeaderValues((prevState) => {
                                const newState = [...prevState];
                                newState[index] = event.target.value;
                                return newState;
                              });
                            }
                          }}
                        >
                          {Object.keys(mappingFields)
                            .sort()
                            .map((field) => (
                              <MenuItem
                                key={field}
                                value={field}
                                sx={{ display: headerValues.includes(field) ? 'none' : 'flex' }}
                              >
                                {t(`translation.label.${field}`)}
                              </MenuItem>
                            ))}
                        </TextField>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows.map((row, index) => (
                    <TableRow key={index} sx={{ height: 50 }}>
                      {headerValues.map((_, idx) => (
                        <TableCell key={row[idx] + index + idx} sx={{ fontSize: 14 }}>
                          {row[idx] || ''}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Grid>
      {renderRequiredFields()}
      {projectImport.flow === Flow.UPDATE && (
        <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
          <Grid container justifyContent="right">
            <Grid item xs={12} md={8} lg={6} justifyContent="right" display="flex">
              <Autocomplete
                sx={{ my: 1, width: 350 }}
                disablePortal
                id="company"
                options={companies}
                getOptionLabel={(option) => option.name}
                value={companies.find((m) => m.id === companyId) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('translation.label.subcontractor')}
                    error={submitted && !companyId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                onChange={(_, value) => {
                  setSubmitted(false);
                  setCompanyId(value?.id || undefined);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          mt: 6,
          justifyContent: 'right',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
        }}
      >
        <Button
          color="inherit"
          variant="text"
          sx={{
            width: 120,
            mr: { xs: 0, sm: 2 },
            mt: { xs: 2, sm: 0 },
            display: { xs: 'flex', sm: 'inline-flex' },
          }}
          component={Link}
          to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }}
        >
          {t('translation.button.cancel')}
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          sx={{
            width: 120,
            mr: { xs: 0, sm: 2 },
            mt: { xs: 2, sm: 0 },
            display: { xs: 'flex', sm: 'inline-flex' },
          }}
          component={Link}
          state={location.state || ''}
          to={{ pathname: paths.build(paths.PROJECT_IMPORT_CONTINUE, projectImportId) }}
        >
          {t('translation.button.back')}
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ width: 120, display: { xs: 'flex', sm: 'inline-flex' } }}
          type="submit"
          loading={isLoading || isFinalizeLoading}
          onClick={handleSubmit}
        >
          {t('translation.button.finish')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
