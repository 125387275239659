import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  useSingleProject,
  useDeleteProject,
  useArchiveProject,
  useCompleteProject,
  useGetProjectReport,
  useProjectUpdateClientId,
  useProjectDetails,
  useGetWorkOrdersSummary,
  useProjectDocuments,
  useReplacementGroups,
  useProjectAssets,
  useWorkOrders,
} from '../../../../hooks';
import { TimePeriod } from '../../../../constants/enum';
import { useGetWorkOrdersMapData } from '../../../../hooks/workOrders';
import { WorkOrdersMapDataEnhanced } from '../../../../components/WorkOrdersMap/interface';

export const useProjectDetailsData = () => {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const location = useLocation();

  const [zoneId, setZoneId] = useState<string>();
  const [companyId, setCompanyId] = useState<number>();
  const [granularity, setGranularity] = useState<TimePeriod>(TimePeriod.DAY);

  const { data: project, isLoading, isFetching } = useSingleProject(Number(projectId));
  const { data: projectDocuments, isLoading: isDocumentsLoading } = useProjectDocuments(
    Number(projectId),
  );
  const { data: projectDetails } = useProjectDetails({
    projectId: Number(projectId),
    zoneId,
    companyId,
    granularity,
  });
  const { data: workOrdersPieData } = useGetWorkOrdersSummary({
    projectId: [Number(projectId)],
    zoneId,
    companyId,
  });
  const { data: replacementGroups, isLoading: isReplacementGroupsLoading } = useReplacementGroups(
    Number(projectId),
  );
  const { data: projectAssets, isLoading: isAssetsLoading } = useProjectAssets({
    projectId: Number(projectId),
    zoneId,
    companyId,
  });
  const {
    data: workOrders,
    isLoading: isWorkOrdersLoading,
    isFetching: isWorkOrdersFetching,
  } = useWorkOrders({ projectId: Number(projectId), zoneId, companyId });
  const { data: workOrdersMap, isLoading: isWorkOrdersMapLoading } = useGetWorkOrdersMapData({
    projectId: [Number(projectId)],
  });

  const { mutate: deleteProject, isLoading: isDeleteLoading } = useDeleteProject();
  const { mutate: archiveProject, isLoading: isArchiveLoading } = useArchiveProject();
  const { mutate: completeProject, isLoading: isCompleteLoading } = useCompleteProject();
  const { mutate: generateReport, isLoading: isGeneratingReport } = useGetProjectReport();
  const { mutate: updateClientId, isLoading: isClientIdUpdating } = useProjectUpdateClientId();

  const [clientId, setClientId] = useState('');

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openClientIdModal, setOpenClientIdModal] = useState(false);

  useEffect(() => {
    if (project) {
      setClientId(project.clientId ?? '');
    }
  }, [project]);

  const handleDeleteModalOpen = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const handleDeleteModalClose = useCallback(() => {
    setOpenDeleteModal(false);
  }, []);

  const handleClientIdModalOpen = useCallback(() => {
    setOpenClientIdModal(true);
  }, []);

  const handleClientIdModalClose = useCallback(() => {
    setOpenClientIdModal(false);
  }, []);

  const handleClientIdSubmit = useCallback(() => {
    if (!isClientIdUpdating && clientId) {
      updateClientId({ projectId: Number(projectId), clientId });
      handleClientIdModalClose();
    }
  }, [isClientIdUpdating, projectId, updateClientId, handleClientIdModalClose, clientId]);

  const workOrderMapData: WorkOrdersMapDataEnhanced[] = useMemo(() => {
    if (!workOrdersMap || !project) {
      return [];
    }

    return workOrdersMap.map((wo) => {
      const enchancedWo: WorkOrdersMapDataEnhanced = {
        ...wo,
        projectName: project.name,
      };
      return enchancedWo;
    });
  }, [workOrdersMap, project]);

  return {
    pathname,
    projectId: Number(projectId),
    location,
    zoneId,
    setZoneId,
    companyId,
    setCompanyId,
    granularity,
    setGranularity,
    project,
    projectDetails,
    workOrdersPieData,
    projectDocuments,
    isDocumentsLoading,
    isLoading,
    isFetching,
    deleteProject,
    isDeleteLoading,
    archiveProject,
    isArchiveLoading,
    completeProject,
    isCompleteLoading,
    generateReport,
    isGeneratingReport,
    replacementGroups,
    isReplacementGroupsLoading,
    projectAssets,
    isAssetsLoading,
    workOrders,
    isWorkOrdersLoading,
    isWorkOrdersFetching,
    openDeleteModal,
    setOpenDeleteModal,
    openClientIdModal,
    setOpenClientIdModal,
    clientId,
    setClientId,
    handleDeleteModalOpen,
    handleDeleteModalClose,
    handleClientIdModalOpen,
    handleClientIdModalClose,
    handleClientIdSubmit,
    workOrderMapData,
    isWorkOrdersMapLoading,
  };
};
