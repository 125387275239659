import { ArrowBack, ArrowForward, DeleteOutline, ModeEditOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Paper,
  Grid,
  Box,
  Button,
  Link,
  Typography,
  IconButton,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { Navigate, Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { ConfirmationModal } from '../../../components';
import { DATE_FORMAT } from '../../../constants/date';
import { paths } from '../../../constants/paths';
import { allAdminRoles } from '../../../constants/permissionGroups';
import { PermissionWrapper } from '../../../containers';
import { useDeleteWorkOrder, useProjectWorkOrderIds, useSingleWorkOrder } from '../../../hooks';
import { WorkOrderData } from './components/WorkOrderData';
import { WorkOrderStatus } from '../../../constants/enum';

export const WorkOrderDetails: FC = () => {
  const { t } = useTranslation();
  const { projectId, workOrderId } = useParams();
  const location = useLocation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const { data: workOrder } = useSingleWorkOrder(Number(workOrderId));

  const { data: workOrderIds, isLoading: isLoadingWorkOrderIds } = useProjectWorkOrderIds(
    Number(projectId),
  );

  const { mutate: deleteWorkOrder, isLoading: isDeleting } = useDeleteWorkOrder({
    projectId: Number(projectId),
  });

  const isDisabled = (index: number) => {
    if (isLoadingWorkOrderIds) {
      return true;
    }
    if (workOrderId && workOrderIds) {
      return workOrderIds?.indexOf(Number(workOrderId)) === index;
    }
    return true;
  };

  const getPrevOrNextWorkOrder = (step: number) => {
    if (workOrderId && workOrderIds) {
      const currentIndex = workOrderIds?.indexOf(Number(workOrderId));
      const newIndex = currentIndex + step;
      if (newIndex >= 0 && newIndex <= workOrderIds?.length - 1) {
        return workOrderIds[newIndex];
      }
    }
    return workOrderId;
  };

  const statusRender = (status: WorkOrderStatus) => {
    switch (status) {
      case WorkOrderStatus.COMPLETED:
        return (
          <Typography component="span" fontWeight="bold" sx={{ color: 'success.main' }}>
            {t(`translation.enum.COMPLETED`)}
          </Typography>
        );
      case WorkOrderStatus.RTU:
        return (
          <Typography component="span" fontWeight="bold" sx={{ color: 'error.main' }}>
            {t('translation.enum.RTU')}
          </Typography>
        );
      case WorkOrderStatus.UNABLE:
        return (
          <Typography component="span" fontWeight="bold" sx={{ color: 'warning.main' }}>
            {t('translation.enum.UNABLE')}
          </Typography>
        );
      default:
        return (
          <Typography component="span" fontWeight="bold">
            {t(`translation.enum.${status}`)}
          </Typography>
        );
    }
  };

  if (workOrderId && isNaN(Number(workOrderId))) {
    return (
      <Navigate
        to={{
          pathname: paths.build(paths.PROJECT_WORK_ORDERS, projectId),
          search: location.state || '',
        }}
      />
    );
  }

  return (
    <>
      <Paper sx={{ px: { xs: 3, md: 6 }, py: 2, borderRadius: 0 }}>
        <Grid container>
          <Grid item xs={6} sm={4} display="flex" alignItems="center">
            <Link
              component={RouterLink}
              to={{
                pathname: paths.build(paths.PROJECT_WORK_ORDERS, projectId),
                search: location.state || '',
              }}
              color="inherit"
              sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              <ArrowBack sx={{ mr: 1 }} />
              {t('translation.common.goBack')}
            </Link>
          </Grid>
          <PermissionWrapper whitelist={allAdminRoles} ownerId={workOrder?.company?.id}>
            <Grid item xs={6} sm={8} textAlign="right">
              <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                <Button
                  color="error"
                  variant="text"
                  disabled={!workOrder}
                  onClick={handleDeleteModalOpen}
                  sx={{ mr: 3, minWidth: 120 }}
                >
                  {t('translation.button.delete')}
                </Button>
                <Button
                  component={RouterLink}
                  color="inherit"
                  variant="outlined"
                  disabled={!workOrder}
                  sx={{ minWidth: 120 }}
                  to={paths.build(paths.WORK_ORDER_EDIT, projectId, workOrderId)}
                >
                  {t('translation.button.edit')}
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
                <Tooltip title={t('translation.button.delete')} placement="top" arrow>
                  <IconButton
                    color="error"
                    disabled={!workOrder}
                    onClick={handleDeleteModalOpen}
                    sx={{ mr: 2 }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('translation.button.edit')} placement="top" arrow>
                  <IconButton
                    component={RouterLink}
                    color="inherit"
                    disabled={!workOrder}
                    to={paths.build(paths.WORK_ORDER_EDIT, projectId, workOrderId)}
                  >
                    <ModeEditOutline />
                  </IconButton>
                </Tooltip>
              </Box>
              <ConfirmationModal
                open={openDeleteModal}
                handleClose={handleDeleteModalClose}
                title="translation.projectDetailsPage.deleteWorkOrderModal.title"
                text="translation.projectDetailsPage.deleteWorkOrderModal.text"
                confirmActionButton={
                  <LoadingButton
                    loading={isDeleting}
                    color="error"
                    variant="text"
                    onClick={() => {
                      if (!isDeleting) {
                        deleteWorkOrder(Number(workOrderId));
                        handleDeleteModalClose();
                      }
                    }}
                  >
                    {t('translation.button.delete')}
                  </LoadingButton>
                }
              />
            </Grid>
          </PermissionWrapper>
          <Grid
            item
            xs={12}
            sx={{ my: 2, height: { xs: 'auto', sm: 30 }, flexWrap: 'wrap' }}
            display="flex"
            alignItems="center"
          >
            <Typography component="span" mr={1}>
              {t(`translation.label.workOrder`)}:
            </Typography>
            {workOrder ? (
              <Typography component="span" fontWeight="bold" fontSize={18}>
                {workOrder.id}
              </Typography>
            ) : (
              <Skeleton variant="rounded" animation="wave" width={90} height={27} />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 0.5 }}>
            <Box display="flex" alignItems="center">
              <Typography component="span" mr={1}>
                {t(`translation.label.status`)}:
              </Typography>
              {workOrder ? (
                <Typography component="span" fontWeight="bold">
                  {statusRender(workOrder.status)}
                </Typography>
              ) : (
                <Skeleton variant="rounded" animation="wave" width={90} height={19} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={5} lg={4} xl={3} display="flex">
            <Box display="flex" alignItems="center">
              <Typography component="span" mr={1}>
                {t(`translation.label.lastStatusChangeDate`)}:
              </Typography>
              {workOrder ? (
                <Typography component="span" fontWeight="bold">
                  {DateTime.fromISO(workOrder.lastStatusChangeDate).toFormat(DATE_FORMAT)}
                </Typography>
              ) : (
                <Skeleton variant="rounded" animation="wave" width={90} height={19} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={4} xl={3} display="flex">
            <Box display="flex" alignItems="center">
              <Typography component="span" mr={1}>
                {t(`translation.label.createdDate`)}:
              </Typography>
              {workOrder ? (
                <Typography component="span" fontWeight="bold">
                  {DateTime.fromISO(workOrder.createdDate).toFormat(DATE_FORMAT)}
                </Typography>
              ) : (
                <Skeleton variant="rounded" animation="wave" width={90} height={19} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} xl={6} sx={{ mt: { xs: 2, lg: 0 } }}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', lg: 'flex-end' } }}>
              <Button
                size="small"
                component={RouterLink}
                color="inherit"
                variant="outlined"
                sx={{ minWidth: 140, mr: 3 }}
                disabled={isDisabled(0)}
                to={paths.build(paths.WORK_ORDER_DETAILS, projectId, getPrevOrNextWorkOrder(-1))}
              >
                <ArrowBack sx={{ mr: 1 }} fontSize="small" />
                {t('translation.button.previous')}
              </Button>
              <Button
                size="small"
                component={RouterLink}
                color="inherit"
                variant="outlined"
                sx={{ minWidth: 140 }}
                disabled={isDisabled((workOrderIds?.length || 0) - 1)}
                to={paths.build(paths.WORK_ORDER_DETAILS, projectId, getPrevOrNextWorkOrder(1))}
              >
                {t('translation.button.next')}
                <ArrowForward sx={{ ml: 1 }} fontSize="small" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {workOrder && <WorkOrderData workOrder={workOrder} />}
    </>
  );
};
