import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { useSnackbar } from 'notistack';
import { FileType } from '../constants/enum';
import { useTranslation } from 'react-i18next';
import { AxiosProgressEvent } from 'axios';

export interface ProjectDocumentData {
  id: number;
  projectId: number;
  fileType: FileType;
  filename: string;
  originalname: string;
  fileUrl: string;
  deletedAt: string | null;
}

export interface ProjectDocumentsParams {
  files: File[];
  projectId?: number;
  progressCallback?: (progressEvent: AxiosProgressEvent) => void;
  onSuccess?: () => void;
}

const getProjectDocuments = async (id: number) => {
  try {
    const { data } = await axiosInstance.get('/project-document', {
      params: { projectId: id },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const uploadProjectDocuments = async (projectImportVariables: ProjectDocumentsParams) => {
  try {
    const { files, projectId, progressCallback } = projectImportVariables;

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    const { data } = await axiosInstance.post(`/project-document/${projectId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progressCallback && progressCallback(progressEvent);
      },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteProjectDocument = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/project-document/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useProjectDocuments(
  id: number,
  options?: UseQueryOptions<ProjectDocumentData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['project-documents', id],
    queryFn: () => getProjectDocuments(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useUploadProjectDocuments(
  projectId: number,
  options?: UseMutationOptions<ProjectDocumentData[], CustomApiError, ProjectDocumentsParams>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<ProjectDocumentData[], CustomApiError, ProjectDocumentsParams>(
    (data) => uploadProjectDocuments({ ...data, projectId }),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newProjectDocuments) => {
        // ✅ update detail view directly
        queryClient.setQueryData(
          ['project-documents', newProjectDocuments[0].projectId],
          (oldData: ProjectDocumentData[] | undefined) => {
            if (oldData) {
              return [...oldData, ...newProjectDocuments];
            }
            return newProjectDocuments;
          },
        );
        enqueueSnackbar(t('translation.notification.projectDocumentsUploaded'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useDeleteProjectDocument(
  projectId: number,
  options?: UseMutationOptions<number, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (projectId) => deleteProjectDocument(projectId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (id) => {
        queryClient.setQueryData(
          ['project-documents', projectId],
          (oldData: ProjectDocumentData[] | undefined) => {
            if (oldData) {
              const documents = [...oldData];
              const newDocuments = documents.filter((doc) => doc.id !== id);

              return newDocuments;
            }
            return oldData;
          },
        );

        enqueueSnackbar(t('translation.notification.projectDocumentDeleted'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}
