import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
} from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { DataTable } from '../../../components';
import { DATE_FORMAT } from '../../../constants/date';
import { ProjectProgress } from '../../../constants/enum';
import { paths } from '../../../constants/paths';
import { allAdminRoles } from '../../../constants/permissionGroups';
import { PermissionWrapper } from '../../../containers';
import { useUserDataContext } from '../../../containers/Layout/context';
import { useProjects } from '../../../hooks';
import { useFilterParams } from '../../../hooks/filterParams';
import { isAdminRole } from '../../../utils/permissions';
import { ProjectsActionsColumn } from './components/ActionsColumn';

export const ProjectList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserDataContext();
  const [{ where }] = useFilterParams();
  const { data, isLoading, isFetching } = useProjects();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px', cursor: 'pointer' } }),
        filterList: where?.id ? [where.id] : undefined,
      },
    },
    {
      label: t('translation.label.projectName'),
      name: 'name',
      options: {
        setCellProps: () => ({ style: { minWidth: '225px', cursor: 'pointer' } }),
        filterList: where?.name ? [where.name] : undefined,
      },
    },
    {
      label: t('translation.label.startDate'),
      name: 'startDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
        filterList: where?.startDateRange
          ? Array.isArray(where?.startDateRange)
            ? where?.startDateRange
            : [where?.startDateRange]
          : undefined,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0] && value[1]) {
              return [
                `${t('translation.label.minStartDate')}: ${DateTime.fromISO(value[0]).toFormat(
                  DATE_FORMAT,
                )}`,
                `${t('translation.label.maxStartDate')}: ${DateTime.fromISO(value[1]).toFormat(
                  DATE_FORMAT,
                )}`,
              ];
            } else if (value[0]) {
              return `${t('translation.label.minStartDate')}: ${DateTime.fromISO(value[0]).toFormat(
                DATE_FORMAT,
              )}`;
            } else if (value[1]) {
              return `${t('translation.label.maxStartDate')}: ${DateTime.fromISO(value[1]).toFormat(
                DATE_FORMAT,
              )}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            return (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
                  <Grid container>
                    <Grid item xs={6} paddingRight={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.minStartDate')}
                        value={DateTime.fromISO(filterList[index][0]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][0] = newValue.toISO();
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} paddingLeft={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.maxStartDate')}
                        value={DateTime.fromISO(filterList[index][1]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][1] = newValue.toISO();
                              if (!filterList[index][0]) {
                                filterList[index][0] = DateTime.fromISO('2000-01-01').toISO();
                              }
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </DemoContainer>
              </LocalizationProvider>
            );
          },
        },
      },
    },
    {
      label: t('translation.label.dueDate'),
      name: 'dueDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
        filterList: where?.dueDateRange
          ? Array.isArray(where?.dueDateRange)
            ? where?.dueDateRange
            : [where?.dueDateRange]
          : undefined,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0] && value[1]) {
              return [
                `${t('translation.label.minDueDate')}: ${DateTime.fromISO(value[0]).toFormat(
                  DATE_FORMAT,
                )}`,
                `${t('translation.label.maxDueDate')}: ${DateTime.fromISO(value[1]).toFormat(
                  DATE_FORMAT,
                )}`,
              ];
            } else if (value[0]) {
              return `${t('translation.label.minDueDate')}: ${DateTime.fromISO(value[0]).toFormat(
                DATE_FORMAT,
              )}`;
            } else if (value[1]) {
              return `${t('translation.label.maxDueDate')}: ${DateTime.fromISO(value[1]).toFormat(
                DATE_FORMAT,
              )}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            return (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
                  <Grid container>
                    <Grid item xs={6} paddingRight={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.minDueDate')}
                        value={DateTime.fromISO(filterList[index][0]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][0] = newValue.toISO();
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} paddingLeft={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.maxDueDate')}
                        value={DateTime.fromISO(filterList[index][1]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][1] = newValue.toISO();
                              if (!filterList[index][0]) {
                                filterList[index][0] = DateTime.fromISO('2000-01-01').toISO();
                              }
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </DemoContainer>
              </LocalizationProvider>
            );
          },
        },
      },
    },
    {
      label: t('translation.label.completedPercent'),
      name: 'completedPercent',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '150px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          return <span>{value}%</span>;
        },
      },
    },
    {
      label: t('translation.label.progressStatus'),
      name: 'progressStatus',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '200px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          switch (value) {
            case ProjectProgress.ON_SCHEDULE:
              return (
                <Box component="span" sx={{ color: 'success.main' }}>
                  {t('translation.enum.ON_SCHEDULE')}
                </Box>
              );
            case ProjectProgress.BEHIND_SCHEDULE:
              return (
                <Box component="span" sx={{ color: 'error.main' }}>
                  {t('translation.enum.BEHIND_SCHEDULE')}
                </Box>
              );
            default:
              return <span>{t(`translation.enum.${value}`)}</span>;
          }
        },
      },
    },
    {
      name: 'archived',
      options: {
        display: false,
        sort: false,
        filterList: where?.archived ? [where.archived] : undefined,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0]) {
              return `${t('translation.label.showArchived')}: ${t(
                'translation.common.yes',
              ).toUpperCase()}`;
            }
            return undefined;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('translation.label.showArchived')}
                  name="archived"
                  checked={!!filterList[index][0]}
                  onChange={(_, checked) => {
                    if (checked) {
                      filterList[index][0] = checked as any;
                    } else {
                      filterList[index] = [];
                    }
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            );
          },
        },
      },
    },
  ];

  if (isAdminRole(user.role)) {
    columns.push({
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        customBodyRenderLite: (dataIndex) => {
          if (data?.data[dataIndex].id) {
            const allowEdit =
              !data.data[dataIndex].archived && !data.data[dataIndex].markedComplete;
            return (
              <ProjectsActionsColumn projectId={data?.data[dataIndex].id} allowEdit={allowEdit} />
            );
          }
          return null;
        },
      },
    });
  }

  // table component returs weird params on filter change
  // iterating over 2d array of filter values and constructing
  // filter object with properties of format { [columnName]: filterValue }
  const buildFilters = (filterList: Array<Array<string>>) => {
    if (filterList.length > 0) {
      const filters: Record<string, any> = {};
      filterList.forEach((filter, index) => {
        if (filter.length > 0) {
          const colName = columns[index].name;
          if (colName === 'startDate' || colName === 'dueDate') {
            const key = `where[${colName}Range]`;
            filters[key] = [];
            if (DateTime.fromISO(filter[0]).isValid) {
              filters[key].push(DateTime.fromISO(filter[0]).toFormat('y-LL-dd'));
            }
            if (DateTime.fromISO(filter[1]).isValid) {
              filters[key].push(DateTime.fromISO(filter[1]).toFormat('y-LL-dd'));
            }
          } else {
            const key = colName === 'company.name' ? 'where[companyName]' : `where[${colName}]`;
            filters[key] = filter[0];
          }
        }
      });
      if (Object.keys(filters).length < 1) {
        return null;
      }
      return filters;
    }
    return null;
  };

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <PermissionWrapper whitelist={allAdminRoles}>
            <Grid item xs={12} sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                component={Link}
                to={paths.PROJECT_IMPORT_CREATE}
              >
                {t('translation.button.importNew')}
              </Button>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12}>
            <DataTable
              data={data?.data}
              columns={columns}
              count={data?.meta.count}
              isLoading={isLoading}
              isFetching={isFetching}
              customBuildFilters={buildFilters}
              title="translation.projectListPage.title"
              options={{
                onRowClick: (_, { dataIndex }) => {
                  const projectId = data?.data[dataIndex].id;
                  if (projectId) {
                    navigate(paths.build(paths.PROJECT_DETAILS, projectId), {
                      state: location.search || null,
                    });
                  }
                },
                onCellClick: (_, { colIndex, event }) => {
                  // Account index for hidden column (archived) used only to display filter option
                  if (colIndex > 5) {
                    event.stopPropagation();
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
