import { FC, Fragment } from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SidebarProps } from '../interface';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

export const Sidebar: FC<SidebarProps> = ({ navItems, handleDrawerToggle, mobileOpen }) => {
  const { t } = useTranslation();

  const drawer = (
    <Box sx={{ textAlign: 'center', px: 2 }}>
      <Typography variant="h6" sx={{ my: 1.5 }}>
        HP Solve
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Fragment key={item.name}>
            <ListItem disablePadding sx={{ borderRadius: 2 }}>
              <ListItemButton
                sx={{ borderRadius: 1 }}
                disabled={!!item.children}
                component={!!item.children ? 'div' : Link}
                to={item.to}
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={t(item.name)} />
              </ListItemButton>
            </ListItem>
            {item.children?.map((item) => (
              <ListItem key={item.name} disablePadding sx={{ borderRadius: 2 }}>
                <ListItemButton
                  sx={{ borderRadius: 1, px: 4 }}
                  component={Link}
                  to={item.to}
                  onClick={handleDrawerToggle}
                >
                  <ListItemText primary={t(item.name)} />
                </ListItemButton>
              </ListItem>
            ))}
          </Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
