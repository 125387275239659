import { FC } from 'react';
import { Container, Paper, Grid, Stack, Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  ReplacementGroupCard,
  PhotosAndDocuments,
  WorkOrdersMap,
  CustomAccordion,
} from '../../../../components';
import { WorkOrderDataPoint } from './WorkOrderDataPoint';
import { WorkOrderData as IWorkOrderData } from '../../../../hooks/workOrders';
import {
  useDeleteWorkOrderDocument,
  useUploadWorkOrderDocuments,
  useWorkOrderDocuments,
} from '../../../../hooks';
import { DATE_FORMAT } from '../../../../constants/date';

interface WorkOrderDataProps {
  workOrder: IWorkOrderData;
}

export const WorkOrderData: FC<WorkOrderDataProps> = ({ workOrder }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: workOrderDocuments } = useWorkOrderDocuments(workOrder.id);

  const { mutate: deleteDocument } = useDeleteWorkOrderDocument(workOrder.id);
  const { mutateAsync: uploadDocuments, isLoading: isUploadingDocuments } =
    useUploadWorkOrderDocuments(workOrder.id);

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          {/* WORK ORDER DETAILS */}
          <Grid item xs={12} sx={{ mb: 4 }}>
            <CustomAccordion title={t('translation.workOrderDetailsPage.details')}>
              <Grid container spacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <Stack spacing={2}>
                    <WorkOrderDataPoint
                      label={t('translation.label.accountNumber')}
                      value={workOrder.accountNumber}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.accountReadSequence')}
                      value={workOrder.accountReadSequence}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.address')}
                      value={workOrder.address}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.subcontractor')}
                      value={workOrder.company?.name}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.installDate')}
                      value={
                        workOrder.installDate
                          ? DateTime.fromISO(workOrder.installDate).toFormat(DATE_FORMAT)
                          : null
                      }
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.installTime')}
                      value={workOrder.installTime}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assignedDate')}
                      value={
                        workOrder.assignedDate
                          ? DateTime.fromISO(workOrder.assignedDate).toFormat(DATE_FORMAT)
                          : null
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <Stack spacing={2}>
                    <WorkOrderDataPoint
                      label={t('translation.label.latitude')}
                      value={workOrder.latitude}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.longitude')}
                      value={workOrder.longitude}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.locationCode')}
                      value={workOrder.locationCode}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetMxuId')}
                      value={workOrder.assetMxuId}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.finalRead')}
                      value={workOrder.finalRead}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.finalDemandRead')}
                      value={workOrder.finalDemandRead}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.oldAssetEIDNumber')}
                      value={workOrder.oldAssetEIDNumber}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.locationDescription')}
                        value={workOrder.locationDescription}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.customerName')}
                        value={workOrder.customerName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.zoneId')}
                        value={workOrder.zoneId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.oldAssetReturnDate')}
                        value={
                          workOrder.oldAssetReturnDate
                            ? DateTime.fromISO(workOrder.oldAssetReturnDate).toFormat(DATE_FORMAT)
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.cityServiceLineMaterial')}
                        value={workOrder.cityServiceLineMaterial}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.customerServiceLineMaterial')}
                        value={workOrder.customerServiceLineMaterial}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.assetNotes')}
                        value={workOrder.assetNotes}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      display={{ xs: 'none', sm: 'block', md: 'none' }}
                    >
                      <WorkOrderDataPoint
                        label={t('translation.label.installNotes')}
                        value={workOrder.installNotes}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} xl={9} display={{ sm: 'none', md: 'block' }}>
                  <WorkOrderDataPoint
                    label={t('translation.label.installNotes')}
                    value={workOrder.installNotes}
                  />
                </Grid>
              </Grid>
            </CustomAccordion>
          </Grid>
          {/* OLD ASSET */}
          <Grid item xs={12} sx={{ mb: 4 }}>
            <CustomAccordion
              title={t('translation.workOrderDetailsPage.oldAssetDetails')}
              defaultExpanded={false}
            >
              <Grid container spacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <Stack spacing={2}>
                    <WorkOrderDataPoint
                      label={t('translation.label.assetSerialNumber')}
                      value={workOrder.assetSerialNumber}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetInstallDate')}
                      value={
                        workOrder.assetInstallDate
                          ? DateTime.fromISO(workOrder.assetInstallDate).toFormat(DATE_FORMAT)
                          : null
                      }
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetType')}
                      value={workOrder.assetType}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetSize')}
                      value={workOrder.assetSize}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetGroup')}
                      value={workOrder.assetGroup}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <Stack spacing={2}>
                    <WorkOrderDataPoint
                      label={t('translation.label.assetMake')}
                      value={workOrder.assetMake}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetScale')}
                      value={workOrder.assetScale}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetDials')}
                      value={workOrder.assetDials}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.assetRateTable')}
                      value={workOrder.assetRateTable}
                    />
                    <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
                      <WorkOrderDataPoint
                        label={t('translation.label.assetMxuId')}
                        value={workOrder.assetMxuId}
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      display={{ xs: 'block', sm: 'none', md: 'block' }}
                    >
                      <WorkOrderDataPoint
                        label={t('translation.label.assetMxuId')}
                        value={workOrder.assetMxuId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.assetMxuType')}
                        value={workOrder.assetMxuType}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.assetLastRead')}
                        value={workOrder.assetLastRead}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.assetLastReadDate')}
                        value={
                          workOrder.assetLastReadDate
                            ? DateTime.fromISO(workOrder.assetLastReadDate).toFormat(DATE_FORMAT)
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomAccordion>
          </Grid>
          {/* NEW ASSET */}
          <Grid item xs={12} sx={{ mb: 4 }}>
            <CustomAccordion
              title={t('translation.workOrderDetailsPage.newAssetDetails')}
              defaultExpanded={false}
            >
              <Grid container spacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <Stack spacing={2}>
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetClassification')}
                      value={workOrder.newAssetClassification}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetActivation')}
                      value={workOrder.newAssetActivation}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetId')}
                      value={workOrder.newAssetId}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetSerialNumber')}
                      value={workOrder.newAssetSerialNumber}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetLatitude')}
                      value={workOrder.newAssetLatitude}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetLongitude')}
                      value={workOrder.newAssetLongitude}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <Stack spacing={2}>
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetType')}
                      value={workOrder.newAssetType}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetSize')}
                      value={workOrder.newAssetSize}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetGroup')}
                      value={workOrder.newAssetGroup}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetMake')}
                      value={workOrder.newAssetMake}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetRead')}
                      value={workOrder.newAssetRead}
                    />
                    <WorkOrderDataPoint
                      label={t('translation.label.newAssetDemandRead')}
                      value={workOrder.newAssetDemandRead}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.newAssetDials')}
                        value={workOrder.newAssetDials}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.newAssetScale')}
                        value={workOrder.newAssetScale}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.newAssetMxuId')}
                        value={workOrder.newAssetMxuId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.newAssetMxuType')}
                        value={workOrder.newAssetMxuType}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.newAssetRateTable')}
                        value={workOrder.newAssetRateTable}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <WorkOrderDataPoint
                        label={t('translation.label.installedBy')}
                        value={workOrder.installedBy}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomAccordion>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography variant="h6" fontSize={20}>
              {t('translation.projectDetailsPage.replacementGroups')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {workOrder.replacementGroup ? (
                  <ReplacementGroupCard
                    defaultExpanded={true}
                    replacementGroup={workOrder.replacementGroup}
                    assetModels={[]}
                    preview={true}
                    modifyWhitelist={[]}
                  />
                ) : (
                  <Typography textAlign="center" my={5}>
                    {t('translation.createProjectPage.noReplacementGroups')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <Paper
              sx={{
                background:
                  theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
                p: 2,
                mt: 3,
              }}
            >
              <PhotosAndDocuments
                documents={workOrderDocuments ?? []}
                deleteDocument={deleteDocument}
                uploadDocuments={uploadDocuments}
                isUploadingDocuments={isUploadingDocuments}
              />
            </Paper>
          </Grid>
          {workOrder.latitude && workOrder.longitude && (
            <Grid item xs={12}>
              <Paper
                sx={{
                  background:
                    theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
                  p: 2,
                  mt: 3,
                }}
              >
                <Typography fontSize={20} variant="h6" mb={2}>
                  {t('translation.projectDetailsPage.mapView')}
                </Typography>
                <Box height={{ xs: 300, md: 400 }}>
                  <WorkOrdersMap
                    zoom={16}
                    center={{ lat: Number(workOrder.latitude), lng: Number(workOrder.longitude) }}
                    workOrders={[
                      {
                        id: workOrder.id,
                        status: workOrder.status,
                        latitude: Number(workOrder.latitude),
                        longitude: Number(workOrder.longitude),
                        projectId: workOrder.projectId,
                        projectName: workOrder.project?.name || '',
                      },
                    ]}
                  />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
};
