import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { Language } from '../constants/enum';

const getLanguage = () => {
  const storedLang = localStorage.getItem('language') || 'en';
  if (Object.keys(Language).includes(storedLang)) {
    return storedLang;
  }
  return 'en';
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    preload: ['en', 'es'],
    // debug: true,
    returnNull: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    lng: getLanguage(),
  });

export default i18n;
