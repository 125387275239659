import { FC } from 'react';
import { Grid, Box, Button, MenuItem, TextField, useTheme, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WorkOrderEditFormProps } from '../interface';
import { paths } from '../../../../constants/paths';
import { useWorkOrderForm } from '../hooks/useWorkOrderForm';
import { ServiceLineMaterial, WorkOrderStatus } from '../../../../constants/enum';

export const WorkOrderEditMobileForm: FC<WorkOrderEditFormProps> = ({ workOrder, project }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    formData,
    /*submitted,*/
    isLoading,
    serialNumbersMatch,
    sizeOptions,
    boxOptions,
    handleSerialNumbersMatchChange,
    handleChange,
    handleGetlocation,
    handleSubmit,
  } = useWorkOrderForm({
    workOrder,
    project,
  });

  return (
    <Grid item xs={12} display={{ md: 'none' }}>
      <Box component="form" onSubmit={handleSubmit}>
        <Paper
          sx={{
            background: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
            p: 2,
            width: '100%',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label={t('translation.label.status')}
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                {Object.keys(WorkOrderStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(`translation.enum.${status}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.address')}
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.oldAssetSerialNumber')}
                name="assetSerialNumber"
                inputProps={{ inputMode: 'numeric' }}
                value={formData.assetSerialNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label={t('translation.label.serialNumbersMatch')}
                name="serialNumbersMatch"
                value={serialNumbersMatch}
                onChange={handleSerialNumbersMatchChange}
              >
                <MenuItem value="yes">{t(`translation.common.yes`)}</MenuItem>
                <MenuItem value="no">{t(`translation.common.no`)}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.newAssetSerialNumber')}
                name="newAssetSerialNumber"
                inputProps={{ inputMode: 'numeric' }}
                disabled={serialNumbersMatch === 'yes'}
                value={formData.newAssetSerialNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.assetLastRead')}
                name="assetLastRead"
                inputProps={{ inputMode: 'decimal' }}
                value={formData.assetLastRead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.finalRead')}
                name="finalRead"
                inputProps={{ inputMode: 'decimal' }}
                value={formData.finalRead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.newAssetId')}
                name="newAssetId"
                inputProps={{ inputMode: 'numeric' }}
                value={formData.newAssetId}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.newAssetSize')}
                name="newAssetSize"
                select
                value={formData.newAssetSize}
                onChange={handleChange}
              >
                <MenuItem value="">{t(`translation.common.selectOption`)}</MenuItem>
                {sizeOptions.map((size) => (
                  <MenuItem key={size.value} value={size.value}>
                    {size.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.boxChangeout')}
                name="boxChangeout"
                select
                value={formData.boxChangeout}
                onChange={handleChange}
              >
                <MenuItem selected={true} value="">
                  {t(`translation.common.selectOption`)}
                </MenuItem>
                {boxOptions.map((box) => (
                  <MenuItem key={box.value} value={box.value}>
                    {box.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.curbStop')}
                name="curbStop"
                select
                value={formData.curbStop}
                onChange={handleChange}
              >
                <MenuItem selected={true} value="">
                  {t(`translation.common.selectOption`)}
                </MenuItem>
                <MenuItem value="yes">{t(`translation.common.yes`)}</MenuItem>
                <MenuItem value="no">{t(`translation.common.no`)}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.accountReadSequence')}
                name="accountReadSequence"
                inputProps={{ inputMode: 'numeric' }}
                value={formData.accountReadSequence}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.newAssetLatitude')}
                name="newAssetLatitude"
                type="number"
                value={formData.newAssetLatitude}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.newAssetLongitude')}
                name="newAssetLongitude"
                type="number"
                value={formData.newAssetLongitude}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button variant="contained" onClick={handleGetlocation}>
                {t('translation.button.getCoordinates')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.installedBy')}
                name="installedBy"
                value={formData.installedBy}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.locationCode')}
                name="locationCode"
                inputProps={{ inputMode: 'numeric' }}
                value={formData.locationCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.locationDescription')}
                name="locationDescription"
                value={formData.locationDescription}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label={t('translation.label.cityServiceLineMaterial')}
                name="cityServiceLineMaterial"
                value={formData.cityServiceLineMaterial ?? ''}
                onChange={handleChange}
              >
                {Object.entries(ServiceLineMaterial).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {t(`translation.enum.${key}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label={t('translation.label.customerServiceLineMaterial')}
                name="customerServiceLineMaterial"
                value={formData.customerServiceLineMaterial ?? ''}
                onChange={handleChange}
              >
                {Object.entries(ServiceLineMaterial).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {t(`translation.enum.${key}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.assetNotes')}
                name="assetNotes"
                value={formData.assetNotes}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.installNotes')}
                name="installNotes"
                value={formData.installNotes}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label={t('translation.label.status')}
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                {Object.keys(WorkOrderStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(`translation.enum.${status}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={4}>
          <Grid item xs={12} gap={2} sx={{ display: 'flex', justifyContent: 'right', mt: 4 }}>
            <Button
              color="inherit"
              variant="text"
              sx={{ minWidth: 120 }}
              component={Link}
              to={{ pathname: paths.build(paths.WORK_ORDER_DETAILS, project.id, workOrder.id) }}
            >
              {t('translation.button.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ minWidth: 120 }}
              type="submit"
              loading={isLoading}
            >
              {t('translation.button.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
