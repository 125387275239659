import { ChangeEvent, ChangeEventHandler, FC } from 'react';
import {
  Checkbox,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProjectImportGrouperWorkOrderData } from '../../../../hooks/projectImports';
import { ReplacementGroupData } from '../../../../hooks/replacementGroups';

interface AssetGroupCardProps {
  assetGroup: ProjectImportGrouperWorkOrderData;
  replacementGroups: ReplacementGroupData[];
  value: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onCheckChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const AssetGroupCard: FC<AssetGroupCardProps> = ({
  assetGroup,
  replacementGroups,
  value,
  checked,
  onChange,
  onCheckChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanMed = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper
      sx={{ backgroundColor: 'background.default', backgroundImage: 'none', px: 2, py: 3, mb: 2 }}
    >
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={2} sm={1} display="flex" alignItems="center">
          <FormGroup>
            <Checkbox checked={checked} onChange={onCheckChange} />
          </FormGroup>
        </Grid>
        <Grid item xs={10} sm={11}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              display="flex"
              alignItems="center"
              justifyContent={{ xs: 'center', sm: 'left' }}
              mb={{ xs: 3, sm: 0 }}
              pl={{ xs: 0, sm: 1, md: 0 }}
            >
              <Grid container>
                {assetGroup.assetMake && (
                  <Grid item xs={6} md={3} mb={{ xs: 2, md: 0 }}>
                    <Typography
                      component="div"
                      color="text.secondary"
                      fontSize={lessThanMed ? 14 : 16}
                    >
                      {t('translation.label.assetMake')}:
                    </Typography>
                    <Typography component="div" fontSize={lessThanMed ? 14 : 16}>
                      {assetGroup.assetMake}
                    </Typography>
                  </Grid>
                )}
                {assetGroup.assetType && (
                  <Grid item xs={6} md={3} mb={{ xs: 2, md: 0 }}>
                    <Typography
                      component="div"
                      color="text.secondary"
                      fontSize={lessThanMed ? 14 : 16}
                    >
                      {t('translation.label.assetType')}:
                    </Typography>
                    <Typography component="div" fontSize={lessThanMed ? 14 : 16}>
                      {assetGroup.assetType}
                    </Typography>
                  </Grid>
                )}
                {assetGroup.assetGroup && (
                  <Grid item xs={6} md={3} mb={{ xs: 2, md: 0 }}>
                    <Typography
                      component="div"
                      color="text.secondary"
                      fontSize={lessThanMed ? 14 : 16}
                    >
                      {t('translation.label.assetGroup')}:
                    </Typography>
                    <Typography component="div" fontSize={lessThanMed ? 14 : 16}>
                      {assetGroup.assetGroup}
                    </Typography>
                  </Grid>
                )}
                {assetGroup.assetSize && (
                  <Grid item xs={6} md={3} mb={{ xs: 2, md: 0 }}>
                    <Typography
                      component="div"
                      color="text.secondary"
                      fontSize={lessThanMed ? 14 : 16}
                    >
                      {t('translation.label.assetSize')}:
                    </Typography>
                    <Typography component="div" fontSize={lessThanMed ? 14 : 16}>
                      {assetGroup.assetSize}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} display="flex" alignItems="center">
              <TextField
                id="replacement-group"
                select
                fullWidth
                label={t('translation.label.replacementGroup')}
                disabled={replacementGroups.length < 1}
                value={value}
                onChange={onChange}
              >
                {replacementGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
