import { FC, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Box, Button, Skeleton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { ProfileMenu } from './ProfileMenu';
import { DropdownMenu } from './DropdownMenu';
import { HeaderProps } from '../interface';
import { Language } from '../../../constants/enum';

export const Header: FC<HeaderProps> = ({ navItems, isLoading, handleDrawerToggle }) => {
  const { i18n } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <AppBar component="nav">
      <Toolbar ref={anchorRef} disableGutters sx={{ px: { xs: 3, md: 6 } }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 1, display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            width: { xs: '100%', md: 'auto' },
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{ height: 'auto', width: { xs: 125, md: 150 } }}
            component="img"
            src="/HP-Logo.png"
          />
        </Box>
        <Box
          sx={{ mx: { xs: 2, md: 6 }, alignSelf: 'stretch', display: { xs: 'none', md: 'flex' } }}
        >
          {isLoading ? (
            <>
              <Box sx={{ width: 120, my: 'auto', mx: 1 }}>
                <Skeleton animation="wave" />
              </Box>
              <Box sx={{ width: 120, my: 'auto', mx: 1 }}>
                <Skeleton animation="wave" />
              </Box>
            </>
          ) : (
            navItems.map((item) => <DropdownMenu key={item.name} item={item} />)
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            mx: { md: 3, lg: 6 },
            position: { xs: 'absolute', md: 'static' },
            top: { xs: 10, sm: 12 },
            right: { xs: 60, sm: 70 },
          }}
        >
          {Object.keys(Language).map((lng) => (
            <Button
              key={lng}
              disableRipple
              sx={{
                fontSize: { xs: 14, sm: 16 },
                px: { xs: 0.5, sm: 1 },
                minWidth: { xs: 30, sm: 50 },
                textDecoration: i18n.resolvedLanguage === lng ? 'underline !important' : 'none',
              }}
              color="inherit"
              variant="text"
              onClick={() => {
                localStorage.setItem('language', lng);
                i18n.changeLanguage(lng);
              }}
            >
              {lng}
            </Button>
          ))}
        </Box>
        <ProfileMenu anchorRef={anchorRef} />
      </Toolbar>
    </AppBar>
  );
};
