import { FC, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  confirmActionButton: ReactNode;
  title: string;
  text: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  handleClose,
  confirmActionButton,
  title,
  text,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { width: 500, maxWidth: '100%' },
      }}
    >
      <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t(text)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="text" onClick={handleClose}>
          {t('translation.button.cancel')}
        </Button>
        {confirmActionButton}
      </DialogActions>
    </Dialog>
  );
};
