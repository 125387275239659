import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  TextField,
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateAssetClassification, useUpdateAssetClassification } from '../../../../hooks';
import {
  AssetClassificationData,
  AssetClassificationInput,
} from '../../../../hooks/assetClassifications';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

interface AssetClassificationFormProps {
  assetClassification?: AssetClassificationData;
  handleClose: () => void;
}

export const AssetClassificationForm: FC<AssetClassificationFormProps> = ({
  handleClose,
  assetClassification,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [assetClassificationData, setAssetClassification] = useState<AssetClassificationInput>({
    name: assetClassification?.name || '',
  });

  const [submitted, setSubmitted] = useState(false);

  const createAssetClassification = useCreateAssetClassification({
    onSuccess: (newAssetClassification) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['asset-classifications'],
        (oldData: AssetClassificationData[] | undefined) =>
          oldData ? [...oldData, newAssetClassification] : oldData,
      );
      enqueueSnackbar(t('translation.notification.assetClassificationCreated'), {
        variant: 'success',
      });
      handleClose();
    },
  });
  const updateAssetClassification = useUpdateAssetClassification({
    onSuccess: (updatedClassification) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['asset-classifications'],
        (oldData: AssetClassificationData[] | undefined) => {
          if (oldData) {
            const updatedAssetClassifications = [...oldData];
            const index = updatedAssetClassifications.findIndex(
              (classification) => classification.id === updatedClassification.id,
            );

            if (index !== -1) {
              updatedAssetClassifications[index] = {
                ...updatedAssetClassifications[index],
                ...updatedClassification,
              };
            }
            return updatedAssetClassifications;
          }
          return oldData;
        },
      );
      enqueueSnackbar(t('translation.notification.assetClassificationUpdated'), {
        variant: 'success',
      });
      handleClose();
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      assetClassificationData.name &&
      !createAssetClassification.isLoading &&
      !updateAssetClassification.isLoading
    ) {
      if (assetClassification) {
        updateAssetClassification.mutate({
          id: assetClassification.id,
          data: assetClassificationData,
        });
      } else {
        createAssetClassification.mutate(assetClassificationData);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setAssetClassification((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {t(
          `translation.assetModelsListPage.${
            assetClassification ? 'edit' : 'create'
          }ClassificationModal.title`,
        )}
      </DialogTitle>
      <DialogContent sx={{ width: 600, maxWidth: '100%' }}>
        <Box component="form" id="assetClassification" onSubmit={handleSubmit}>
          <Grid container py={4}>
            <Grid item xs={12} sm={7}>
              <TextField
                sx={{ my: 1 }}
                fullWidth
                label={t('translation.label.classification')}
                name="name"
                value={assetClassificationData.name}
                error={submitted && !assetClassificationData.name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth: 120 }} color="inherit" variant="text" onClick={handleClose}>
          {t('translation.button.cancel')}
        </Button>
        <LoadingButton
          form="assetClassification"
          variant="contained"
          sx={{ minWidth: 120 }}
          type="submit"
          loading={createAssetClassification.isLoading || updateAssetClassification.isLoading}
        >
          {t('translation.button.save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};
