import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, MenuItem, Button, Box } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CompanyData, CompanyInput } from '../../../../hooks/company';
import { useCreateCompany, useUpdateCompany } from '../../../../hooks';
import { CompanyType } from '../../../../constants/enum';
import { paths } from '../../../../constants/paths';

interface CompanyFormProps {
  company?: CompanyData;
}

export const CompanyForm: FC<CompanyFormProps> = ({ company }) => {
  const location = useLocation();

  const [companyData, setCompany] = useState<CompanyInput>({
    name: company?.name || '',
    companyType: company?.companyType || undefined,
    address: company?.address || '',
    city: company?.city || '',
    state: company?.state || '',
    zip: company?.zip || '',
    phoneNumber: company?.phoneNumber,
    latitude: company?.latitude || 0,
    longitude: company?.longitude || 0,
  });

  const [submitted, setSubmitted] = useState(false);

  const createCompany = useCreateCompany();
  const updateCompany = useUpdateCompany();

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      companyData.name &&
      companyData.companyType &&
      !createCompany.isLoading &&
      !updateCompany.isLoading
    ) {
      if (company) {
        updateCompany.mutate({ id: company.id, data: companyData });
      } else {
        createCompany.mutate(companyData);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setCompany((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.name')}
            name="name"
            value={companyData.name}
            error={submitted && !companyData.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            select
            fullWidth
            label={t('translation.label.companyType')}
            name="companyType"
            value={companyData.companyType ?? ''}
            error={submitted && !companyData.companyType}
            onChange={handleChange}
          >
            {Object.keys(CompanyType).map((companyType) => (
              <MenuItem key={companyType} value={companyType}>
                {t(`translation.companyType.${companyType}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.address')}
            name="address"
            value={companyData.address}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.city')}
            name="city"
            value={companyData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.state')}
            name="state"
            value={companyData.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.zip')}
            name="zip"
            value={companyData.zip}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            type="number"
            label={t('translation.label.latitude')}
            name="latitude"
            value={companyData.latitude}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            type="number"
            label={t('translation.label.longitude')}
            name="longitude"
            value={companyData.longitude}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
        >
          <Button
            color="inherit"
            variant="text"
            sx={{ minWidth: 120, mr: 2 }}
            component={Link}
            to={{ pathname: paths.COMPANIES, search: location.state || '' }}
          >
            <Trans i18nKey="translation.button.cancel" />
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 120 }}
            type="submit"
            loading={createCompany.isLoading || updateCompany.isLoading}
          >
            <Trans i18nKey="translation.button.save" />
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
