import { t } from 'i18next';
import { ApiError } from '../interfaces';

export class CustomApiError extends Error {
  constructor({ statusCode, message, error }: ApiError) {
    super(message);
    this.statusCode = statusCode;
    this.error = error;
  }

  statusCode: number;
  error?: string;
}

export const errorHandling = (error: any) => {
  const message =
    typeof error?.response?.data?.message === 'string'
      ? error.response.data.message
      : 'errorOccurred';
  if (error?.response?.data?.error) {
    throw new CustomApiError({
      statusCode: error.response.data?.statusCode || 999,
      error: error.response.data?.error,
      message,
    });
  }

  throw new CustomApiError({
    statusCode: error?.response?.data?.statusCode || 999,
    message,
  });
};

export const getErrorDisplayMessage = (error: CustomApiError) => {
  if (error.statusCode === 999) {
    return t('translation.error.errorOccurred');
  }

  if (typeof error.error === 'string' && typeof error.message === 'string') {
    return t(`translation.error.${error.message}`);
  }

  switch (error.statusCode) {
    case 400:
      return t('translation.error.badRequest');
    case 401:
      return t('translation.error.unauthorized');
    case 403:
      return t('translation.error.forbidden');
    case 404:
      return t('translation.error.notFound');
    default:
      return t('translation.error.errorOccurred');
  }
};
