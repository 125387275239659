import { FC } from 'react';
import { Dialog } from '@mui/material';
import { AssetSizeData } from '../../../../hooks/assetSizes';
import { AssetClassificationData } from '../../../../hooks/assetClassifications';
import { AssetManufacturerData } from '../../../../hooks/assetManufacturers';
import { AssetClassificationForm } from './AssetClassificationForm';
import { AssetSizeForm } from './AssetSizeForm';
import { AssetManufacturerForm } from './AssetManufacturerForm';

interface CreateUpdateModalProps {
  open: boolean;
  handleClose: () => void;
  type?: 'Classification' | 'Manufacturer' | 'Size';
  data?: AssetSizeData | AssetClassificationData | AssetManufacturerData;
}

export const CreateUpdateModal: FC<CreateUpdateModalProps> = ({
  open,
  handleClose,
  type,
  data,
}) => {
  const renderForm = () => {
    switch (type) {
      case 'Classification':
        return (
          <AssetClassificationForm
            assetClassification={data as AssetClassificationData}
            handleClose={handleClose}
          />
        );
      case 'Size':
        return <AssetSizeForm assetSize={data as AssetSizeData} handleClose={handleClose} />;
      case 'Manufacturer':
        return (
          <AssetManufacturerForm
            assetManufacturer={data as AssetManufacturerData}
            handleClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { width: 700, maxWidth: '100%' },
      }}
    >
      {renderForm()}
    </Dialog>
  );
};
