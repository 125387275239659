import { Box, Container, Grid, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useCompaniesSummary, useSingleProjectImport } from '../../../hooks';
import { CompanyType, Flow, ProjectImportStatus } from '../../../constants/enum';
import { ProjectCreateForm } from './components/ProjectCreateForm';
import { paths } from '../../../constants/paths';

const steps = ['translation.createProjectPage.step1', 'translation.createProjectPage.step2'];

export const CreateProject = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { projectImportId } = useParams();

  const {
    data: projectImport,
    isLoading: isPiLoading,
    isFetching: isPiFetching,
  } = useSingleProjectImport(Number(projectImportId));

  const { data: subcontractors, isLoading: isSubcontractorsLoading } = useCompaniesSummary([
    CompanyType.SUBCONTRACTOR,
  ]);
  const { data: utilities, isLoading: isUtilitiesLoading } = useCompaniesSummary([
    CompanyType.UTILITY,
  ]);

  if (isSubcontractorsLoading || isUtilitiesLoading || isPiLoading || isPiFetching) {
    return null;
  }

  if (
    projectImport?.flow !== Flow.IMPORT ||
    projectImport?.status !== ProjectImportStatus.PARSE_SUCCESS
  ) {
    return <Navigate to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }} />;
  }

  if (projectImport.projectId) {
    return (
      <Navigate
        state={location.state || ''}
        to={paths.build(paths.PROJECT_FINALIZE, projectImport.id)}
      />
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.createProjectPage.title')}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box sx={{ width: { xs: '100%', md: '70%' } }}>
              <Stepper activeStep={1} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Grid>
        <ProjectCreateForm
          projectImportId={Number(projectImportId)}
          utilities={utilities || []}
          subcontractors={subcontractors || []}
        />
      </Paper>
    </Container>
  );
};
