import { FC, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails,
  AccordionSummaryProps,
  styled,
  Box,
  AccordionProps,
} from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
  backgroundImage: 'none',
  borderRadius: '4px',
  '&:not(:last-child)': {
    borderBottom: 0,
    marginBottom: '16px',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
  borderRadius: '4px',
  height: 64,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '&.Mui-expanded': {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

interface CustomAccordionProps {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  title: string;
  elevation?: number;
  noPadding?: boolean;
}

export const CustomAccordion: FC<CustomAccordionProps> = ({
  defaultExpanded = true,
  children,
  title,
  elevation = 1,
  noPadding = false,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = () => {
    setExpanded((exp) => !exp);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange} elevation={elevation}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={noPadding ? { px: 0 } : undefined}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography>{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={noPadding ? { px: 0 } : undefined}>{children}</AccordionDetails>
    </Accordion>
  );
};
