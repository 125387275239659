import { useMemo, FC, ReactNode, useReducer } from 'react';
import { createTheme, CssBaseline, PaletteMode } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ColorModeContext } from './context';

export const ColorModeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const initialMode = localStorage.getItem('theme') === 'light' ? 'light' : 'dark';

  const [mode, setMode] = useReducer((state: PaletteMode) => {
    const newState = state === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newState);
    return newState;
  }, initialMode);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: setMode,
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: { main: '#0073BE' },
          background: { default: mode === 'dark' ? '#262B2F' : '#D9D9D9' },
          success: { main: '#58FB8F' },
          secondary: { main: '#3D4347' },
          error: { main: '#FE7272' },
          //   action: {
          //     selected: '#0073BE31',
          //     active: '#0073BE31',
          //     hover: '#0073BE31',
          //   },
        },
        components: {
          MuiTextField: {
            defaultProps: {
              InputLabelProps: { shrink: true },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? '#3D4347' : '#FFFFFF',
              },
            },
          },
          MUIDataTable: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? '#262B2F !important' : '#FFFFFF',
                backgroundImage: 'none !important',
                borderRadius: 3,
              },
              tableRoot: {
                minHeight: 150,
              },
            },
          },
          MUIDataTableFilter: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? '#3D4347' : '#FFFFFF',
                backgroundImage: 'none',
              },
            },
          },
          MUIDataTableHeadCell: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? '#262B2F' : '#FFFFFF',
              },
              data: {
                textTransform: 'capitalize',
              },
            },
          },
          MUIDataTableToolbarSelect: {
            styleOverrides: {
              root: {
                height: 64,
              },
            },
          },
          MUIDataTableSelectCell: {
            styleOverrides: {
              headerCell: {
                backgroundColor: mode === 'dark' ? '#262B2F' : '#FFFFFF',
              },
            },
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? '#262B2F' : '#FFFFFF',
                backgroundImage: 'none',
              },
            },
          },
          MuiTableBody: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? '#3D4347' : '#FFFFFF',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              footer: {
                border: 'none',
              },
              root: {
                borderColor: mode === 'dark' ? '#262B2F' : '#E0E0E0',
              },
              body: {
                fontSize: 16,
                padding: 8,
              },
              head: {
                padding: 8,
              },
            },
          },
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
