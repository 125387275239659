import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { WorkOrderStatus } from '../../../constants/enum';
import { WorkOrdersMapDataEnhanced } from '../interface';

interface UseMapMarkersProps {
  workOrders: WorkOrdersMapDataEnhanced[];
}

const getIconUrl = (status: WorkOrderStatus) => {
  switch (status) {
    case WorkOrderStatus.UNABLE:
      return 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
    case WorkOrderStatus.ASSIGNED:
      return 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    case WorkOrderStatus.COMPLETED:
    case WorkOrderStatus.RETURNED:
      return 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
    case WorkOrderStatus.UNASSIGNED:
      return 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    default:
      return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
  }
};

export const useMapMarkers = ({ workOrders }: UseMapMarkersProps) => {
  const { t } = useTranslation();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markers = useRef<google.maps.Marker[] | null>(null);
  const markerCluster = useRef<MarkerClusterer | null>(null);

  useEffect(() => {
    if (workOrders && map) {
      if (markers.current) {
        markerCluster.current?.clearMarkers();
        markers.current.forEach((marker) => {
          marker.setMap(null);
        });
      }

      const infoWindow = new google.maps.InfoWindow({
        content: '',
        minWidth: 200,
        maxWidth: 500,
      });

      markers.current = workOrders.map((wo) => {
        const content = renderToString(
          <div style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 14, fontWeight: 400 }}>
            <div>
              <strong>{t('translation.label.status')}:</strong> {wo?.status}
            </div>
            <div>
              <strong>{t('translation.label.projectName')}:</strong> {wo?.projectName}
            </div>
          </div>,
        );

        const marker = new google.maps.Marker({
          map,
          position: { lat: Number(wo.latitude), lng: Number(wo.longitude) },
          icon: getIconUrl(wo.status),
        });

        marker.addListener('click', () => {
          infoWindow.setContent(content);
          infoWindow.setPosition({ lat: Number(wo.latitude), lng: Number(wo.longitude) });
          infoWindow.open(map, marker);
        });
        return marker;
      });

      map.addListener('mousedown', () => infoWindow.close());

      markerCluster.current = new MarkerClusterer({
        map,
        markers: markers.current,
        algorithm: new SuperClusterAlgorithm({
          minPoints: 500,
          radius: 60,
          maxZoom: 12,
        }),
      });
    }
  }, [workOrders, map, t]);

  return { setMap };
};
