import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { useSnackbar } from 'notistack';
import { Flow } from '../constants/enum';

export interface MappingData {
  id: number;
  name: string;
}

export interface MappingInput {
  name: string;
  flow: Flow;
  accountNumber: number | null;
  accountReadSequence: number | null;
  address: number | null;
  longitude: number | null;
  latitude: number | null;
  locationDescription: number | null;
  locationCode: number | null;
  customerName: number | null;
  assetId: number | null;
  assetSerialNumber: number | null;
  assetGroup: number | null;
  assetMake: number | null;
  assetType: number | null;
  assetSize: number | null;
  assetDials: number | null;
  assetScale: number | null;
  assetMxuId: number | null;
  assetMxuType: number | null;
  assetNotes: number | null;
  assetRateTable: number | null;
  assetLastRead: number | null;
  assetLastReadDate: number | null;
  assetInstallDate: number | null;
  zoneId: number | null;
  finalRead: number | null;
  finalDemandRead: number | null;
  newAssetId: number | null;
  newAssetLongitude: number | null;
  newAssetLatitude: number | null;
  newAssetSerialNumber: number | null;
  newAssetClassification: number | null;
  newAssetGroup: number | null;
  newAssetMake: number | null;
  newAssetType: number | null;
  newAssetSize: number | null;
  newAssetDials: number | null;
  newAssetScale: number | null;
  newAssetMxuId: number | null;
  newAssetMxuType: number | null;
  newAssetRead: number | null;
  newAssetDemandRead: number | null;
  newAssetRateTable: number | null;
  newAssetActivation: number | null;
  installDate: number | null;
  installTime: number | null;
  installNotes: number | null;
  serviceStatusPostSwap: number | null;
  serviceStatusPreSwap: number | null;
  status: number | null;
  finalReadPicture: number | null;
  completedInstallPicture: number | null;
  workOrderPicture: number | null;
  oldAssetEIDNumber: number | null;
  oldAssetReturnDate: number | null;
  cityServiceLineMaterial: number | null;
  customerServiceLineMaterial: number | null;
}

const getMappings = async (flow?: Flow) => {
  try {
    const { data } = await axiosInstance.get('/mapping', { params: flow ? { flow } : null });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createMapping = async (mappingData: MappingInput) => {
  try {
    const { data } = await axiosInstance.post(`/mapping`, mappingData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useMappings(flow?: Flow, options?: UseQueryOptions<MappingData[], CustomApiError>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<MappingData[], CustomApiError>(['mappings'], () => getMappings(flow), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    ...options,
  });
}

export function useCreateMapping(
  options?: UseMutationOptions<MappingData, CustomApiError, MappingInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation<MappingData, CustomApiError, MappingInput>((data) => createMapping(data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (newMapping) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['mappings', newMapping.id], newMapping);
    },
    ...options,
  });
}
