import { Grid, Typography, Container, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useCompaniesSummary, useSingleUser } from '../../../hooks';
import { UserForm } from './components/UserForm';
import { paths } from '../../../constants/paths';

export const CreateUser = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const { data: companies, isLoading: isCompaniesLoading } = useCompaniesSummary();
  const { data, isInitialLoading } = useSingleUser(Number(userId));

  if (isInitialLoading || isCompaniesLoading) {
    return null;
  }

  if (userId && isNaN(Number(userId))) {
    return <Navigate to={paths.USERS} />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.createUserPage.title')}</Typography>
          </Grid>
        </Grid>
        <UserForm user={data} companies={companies || []} />
      </Paper>
    </Container>
  );
};
