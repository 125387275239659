import { FC } from 'react';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { WorkOrderEditFormProps } from '../interface';
import { CustomAccordion } from '../../../../components';
import { ServiceLineMaterial } from '../../../../constants/enum';
import { WorkOrderStatus } from '../../../../constants/enum';
import { paths } from '../../../../constants/paths';
import { useWorkOrderForm } from '../hooks/useWorkOrderForm';

export const WorkOrderEditForm: FC<WorkOrderEditFormProps> = ({ workOrder, project }) => {
  const { t } = useTranslation();

  const {
    formData,
    isLoading,
    disableSubcontractorChange,
    boxOptions,
    sizeOptions,
    handleChange,
    handleDateChange,
    handleGetlocation,
    handleSubmit,
  } = useWorkOrderForm({
    workOrder,
    project,
  });

  return (
    <Grid item xs={12} display={{ xs: 'none', md: 'block' }}>
      <Grid container>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid item xs={12} mb={4}>
            <CustomAccordion title={t('translation.workOrderEditPage.generalInfo')}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.accountNumber')}
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.accountReadSequence')}
                    name="accountReadSequence"
                    value={formData.accountReadSequence}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.address')}
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.latitude')}
                    name="latitude"
                    type="number"
                    value={formData.latitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.longitude')}
                    name="longitude"
                    type="number"
                    value={formData.longitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.locationCode')}
                    name="locationCode"
                    value={formData.locationCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.locationDescription')}
                    name="locationDescription"
                    value={formData.locationDescription}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.customerName')}
                    name="customerName"
                    value={formData.customerName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label={t('translation.label.cityServiceLineMaterial')}
                    name="cityServiceLineMaterial"
                    value={formData.cityServiceLineMaterial ?? ''}
                    onChange={handleChange}
                  >
                    {Object.entries(ServiceLineMaterial).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {t(`translation.enum.${key}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label={t('translation.label.customerServiceLineMaterial')}
                    name="customerServiceLineMaterial"
                    value={formData.customerServiceLineMaterial ?? ''}
                    onChange={handleChange}
                  >
                    {Object.entries(ServiceLineMaterial).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {t(`translation.enum.${key}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.zoneId')}
                    name="zoneId"
                    value={formData.zoneId}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label={t('translation.label.subcontractor')}
                    name="companyId"
                    value={formData.companyId ?? ''}
                    disabled={disableSubcontractorChange}
                    onChange={handleChange}
                  >
                    {project.subcontractors?.map((subcontractor) => (
                      <MenuItem key={subcontractor.id} value={subcontractor.id}>
                        {subcontractor.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label={t('translation.label.status')}
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    {Object.keys(WorkOrderStatus).map((status) => (
                      <MenuItem key={status} value={status}>
                        {t(`translation.enum.${status}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetNotes')}
                    name="assetNotes"
                    value={formData.assetNotes}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.installNotes')}
                    name="installNotes"
                    value={formData.installNotes}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </CustomAccordion>
          </Grid>

          {/* NEW ASSET DETAILS */}
          <Grid item xs={12} mb={4}>
            <CustomAccordion title={t('translation.workOrderEditPage.newAssetDetails')}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetClassification')}
                    name="newAssetClassification"
                    value={formData.newAssetClassification}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label={t('translation.label.installDate')}
                      value={DateTime.fromISO(formData.installDate)}
                      onChange={(newValue) => handleDateChange('installDate', newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetId')}
                    name="newAssetId"
                    value={formData.newAssetId}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetSerialNumber')}
                    name="newAssetSerialNumber"
                    value={formData.newAssetSerialNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetType')}
                    name="newAssetType"
                    value={formData.newAssetType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetSize')}
                    name="newAssetSize"
                    select
                    value={formData.newAssetSize}
                    onChange={handleChange}
                  >
                    <MenuItem value="">{t(`translation.common.selectOption`)}</MenuItem>
                    {sizeOptions.map((size) => (
                      <MenuItem key={size.value} value={size.value}>
                        {size.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetMake')}
                    name="newAssetMake"
                    value={formData.newAssetMake}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetGroup')}
                    name="newAssetGroup"
                    value={formData.newAssetGroup}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.installedBy')}
                    name="installedBy"
                    value={formData.installedBy}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button variant="contained" onClick={handleGetlocation}>
                    {t('translation.button.getCoordinates')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetLatitude')}
                    name="newAssetLatitude"
                    type="number"
                    value={formData.newAssetLatitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.newAssetLongitude')}
                    name="newAssetLongitude"
                    type="number"
                    value={formData.newAssetLongitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomAccordion
                    title={t('translation.workOrderEditPage.toggleAdditionalInfo')}
                    elevation={0}
                    noPadding={true}
                    defaultExpanded={false}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.newAssetActivation')}
                          name="newAssetActivation"
                          value={formData.newAssetActivation}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.newAssetRead')}
                          name="newAssetRead"
                          value={formData.newAssetRead}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.newAssetDemandRead')}
                          name="newAssetDemandRead"
                          value={formData.newAssetDemandRead}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.newAssetDials')}
                          name="newAssetDials"
                          value={formData.newAssetDials}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.newAssetScale')}
                          name="newAssetScale"
                          value={formData.newAssetScale}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.newAssetRateTable')}
                          name="newAssetRateTable"
                          value={formData.newAssetRateTable}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.boxChangeout')}
                          name="boxChangeout"
                          select
                          value={formData.boxChangeout}
                          onChange={handleChange}
                        >
                          <MenuItem selected={true} value="">
                            {t(`translation.common.selectOption`)}
                          </MenuItem>
                          {boxOptions.map((box) => (
                            <MenuItem key={box.value} value={box.value}>
                              {box.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.curbStop')}
                          name="curbStop"
                          select
                          value={formData.curbStop}
                          onChange={handleChange}
                        >
                          <MenuItem selected={true} value="">
                            {t(`translation.common.selectOption`)}
                          </MenuItem>
                          <MenuItem value="yes">{t(`translation.common.yes`)}</MenuItem>
                          <MenuItem value="no">{t(`translation.common.no`)}</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t('translation.label.assetSpud')}
                          name="assetSpud"
                          value={formData.assetSpud}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </CustomAccordion>
                </Grid>
              </Grid>
            </CustomAccordion>
          </Grid>

          {/* OLD ASSET DETAILS */}
          <Grid item xs={12}>
            <CustomAccordion title={t('translation.workOrderEditPage.oldAssetDetails')}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetId')}
                    name="assetId"
                    value={formData.assetId}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetSerialNumber')}
                    name="assetSerialNumber"
                    value={formData.assetSerialNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetGroup')}
                    name="assetGroup"
                    value={formData.assetGroup}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetMake')}
                    name="assetMake"
                    value={formData.assetMake}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetType')}
                    name="assetType"
                    value={formData.assetType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetSize')}
                    name="assetSize"
                    value={formData.assetSize}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetDials')}
                    name="assetDials"
                    value={formData.assetDials}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetScale')}
                    name="assetScale"
                    value={formData.assetScale}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetRateTable')}
                    name="assetRateTable"
                    value={formData.assetRateTable}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.assetLastRead')}
                    name="assetLastRead"
                    value={formData.assetLastRead}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t('translation.label.finalRead')}
                    name="finalRead"
                    value={formData.finalRead}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label={t('translation.label.assetLastReadDate')}
                      value={DateTime.fromISO(formData.assetLastReadDate)}
                      onChange={(newValue) => handleDateChange('assetLastReadDate', newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label={t('translation.label.assetInstallDate')}
                      value={DateTime.fromISO(formData.assetInstallDate)}
                      onChange={(newValue) => handleDateChange('assetInstallDate', newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </CustomAccordion>
          </Grid>

          <Grid item xs={12} gap={2} sx={{ display: 'flex', justifyContent: 'right', mt: 4 }}>
            <Button
              color="inherit"
              variant="text"
              sx={{ minWidth: 120 }}
              component={Link}
              to={{ pathname: paths.build(paths.WORK_ORDER_DETAILS, project.id, workOrder.id) }}
            >
              {t('translation.button.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ minWidth: 120 }}
              type="submit"
              loading={isLoading}
            >
              {t('translation.button.save')}
            </LoadingButton>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
