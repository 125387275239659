import { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ErrorDetailsColumn: FC<{ data: string }> = ({ data }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ textTransform: 'none', minWidth: 158 }}
        color="inherit"
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
      >
        {t('translation.button.showDetails')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ minWidth: 400 }}>
          <DialogContentText
            id="alert-dialog-description"
            color="error"
            sx={{ wordBreak: 'break-word' }}
          >
            {data}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="text" onClick={handleClose}>
            {t('translation.button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
