import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';
import { WorkOrderStatus } from '../../constants/enum';

interface WorkOrdersPieChartProps {
  pieData: any[];
}

export const WorkOrdersPieChart: FC<WorkOrdersPieChartProps> = ({ pieData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));

  const COLORS: Record<string, string> = {
    [t(`translation.enum.${WorkOrderStatus.ASSIGNED}`).toUpperCase()]: '#7BA0FF',
    [t(`translation.enum.${WorkOrderStatus.COMPLETED}`).toUpperCase()]: theme.palette.success.main,
    [t(`translation.enum.${WorkOrderStatus.RTU}`).toUpperCase()]: theme.palette.error.main,
    [t(`translation.enum.${WorkOrderStatus.UNABLE}`).toUpperCase()]:
      theme.palette.mode === 'dark' ? '#FCFE8A' : '#FBD036',
    [t(`translation.enum.${WorkOrderStatus.UNASSIGNED}`).toUpperCase()]:
      theme.palette.text.disabled,
  };

  const calculateCharContainerWidth = () => {
    if (isXs) return 255;
    if (isLg) return 216;
    return 300;
  };

  return (
    <Box
      sx={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ResponsiveContainer width={calculateCharContainerWidth()} aspect={isLg ? 0.8 : 1}>
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            nameKey="name"
            stroke="none"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
            ))}
          </Pie>
          <Tooltip
            wrapperStyle={{
              fontSize: 14,
              border: 'none',
              outline: 'none',
              borderRadius: 4,
            }}
          />
          <Legend wrapperStyle={{ fontSize: 14, width: '100%' }} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
