import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { CompanyType } from '../constants/enum';
import { paths } from '../constants/paths';
import { GetManyQueryParams, PaginatedApiResponse } from '../interfaces';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { useFilterParams } from './filterParams';

export interface CompanyData {
  id: number;
  companyType: CompanyType;
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  activeProjects: number;
  activeUsers: number;
  deletedAt: Date;
}

export interface CompanySummaryData extends Pick<CompanyData, 'id' | 'name'> {}

export interface CompanyInput
  extends Partial<Omit<CompanyData, 'id' | 'deletedAt' | 'activeProjects' | 'users'>> {}

const getCompanies = async (params?: GetManyQueryParams) => {
  try {
    const { data } = await axiosInstance.get('/company', {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getCompaniesSummary = async (companyType?: CompanyType[]) => {
  try {
    const { data } = await axiosInstance.get('/company/summary', {
      params: companyType ? { companyType } : undefined,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getSingleCompany = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/company/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createCompany = async (companyData: CompanyInput) => {
  try {
    const { data } = await axiosInstance.post(`/company`, companyData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateCompany = async (id: number, companyData: CompanyInput) => {
  try {
    const { data } = await axiosInstance.put(`/company/${id}`, companyData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteCompany = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/company/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useCompanies(
  options?: UseQueryOptions<PaginatedApiResponse<CompanyData>, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useQuery({
    queryKey: ['companies', ...parsedParams],
    queryFn: () => getCompanies(searchParams),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    ...options,
  });
}

export function useCompaniesSummary(
  companyType?: CompanyType[],
  options?: UseQueryOptions<CompanySummaryData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['companies-summary', companyType],
    queryFn: () => getCompaniesSummary(companyType),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    ...options,
  });
}

export function useSingleCompany(
  id: number,
  options?: UseQueryOptions<CompanyData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['companies', id],
    queryFn: () => getSingleCompany(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      navigate(paths.COMPANIES);
    },
    keepPreviousData: true,
    enabled: !!id,
    ...options,
  });
}

export function useCreateCompany(
  options?: UseMutationOptions<CompanyData, CustomApiError, CompanyInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<CompanyData, CustomApiError, CompanyInput>((data) => createCompany(data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (newCompany) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['companies', newCompany.id], newCompany);
      enqueueSnackbar(t('translation.notification.companyCreated'), {
        variant: 'success',
      });
      navigate({ pathname: paths.COMPANIES, search: location.state || '' });
    },
    ...options,
  });
}

export function useUpdateCompany(
  options?: UseMutationOptions<CompanyData, CustomApiError, { id: number; data: CompanyInput }>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<CompanyData, CustomApiError, { id: number; data: CompanyInput }>(
    ({ id, data }) => updateCompany(id, data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newCompany) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['companies', newCompany.id], newCompany);
        enqueueSnackbar(t('translation.notification.companyUpdated'), {
          variant: 'success',
        });
        navigate({ pathname: paths.COMPANIES, search: location.state || '' });
      },
      ...options,
    },
  );
}

export function useDeleteCompany(options?: UseMutationOptions<number, CustomApiError, number>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>((companyId) => deleteCompany(companyId), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (id) => {
      enqueueSnackbar(t('translation.notification.companyDeleted'), {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['companies', id] });
    },
    ...options,
  });
}
