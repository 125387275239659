export const ProjectImportMimeTypes = {
  'text/csv': ['.text', '.csv'],
  'application/vnd.ms-excel': ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xls'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
    '.xlsx',
    '.xlsm',
    '.xlsb',
    '.xltx',
  ],
};

export const ProjectDocumentTypes = {
  'text/csv': ['.text', '.csv'],
  'application/vnd.ms-excel': ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xls'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
    '.xlsx',
    '.xlsm',
    '.xlsb',
    '.xltx',
  ],
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/bmp': ['.bmp'],
  'image/x-png': ['.png'],
  'video/mp4': ['.mp4'],
  'video/3gpp': ['.3gp'],
};
