import { Container, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useCreateProjectImport,
  useSingleProjectImport,
  useUpdateProjectImport,
} from '../../../hooks';
import { ImportFileUploadForm } from '../components/ImportFileUploadForm';
import { Flow } from '../../../constants/enum';
import { useTranslation } from 'react-i18next';

export const UploadImport = () => {
  const { t } = useTranslation();
  const { projectImportId } = useParams();
  const { data, isInitialLoading } = useSingleProjectImport(Number(projectImportId));
  const [newMapping, setNewMapping] = useState<string | null>(null);

  const { mutate: createMutate, isLoading: isCreateLoading } = useCreateProjectImport(newMapping);
  const { mutate: updateMutate, isLoading: isUpdateLoading } = useUpdateProjectImport(newMapping);

  if (isInitialLoading) {
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.uploadNewImportPage.title')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography>{t('translation.uploadNewImportPage.text')}</Typography>
          </Grid>
          <ImportFileUploadForm
            mutate={projectImportId ? updateMutate : createMutate}
            isSubmitting={isCreateLoading || isUpdateLoading}
            projectImport={data}
            setNewMapping={setNewMapping}
            newMapping={newMapping}
            flow={data?.flow || Flow.IMPORT}
          />
        </Grid>
      </Paper>
    </Container>
  );
};
