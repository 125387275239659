import { TabsUnstyled, TabsListUnstyled } from '@mui/base';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { CustomTab } from '../../../components';
import { paths } from '../../../constants/paths';
import { Container, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AssetTabsNav = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <TabsUnstyled value={pathname}>
        <TabsListUnstyled>
          {/* @ts-ignore */}
          <CustomTab component={Link} to={paths.ASSET_MODELS} value={paths.ASSET_MODELS}>
            {t('translation.assetsPage.assets')}
          </CustomTab>
          <CustomTab
            // @ts-ignore
            component={Link}
            to={paths.ASSET_MODEL_COMPONENTS}
            value={paths.ASSET_MODEL_COMPONENTS}
          >
            {t('translation.assetsPage.components')}
          </CustomTab>
        </TabsListUnstyled>

        <Paper sx={{ px: 3, py: 4, borderTopLeftRadius: 0 }}>
          <Outlet />
        </Paper>
      </TabsUnstyled>
    </Container>
  );
};
