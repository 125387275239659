import { useOutletContext } from 'react-router-dom';
import { ProjectData } from '../../../../hooks/projects';
import { ProjectAssetsData, ProjectDetailsData } from '../../../../hooks/projectDetails';
import { TimePeriod } from '../../../../constants/enum';
import { Dispatch, SetStateAction } from 'react';
import { WorkOrderData, WorkOrdersSummaryData } from '../../../../hooks/workOrders';
import { ProjectDocumentData } from '../../../../hooks/projectDocuments';
import { ReplacementGroupData } from '../../../../hooks/replacementGroups';
import { PaginatedApiResponse } from '../../../../interfaces';
import { WorkOrdersMapDataEnhanced } from '../../../../components/WorkOrdersMap/interface';

interface ProjectDetailsContext {
  project: ProjectData;
  projectDetails?: ProjectDetailsData | undefined;
  workOrdersPieData: WorkOrdersSummaryData | undefined;
  isProjectDetailsLoading: boolean;
  zoneId?: string;
  companyId?: number;
  granularity: TimePeriod;
  setGranularity: Dispatch<SetStateAction<TimePeriod | undefined>>;
  projectDocuments: ProjectDocumentData[] | undefined;
  isDocumentsLoading: boolean;
  replacementGroups: ReplacementGroupData[] | undefined;
  isReplacementGroupsLoading: boolean;
  projectAssets: ProjectAssetsData | undefined;
  isAssetsLoading: boolean;
  workOrders: PaginatedApiResponse<WorkOrderData> | undefined;
  isWorkOrdersLoading: boolean;
  isWorkOrdersFetching: boolean;
  workOrderMapData: WorkOrdersMapDataEnhanced[];
}

export const useProjectDetailsContext = () => {
  return useOutletContext<ProjectDetailsContext>();
};
