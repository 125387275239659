import { useTranslation } from 'react-i18next';
import { MUIDataTableColumn } from 'mui-datatables';
import { DateTime } from 'luxon';
import { DataTable } from '../../../components';
import { DATE_FORMAT } from '../../../constants/date';
import { DashboardProjectData } from '../../../hooks/projects';
import { Dispatch, FC, SetStateAction } from 'react';

interface ProjectListTableProps {
  data: DashboardProjectData[];
  isLoading: boolean;
  isFetching: boolean;
  projectFilter: number[];
  setProjectFilter: Dispatch<SetStateAction<number[]>>;
}

export const ProjectListTable: FC<ProjectListTableProps> = ({
  data,
  isFetching,
  isLoading,
  projectFilter,
  setProjectFilter,
}) => {
  const { t } = useTranslation();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      label: t('translation.label.name'),
      name: 'name',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
      },
    },
    {
      label: t('translation.label.startDate'),
      name: 'startDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '100px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
      },
    },
    {
      label: t('translation.label.dueDate'),
      name: 'dueDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '100px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
      },
    },
    {
      label: t('translation.label.completedPercent'),
      name: 'completedPercent',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '100px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          return <span>{value}%</span>;
        },
      },
    },
  ];

  return (
    <DataTable
      title={t('translation.dashboardPage.projectDetails')}
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        serverSide: false,
        filter: false,
        responsive: 'vertical',
        pagination: false,
        tableBodyMaxHeight: '450px',
        onTableChange: undefined,
        sortOrder: {
          name: 'startDate',
          direction: 'desc',
        },
        selectableRows: 'multiple',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        onRowSelectionChange: (_, allRowsSelected) => {
          if (allRowsSelected.length > 0) {
            const ids = allRowsSelected.map((row) => data[row.dataIndex].id);
            setProjectFilter(ids);
          } else {
            setProjectFilter([]);
          }
        },
      }}
    />
  );
};
