import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ExpandMoreSharp } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { DashboardProjectData } from '../../../hooks/projects';

interface StatusFilter {
  assigned: boolean;
  completed: boolean;
  rtu: boolean;
  unable: boolean;
  unassigned: boolean;
}
interface FiltersProps {
  projects: DashboardProjectData[];
  isFetching: boolean;
  statuses: StatusFilter;
  setStatus: Dispatch<SetStateAction<StatusFilter>>;
  projectFilter: number[];
  setProjectFilter: Dispatch<SetStateAction<number[]>>;
  startDateStart: string | null;
  startDateEnd: string | null;
  setStartDateStart: Dispatch<SetStateAction<string | null>>;
  setStartDateEnd: Dispatch<SetStateAction<string | null>>;
}

export const Filters: FC<FiltersProps> = ({
  statuses,
  startDateStart,
  startDateEnd,
  setStatus,
  setProjectFilter,
  setStartDateEnd,
  setStartDateStart,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((exp) => !exp);
  };

  return (
    <Accordion expanded={lessThanMd ? expanded : true}>
      <AccordionSummary
        onClick={lessThanMd ? handleChange : undefined}
        expandIcon={lessThanMd ? <ExpandMoreSharp /> : undefined}
        sx={{
          flexDirection: 'row-reverse',
          height: 68,
          '&.Mui-expanded': {
            minHeight: 68,
          },
        }}
      >
        <Grid item xs={12}>
          <Grid container display="flex" alignItems="center">
            <Grid item xs={6}>
              <Typography fontSize={16} fontWeight="bold" ml={{ xs: 1, md: 0 }}>
                {t('translation.dashboardPage.filters')}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button
                variant="text"
                color="primary"
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  '&:hover': { textDecoration: 'underline' },
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setStatus({
                    assigned: false,
                    completed: false,
                    rtu: false,
                    unable: false,
                    unassigned: false,
                  });
                  setProjectFilter([]);
                  setStartDateStart(null);
                  setStartDateEnd(null);
                }}
              >
                {t('translation.button.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <Divider sx={{ mb: 3 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={16} fontWeight="bold" textTransform="uppercase" gutterBottom>
            {t('translation.label.status')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="small" />}
              label={t('translation.enum.ASSIGNED')}
              checked={statuses.assigned}
              name="assigned"
              onChange={(_, checked) => {
                setStatus((prevState) => ({ ...prevState, assigned: !!checked }));
              }}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label={t('translation.enum.COMPLETED')}
              name="completed"
              checked={statuses.completed}
              onChange={(_, checked) => {
                setStatus((prevState) => ({ ...prevState, completed: !!checked }));
              }}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label={t('translation.enum.RTU')}
              name="rtu"
              checked={statuses.rtu}
              onChange={(_, checked) => {
                setStatus((prevState) => ({ ...prevState, rtu: !!checked }));
              }}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label={t('translation.enum.UNABLE')}
              name="unable"
              checked={statuses.unable}
              onChange={(_, checked) => {
                setStatus((prevState) => ({ ...prevState, unable: !!checked }));
              }}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label={t('translation.enum.UNASSIGNED')}
              name="unassigned"
              checked={statuses.unassigned}
              onChange={(_, checked) => {
                setStatus((prevState) => ({ ...prevState, unassigned: !!checked }));
              }}
            />
          </FormGroup>
          <Divider sx={{ my: 3 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={16} fontWeight="bold" textTransform="uppercase" marginBottom={1}>
            {t('translation.label.startDate')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={12}
                  xl={6}
                  paddingRight={{ sm: 1, md: 0, xl: 1 }}
                  paddingBottom={{ xs: 2, sm: 0, md: 2, xl: 0 }}
                >
                  <DatePicker
                    sx={{ width: '100%' }}
                    label={t('translation.label.from')}
                    value={startDateStart || null}
                    // @ts-ignore
                    minDate={DateTime.fromISO('2000-01-01')}
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = DateTime.fromISO(newValue);
                        if (date.isValid && date > DateTime.fromISO('2000-01-01')) {
                          setStartDateStart(newValue);
                        }
                      } else {
                        setStartDateStart(null);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={12} xl={6} paddingLeft={{ sm: 1, md: 0, xl: 1 }}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label={t('translation.label.to')}
                    value={startDateEnd || null}
                    // @ts-ignore
                    minDate={DateTime.fromISO('2000-01-01')}
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = DateTime.fromISO(newValue);
                        if (date.isValid && date > DateTime.fromISO('2000-01-01')) {
                          setStartDateEnd(newValue);
                        }
                      } else {
                        setStartDateEnd(null);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
