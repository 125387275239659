import axios from 'axios';
import { getIdToken } from '../utils/auth';

const config = {
  baseURL: process.env.REACT_APP_BACKEND_DOMAIN,
  mode: 'cors',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  async (config) => {
    const idToken = await getIdToken();
    if (idToken) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default axiosInstance;
