import { Typography, CircularProgress, Box } from '@mui/material';
import React from 'react';

interface CustomHeaderProps {
  title: string;
  isLoading: boolean;
}

export const CustomHeader: React.FunctionComponent<CustomHeaderProps> = ({ title, isLoading }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative' }} />}
    </Box>
  );
};
