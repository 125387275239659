import { FC, ReactNode } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface ProjectDetailsActionModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  title: string;
  children: ReactNode;
  isSubmitting: boolean;
}

export const ProjectDetailsActionModal: FC<ProjectDetailsActionModalProps> = ({
  open,
  handleClose,
  handleSubmit,
  title,
  isSubmitting,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { width: 550, maxWidth: '100%' },
      }}
    >
      <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ minWidth: 120 }} color="inherit" variant="text" onClick={handleClose}>
          {t('translation.button.cancel')}
        </Button>
        <LoadingButton
          sx={{ minWidth: 120 }}
          loading={isSubmitting}
          variant="contained"
          onClick={handleSubmit}
        >
          {t('translation.button.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
