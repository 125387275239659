export const paths = {
  SIGN_IN: '/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  SETTINGS: '/settings',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  PROJECT_DETAILS: '/projects/:projectId',
  PROJECT_WORK_ORDERS: '/projects/:projectId/work-orders',
  PROJECT_REPLACEMENT_GROUPS: '/projects/:projectId/replacement-groups',
  WORK_ORDER_DETAILS: '/projects/:projectId/work-orders/:workOrderId',
  WORK_ORDER_EDIT: '/projects/:projectId/work-orders/:workOrderId/edit',
  WORK_ORDER_CREATE: '/projects/:projectId/work-orders/new',
  PROJECT_RECOVERY: '/project-recovery',
  PROJECT_IMPORTS: '/project-imports',
  PROJECT_IMPORT_CREATE: '/project-imports/new',
  PROJECT_IMPORT_MAPPING: '/project-imports/:projectImportId/mapping',
  PROJECT_IMPORT_MAPPING_NEW: '/project-imports/:projectImportId/mapping/:mappingName',
  PROJECT_IMPORT_CONTINUE: '/project-imports/:projectImportId',
  PROJECT_IMPORT_ERRORS: '/project-imports/:projectImportId/errors',
  PROJECT_CREATE: '/project-create/:projectImportId',
  PROJECT_FINALIZE: '/project-create/:projectImportId/finalize',
  PROJECT_UPDATE: '/project-update/:projectId',
  USERS: '/users',
  USER_CREATE: '/users/new',
  USER_EDIT: '/users/:userId',
  COMPANIES: '/companies',
  COMPANY_CREATE: '/companies/new',
  COMPANY_EDIT: '/companies/:companyId',
  ASSET_MODELS: '/assets',
  ASSET_MODEL_CREATE: '/assets/new',
  ASSET_MODEL_EDIT: '/assets/:assetModelId',
  ASSET_MODEL_COMPONENTS: '/assets/components',

  build: (path: string, ...params: any[]): string => {
    params.reverse();
    return path.replace(/(:\w+)/g, () => params.pop());
  },
};
