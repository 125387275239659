import { FC, FormEventHandler, SyntheticEvent, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Button, Autocomplete, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AxiosProgressEvent } from 'axios';
import { FileDropzone } from '../../../components/FileDropzone';
import { ProjectImportMimeTypes } from '../../../constants/fileTypes';
import { paths } from '../../../constants/paths';
import { useMappings } from '../../../hooks';
import { MappingData } from '../../../hooks/mappings';
import { ProjectImportData } from '../../../hooks/projectImports';
import { Flow } from '../../../constants/enum';

interface ImportFileUploadFormProps {
  isSubmitting: boolean;
  projectImport?: ProjectImportData;
  mutate: (_: any, __?: any) => void;
  newMapping: string | null;
  setNewMapping: (value: string | null) => void;
  flow: Flow;
  projectId?: number;
}

interface UploadFormData {
  csvFile: File | undefined;
  mapping: string | MappingData | null;
}

export const ImportFileUploadForm: FC<ImportFileUploadFormProps> = ({
  isSubmitting,
  projectImport,
  mutate,
  setNewMapping,
  newMapping,
  flow,
  projectId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading } = useMappings(flow);

  const [submitted, setSubmitted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadFormData, setUploadFormData] = useState<UploadFormData>({
    csvFile: undefined,
    mapping: projectImport?.mapping
      ? { id: projectImport?.mapping.id, name: projectImport?.mapping.name }
      : null,
  });

  const progressCallback = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = Math.round(percentComplete * 100);
      setProgress(percentComplete);
    }
  };

  // Create new project import and upload file OR update mapping on existing import
  // If mapping is the same skip updating
  // If new mapping name is entered, skip updating a navigate to new mapping route
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (projectImport) {
      if (uploadFormData.mapping && !isSubmitting) {
        if (typeof uploadFormData.mapping === 'string') {
          navigate({
            pathname: paths.build(paths.PROJECT_IMPORT_MAPPING, projectImport.id),
            search: newMapping ? `mappingName=${encodeURIComponent(newMapping)}` : undefined,
          });
        } else {
          if (uploadFormData.mapping.id === projectImport.mapping?.id) {
            navigate({
              pathname: paths.build(paths.PROJECT_IMPORT_MAPPING, projectImport.id),
            });
          } else {
            mutate({
              id: projectImport.id,
              data: { mappingId: uploadFormData.mapping.id },
            });
          }
        }
      }
    } else {
      if (uploadFormData.csvFile && uploadFormData.mapping && !isSubmitting) {
        mutate({
          file: uploadFormData.csvFile,
          mappingId: typeof uploadFormData.mapping === 'string' ? null : uploadFormData.mapping.id,
          projectId,
          progressCallback,
        });
      }
    }
  };

  const handleMappingChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | MappingData | null,
  ) => {
    if (!isLoading) {
      setSubmitted(false);
      setNewMapping(null);
      setUploadFormData((prevState) => ({
        ...prevState,
        mapping: value,
      }));
    }
  };

  const handleNewMappingChange = (_: SyntheticEvent<Element, Event>, value: string | null) => {
    if (!isLoading) {
      setSubmitted(false);
      const existingMapping = data?.find((mapping) => mapping.name === value);
      if (!existingMapping) {
        setNewMapping(value);
      }
      setUploadFormData((prevState) => ({
        ...prevState,
        mapping: existingMapping ?? value,
      }));
    }
  };

  const handleFileChange = (file: File) => {
    setSubmitted(false);
    setUploadFormData((prevState) => ({
      ...prevState,
      csvFile: file,
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Grid item xs={12} sx={{ mb: 6, px: { xs: 1, md: 3 } }}>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} sm={10}>
            <FileDropzone
              title={t('translation.uploadNewImportPage.dropFile')}
              files={uploadFormData.csvFile}
              disabled={!!projectImport}
              disabledFileName={projectImport?.filename}
              setFiles={handleFileChange}
              fileTypes={ProjectImportMimeTypes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
        <Autocomplete
          id="mapping"
          loading={isLoading}
          freeSolo
          loadingText={t('translation.common.loading')}
          options={data ?? []}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          value={uploadFormData.mapping}
          onChange={handleMappingChange}
          onInputChange={handleNewMappingChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('translation.label.mapping')}
              placeholder={t('translation.label.mapping')}
              error={submitted && !uploadFormData.mapping}
              helperText={t('translation.uploadNewImportPage.mappingHelperText')}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
      >
        <Button
          color="inherit"
          variant="text"
          sx={{ minWidth: 120, mr: 2 }}
          component={Link}
          // Navigate to projects list only if cancelling new project update
          to={{
            pathname: !projectId ? paths.PROJECT_IMPORTS : paths.PROJECTS,
            search: location.state || '',
          }}
        >
          {t('translation.button.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ minWidth: 120 }}
          type="submit"
          loading={isSubmitting}
          loadingPosition={projectImport ? undefined : 'start'}
        >
          {isSubmitting && !projectImport ? `${progress}%` : t('translation.button.continue')}
        </LoadingButton>
      </Grid>
    </Box>
  );
};
