import { createContext, useContext } from 'react';
import { UserData } from '../../hooks/users';

export const UserDataContext = createContext<UserData | undefined>(undefined);

// call the custom hook with the context and returns the context
export const useUserDataContext = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserDataContext must be used within the Layout container');
  }

  return context;
};
