import { FormEventHandler, useState } from 'react';
import { Alert, Box, TextField, Typography } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { AuthBoxLayout } from '../../../containers';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const { error, submitForm, isPending } = useAuthenticator();

  const [submit, setSubmit] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSubmit(true);
    if (password && confirmPassword && password === confirmPassword && !isPending) {
      submitForm({ password, confirm_password: confirmPassword });
    }
  };

  return (
    <AuthBoxLayout
      cardActions={
        <LoadingButton
          form="changePassword"
          loading={isPending}
          sx={{ minWidth: 120 }}
          variant="contained"
          type="submit"
        >
          <Trans i18nKey="translation.changePasswordPage.changePassword" />
        </LoadingButton>
      }
    >
      <Typography sx={{ my: 3, fontWeight: 'bold' }} variant="h5" component="div">
        <Trans i18nKey="translation.changePasswordPage.title" />
      </Typography>
      {error && (
        <Alert sx={{ mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Box>
        <form id="changePassword" onSubmit={handleSubmit}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="new-password"
            autoComplete="new-password"
            label={t('translation.label.newPassword')}
            name="new-password"
            type="password"
            error={submit && !password}
            onChange={(event) => {
              setSubmit(false);
              setPassword(event.target.value);
            }}
          />
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="confirm-password"
            autoComplete="new-password"
            label={t('translation.label.confirmPassword')}
            name="confirm-password"
            type="password"
            error={submit && !confirmPassword}
            onChange={(event) => {
              setSubmit(false);
              setConfirmPassword(event.target.value);
            }}
          />
        </form>
      </Box>
    </AuthBoxLayout>
  );
};
