import { FC, FormEventHandler, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  TextField,
  Button,
  DialogActions,
  Autocomplete,
  Divider,
} from '@mui/material';
import { t } from 'i18next';
import { AssetModelData } from '../../hooks/assetModels';

interface AssetModelModalProps {
  open: boolean;
  toggleModal: () => void;
  assetModels: AssetModelData[];
  onSubmit: (assetModel: AssetModelData) => void;
}

export const AssetModelModal: FC<AssetModelModalProps> = ({
  open,
  toggleModal,
  assetModels,
  onSubmit,
}) => {
  const [assetModel, setAssetModel] = useState<AssetModelData | null>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (assetModel) {
      onSubmit(assetModel);
    }
    setAssetModel(null);
    toggleModal();
  };

  return (
    <Dialog
      keepMounted={false}
      open={open}
      onClose={() => {
        setAssetModel(null);
        toggleModal();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { width: 700, maxWidth: '100%' },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {t('translation.createProjectPage.assetModelModal.title')}
      </DialogTitle>
      <DialogContent>
        <Box component="form" id="assetModel" onSubmit={handleSubmit}>
          <Grid container my={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                sx={{ my: 1 }}
                id="asset"
                options={assetModels}
                getOptionLabel={(option) => option.name}
                value={assetModel}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={t('translation.label.asset')} />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                onChange={(_, value) => {
                  setAssetModel(value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container my={2} spacing={2} justifyContent="center">
            <Grid item xs={12} sm={11} textAlign="center">
              <Divider sx={{ '.MuiDivider-wrapper': { px: 4 } }}>
                {t('translation.createProjectPage.assetModelModal.text')}
              </Divider>
            </Grid>
          </Grid>
          <Grid container my={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled
                value={assetModel?.manufacturer?.name || ''}
                label={t('translation.label.manufacturer')}
                name="manufacturer"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled
                value={assetModel?.classification?.name || ''}
                label={t('translation.label.classification')}
                name="classification"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled
                value={assetModel?.size?.name || ''}
                label={t('translation.label.size')}
                name="size"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled
                value={assetModel?.partNumber || ''}
                label={t('translation.label.partNumber')}
                name="partNumber"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="inherit"
          variant="text"
          onClick={() => {
            setAssetModel(null);
            toggleModal();
          }}
        >
          {t('translation.button.cancel')}
        </Button>
        <Button type="submit" form="assetModel" variant="contained" disabled={!assetModel}>
          {t('translation.button.addAsset')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
