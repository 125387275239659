export enum Language {
  en = 'en',
  es = 'es',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  END_USER = 'END_USER',
}

export enum CompanyType {
  ADMIN = 'ADMIN',
  UTILITY = 'UTILITY',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  MANUFACTURER = 'MANUFACTURER',
  OTHER = 'OTHER',
}

export enum ProjectStatus {
  CREATED = 'CREATED',
  INITIAL_PARSE_IN_PROGRESS = 'IN_PROGRESS',
  PARSE_SUCCESS = 'PARSE_SUCCESS',
  COMPLETE = 'COMPLETE',
}

export enum ProjectProgress {
  ON_SCHEDULE = 'ON_SCHEDULE',
  BEHIND_SCHEDULE = 'BEHIND_SCHEDULE',
  COMPLETED = 'COMPLETED',
  READY_FOR_COMPLETE = 'READY_FOR_COMPLETE',
}

export enum Flow {
  IMPORT = 'IMPORT',
  UPDATE = 'UPDATE',
}

export enum ProjectImportStatus {
  CREATED = 'CREATED',
  UPLOADED = 'UPLOADED',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  PARSE_SUCCESS = 'PARSE_SUCCESS',
  COMPLETE = 'COMPLETE',
}

export enum ProjectImportErrorType {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  WORK_ORDER_NOT_UNIQUELY_IDENTIFIED = 'WORK_ORDER_NOT_UNIQUELY_IDENTIFIED',
  WORK_ORDER_DOES_NOT_EXIST = 'WORK_ORDER_DOES_NOT_EXIST',
  INVALID_STATUS = 'INVALID_STATUS',
  FILE_PARSE_ERROR = 'FILE_PARSE_ERROR',
}

export enum WorkOrderStatus {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  UNABLE = 'UNABLE',
  RTU = 'RTU',
  PENDING_RETURN = 'PENDING_RETURN',
  RETURNED = 'RETURNED',
}

export enum FileType {
  IMAGE = 'PHOTO',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
}

export enum ServiceLineMaterial {
  PVC = 'PVC',
  POLY = 'Poly',
  COPPER = 'Copper',
  GALV_AND_COPPER = 'Galv & Copper',
  GALV = 'Galv',
}

export enum TimePeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
}
