import { FC, useRef, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownMenuProps } from '../interface';

export const DropdownMenu: FC<DropdownMenuProps> = ({ item }) => {
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        component={item.children ? Button : NavLink}
        to={item.to}
        key={item.name}
        ref={anchorRef}
        aria-controls={item.id}
        onClick={handleMenuOpen}
        disableRipple
        sx={{
          textTransform: 'none',
          fontSize: { sm: 14, md: 16 },
          fontWeight: 500,
          px: 2,
          borderRadius: 0,
        }}
        color="inherit"
      >
        {t(item.name)}
      </Button>
      {item.children && (
        <Menu
          sx={{ marginTop: 1, minWidth: 200 }}
          anchorEl={anchorRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          id={item.id}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {item.children.map((item) => (
            <MenuItem
              key={item.name}
              sx={{ minWidth: 200, height: 50 }}
              component={NavLink}
              to={item.to}
              onClick={handleMenuClose}
            >
              {t(item.name)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
