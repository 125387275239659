import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { CompanyType, UserRole } from '../constants/enum';
import { useFilterParams } from './filterParams';
import { GetManyQueryParams, PaginatedApiResponse } from '../interfaces';
import { paths } from '../constants/paths';

export interface UserData {
  id: number;
  role: UserRole;
  company: {
    id: number;
    companyType: CompanyType;
    name: string;
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    deletedAt: Date;
  };
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  hasLoginAccess: boolean;
  deletedAt: Date;
}

export interface UserInput extends Partial<UserData> {
  companyId?: number;
}

const getUsers = async (params?: GetManyQueryParams) => {
  try {
    const { data } = await axiosInstance.get('/user', {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getSingleUser = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/user/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createUser = async (userData: UserInput) => {
  try {
    const { data } = await axiosInstance.post(`/user`, userData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateUser = async (id: number, userData: UserInput) => {
  try {
    const { data } = await axiosInstance.put(`/user/${id}`, userData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteUser = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/user/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useUsers(
  options?: UseQueryOptions<PaginatedApiResponse<UserData>, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useQuery({
    queryKey: ['users', ...parsedParams],
    queryFn: () => getUsers(searchParams),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useSingleUser(id: number, options?: UseQueryOptions<UserData, CustomApiError>) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['users', id],
    queryFn: () => getSingleUser(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      navigate(paths.USERS);
    },
    ...options,
    enabled: !!id,
  });
}

export function useCreateUser(options?: UseMutationOptions<UserData, CustomApiError, UserInput>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<UserData, CustomApiError, UserInput>((data) => createUser(data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (newUser) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['users', newUser.id], newUser);
      enqueueSnackbar(t('translation.notification.userCreated'), {
        variant: 'success',
      });
      navigate({ pathname: paths.USERS, search: location.state || '' });
    },
    ...options,
  });
}

export function useUpdateUser(
  options?: UseMutationOptions<UserData, CustomApiError, { id: number; data: UserInput }>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<UserData, CustomApiError, { id: number; data: UserInput }>(
    ({ id, data }) => updateUser(id, data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newUser) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['users', newUser.id], newUser);
        enqueueSnackbar(t('translation.notification.userUpdated'), {
          variant: 'success',
        });
        navigate({ pathname: paths.USERS, search: location.state || '' });
      },
      ...options,
    },
  );
}

export function useDeleteUser(options?: UseMutationOptions<number, CustomApiError, number>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>((userId) => deleteUser(userId), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar(t('translation.notification.userDeleted'), {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    ...options,
  });
}
