import { Box, Button, Grid, MenuItem, Paper, TextField, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LoadingButton } from '@mui/lab';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useProjectWorkOrders } from './hooks/useProjectWorkOrders';
import { ConfirmationModal, DataTable, WorkOrdersMap } from '../../../components';
import { paths } from '../../../constants/paths';
import { WorkOrderStatus } from '../../../constants/enum';
import { useFilterParams } from '../../../hooks/filterParams';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../constants/date';
import { WorkOrdersActionsColumn } from './components/ActionsColumn';
import { allAdminAndEmployeeRoles } from '../../../constants/permissionGroups';
import { PermissionWrapper } from '../../../containers';
import { isAdminRole } from '../../../utils/permissions';
import { isEmployeeRole } from '../../../utils/permissions';
import { useUserDataContext } from '../../../containers/Layout/context';
import { ProjectDetailsActionModal } from './components/ProjectDetailsActionModal';

export const ProjectWorkOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const {
    projectId,
    workOrders,
    isWorkOrdersLoading,
    isWorkOrdersFetching,
    isDeleting,
    deleteWorkOrders,
    deleteOpen,
    handleDeleteOpen,
    handleDeleteClose,
    handleUpdateOpen,
    handleUpdateClose,
    updateOpen,
    status,
    handleStatusChange,
    isUpdating,
    handleStatusChangeSubmit,
    workOrderMapData,
  } = useProjectWorkOrders();
  const user = useUserDataContext();

  const [{ order, where }] = useFilterParams();

  const workOrderColumns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.accountNumber'),
      name: 'accountNumber',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px', cursor: 'pointer' } }),
        filterList: where?.accountNumber ? [where.accountNumber] : undefined,
      },
    },
    {
      label: t('translation.label.accountReadSequence'),
      name: 'accountReadSequence',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px', cursor: 'pointer' } }),
        filterList: where?.accountReadSequence ? [where.accountReadSequence] : undefined,
      },
    },
    {
      label: t('translation.label.address'),
      name: 'address',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        filterList: where?.address ? [where.address] : undefined,
      },
    },
    {
      label: t('translation.label.subcontractor'),
      name: 'company.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        filter: false,
      },
    },
    {
      label: t('translation.label.zoneId'),
      name: 'zoneId',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px', cursor: 'pointer' } }),
        filter: false,
      },
    },
    {
      label: t('translation.label.status'),
      name: 'status',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        filterList: where?.status ? [where.status] : undefined,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                variant="standard"
                select
                label={t('translation.label.status')}
                name="status"
                value={filterList[index][0] ?? ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                {Object.keys(WorkOrderStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(`translation.enum.${status}`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
        customBodyRender: (value) => {
          switch (value) {
            case WorkOrderStatus.COMPLETED:
              return (
                <Box component="span" sx={{ color: 'success.main' }}>
                  {t(`translation.enum.COMPLETED`)}
                </Box>
              );
            case WorkOrderStatus.RTU:
              return (
                <Box component="span" sx={{ color: 'error.main' }}>
                  {t('translation.enum.RTU')}
                </Box>
              );
            case WorkOrderStatus.UNABLE:
              return (
                <Box component="span" sx={{ color: 'warning.main' }}>
                  {t('translation.enum.UNABLE')}
                </Box>
              );
            default:
              return <span>{t(`translation.enum.${value}`)}</span>;
          }
        },
      },
    },
    {
      label: t('translation.label.updatedDate'),
      name: 'updatedDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '150px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_TIME_FORMAT);
          return <span>{dateTime}</span>;
        },
        filterList: where?.updatedDateRange
          ? Array.isArray(where?.updatedDateRange)
            ? where?.updatedDateRange
            : [where?.updatedDateRange]
          : undefined,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0] && value[1]) {
              return [
                `${t('translation.label.minWorkOrderUpdatedDate')}: ${DateTime.fromISO(
                  value[0],
                ).toFormat(DATE_FORMAT)}`,
                `${t('translation.label.maxWorkOrderUpdatedDate')}: ${DateTime.fromISO(
                  value[1],
                ).toFormat(DATE_FORMAT)}`,
              ];
            } else if (value[0]) {
              return `${t('translation.label.minWorkOrderUpdatedDate')}: ${DateTime.fromISO(
                value[0],
              ).toFormat(DATE_FORMAT)}`;
            } else if (value[1]) {
              return `${t('translation.label.maxWorkOrderUpdatedDate')}: ${DateTime.fromISO(
                value[1],
              ).toFormat(DATE_FORMAT)}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            return (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
                  <Grid container>
                    <Grid item xs={6} paddingRight={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.minWorkOrderUpdatedDate')}
                        value={DateTime.fromISO(filterList[index][0]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][0] = newValue.toISO();
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} paddingLeft={2}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('translation.label.maxWorkOrderUpdatedDate')}
                        value={DateTime.fromISO(filterList[index][1]) || null}
                        minDate={DateTime.fromISO('2000-01-01')}
                        onChange={(newValue) => {
                          if (newValue) {
                            if (newValue.isValid && newValue > DateTime.fromISO('2000-01-01')) {
                              filterList[index][1] = newValue.toISO();
                              if (!filterList[index][0]) {
                                filterList[index][0] = DateTime.fromISO('2000-01-01').toISO();
                              }
                              onChange(filterList[index], index, column);
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </DemoContainer>
              </LocalizationProvider>
            );
          },
        },
      },
    },
  ];

  if (isAdminRole(user.role) || isEmployeeRole(user.role)) {
    workOrderColumns.push({
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        customBodyRenderLite: (dataIndex) => {
          if (workOrders?.data[dataIndex].id) {
            const companyId = workOrders.data[dataIndex].company?.id;
            const zoneId = workOrders.data[dataIndex].zoneId;
            return (
              <WorkOrdersActionsColumn
                workOrderId={workOrders?.data[dataIndex].id}
                companyId={companyId}
                user={user}
                projectId={projectId}
                zoneId={zoneId}
              />
            );
          }
          return null;
        },
      },
    });
  }

  const buildFilters = (filterList: Array<Array<string>>) => {
    if (filterList.length > 0) {
      const filters: Record<string, any> = {};
      filterList.forEach((filter, index) => {
        if (filter[0]) {
          const colName = workOrderColumns[index].name;
          if (colName === 'company.name') {
            const key = 'where[companyName]';
            filters[key] = filter[0];
          } else if (colName === 'status') {
            const key = 'where[statuses]';
            filters[key] = filter[0];
          } else if (colName === 'updatedDate') {
            const key = `where[${colName}Range]`;
            filters[key] = [];
            if (DateTime.fromISO(filter[0]).isValid) {
              filters[key].push(DateTime.fromISO(filter[0]).toFormat('y-LL-dd'));
            }
            if (DateTime.fromISO(filter[1]).isValid) {
              filters[key].push(DateTime.fromISO(filter[1]).toFormat('y-LL-dd'));
            }
          } else {
            const key = `where[${colName}]`;
            filters[key] = filter[0];
          }
        }
      });
      if (Object.keys(filters).length < 1) {
        return null;
      }
      return filters;
    }
    return null;
  };

  const buildSortOrder = (property: string, direction: string) => {
    if (property && direction) {
      const colName = property === 'company.name' ? 'companyName' : property;
      const key = `order[${colName}]`;
      return { [key]: direction };
    }
    return null;
  };

  return (
    <Grid container>
      <PermissionWrapper whitelist={allAdminAndEmployeeRoles}>
        <Grid item xs={12} sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
          <Button
            sx={{ minWidth: 120 }}
            variant="contained"
            component={Link}
            state={location.search || null}
            to={paths.build(paths.WORK_ORDER_CREATE, projectId)}
          >
            {t('translation.button.add')} +
          </Button>
        </Grid>
      </PermissionWrapper>
      <Grid item xs={12}>
        <DataTable
          data={workOrders?.data}
          columns={workOrderColumns}
          count={workOrders?.meta.count}
          isLoading={isWorkOrdersLoading}
          isFetching={isWorkOrdersFetching}
          customBuildFilters={buildFilters}
          customBuildSortOrder={buildSortOrder}
          title="translation.projectDetailsPage.workOrders"
          rowsPerPage={50}
          options={{
            selectableRows: 'multiple',
            onRowsDelete: () => false,
            customToolbarSelect: (selectedRows, _, setSelectedRows) => (
              <Box sx={{ pr: 3, display: 'flex', gap: 4 }}>
                <Button
                  sx={{ minWidth: 120, mx: { xs: 1, md: 0 } }}
                  color="error"
                  onClick={handleDeleteOpen}
                >
                  {t('translation.button.delete')}
                </Button>
                <Button
                  color="inherit"
                  sx={{ minWidth: 120, mx: { xs: 1, md: 0 } }}
                  onClick={handleUpdateOpen}
                >
                  {t('translation.button.changeStatus')}
                </Button>
                <ConfirmationModal
                  open={deleteOpen}
                  handleClose={handleDeleteClose}
                  title="translation.projectDetailsPage.batchDeleteWorkOrdersModal.title"
                  text="translation.projectDetailsPage.batchDeleteWorkOrdersModal.text"
                  confirmActionButton={
                    <LoadingButton
                      loading={isDeleting}
                      color="error"
                      variant="text"
                      onClick={async () => {
                        if (!isDeleting) {
                          const workOrderIds = selectedRows.data
                            .map(({ dataIndex }) => workOrders?.data[dataIndex].id)
                            .filter(Boolean);
                          await deleteWorkOrders(workOrderIds as number[]);
                          setSelectedRows([]);
                          handleDeleteClose();
                        }
                      }}
                    >
                      {t('translation.button.delete')}
                    </LoadingButton>
                  }
                />
                <ProjectDetailsActionModal
                  open={updateOpen}
                  handleClose={handleUpdateClose}
                  handleSubmit={async () => {
                    const workOrderIds = selectedRows.data
                      .map(({ dataIndex }) => workOrders?.data[dataIndex].id)
                      .filter(Boolean);
                    await handleStatusChangeSubmit(workOrderIds as number[]);
                    setSelectedRows([]);
                    handleUpdateClose();
                  }}
                  title={t('translation.projectDetailsPage.updateClientIdModal.title')}
                  isSubmitting={isUpdating}
                >
                  <Grid container pt={2}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        sx={{ my: 1 }}
                        fullWidth
                        select
                        label={t('translation.label.status')}
                        value={status ?? ''}
                        onChange={handleStatusChange}
                        name="status"
                      >
                        {Object.values(WorkOrderStatus).map((status) => (
                          <MenuItem key={status} value={status}>
                            {t(`translation.enum.${status}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </ProjectDetailsActionModal>
              </Box>
            ),
            onRowClick: (_, { dataIndex }) => {
              const projectId = workOrders?.data[dataIndex].projectId;
              const workOrderId = workOrders?.data[dataIndex].id;
              if (workOrderId && projectId) {
                navigate(paths.build(paths.WORK_ORDER_DETAILS, projectId, workOrderId), {
                  state: location.search || null,
                });
              }
            },
            onCellClick: (_, { colIndex, event }) => {
              if (colIndex > 6) {
                event.stopPropagation();
              }
            },
            sortOrder: order
              ? Object.entries(order).map(([key, value]) => ({
                  name: key === 'companyName' ? 'company.name' : key,
                  direction: value.toLowerCase(),
                }))[0]
              : undefined,
          }}
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <Paper
          sx={{
            background: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
            p: 2,
            mt: 3,
          }}
        >
          <Typography fontSize={20} variant="h6" mb={2}>
            {t('translation.projectDetailsPage.mapView')}
          </Typography>
          <Box height={{ xs: 300, md: 400 }}>
            <WorkOrdersMap workOrders={workOrderMapData} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
