import { TabsUnstyled, TabsListUnstyled } from '@mui/base';
import { Link as RouterLink, Outlet, Navigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ModeEditOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DateTime } from 'luxon';
import { ProjectProgress, ProjectStatus } from '../../../constants/enum';
import { ConfirmationModal, CustomTab, TableActionsMenu } from '../../../components';
import { paths } from '../../../constants/paths';
import { DATE_FORMAT } from '../../../constants/date';
import { useProjectDetailsData } from './hooks/useProjectDetails';
import { ProjectDetailsActionModal } from './components/ProjectDetailsActionModal';
import {
  allAdminRoles,
  allAdminRolesAndAdminTypeEmployee,
} from '../../../constants/permissionGroups';
import { PermissionWrapper } from '../../../containers';

export const ProjectDetails = () => {
  const { t } = useTranslation();
  const {
    pathname,
    projectId,
    location,
    project,
    projectDetails,
    workOrdersPieData,
    zoneId,
    setZoneId,
    companyId,
    setCompanyId,
    granularity,
    setGranularity,
    projectDocuments,
    isDocumentsLoading,
    isLoading,
    isFetching,
    deleteProject,
    isDeleteLoading,
    archiveProject,
    isArchiveLoading,
    completeProject,
    isCompleteLoading,
    generateReport,
    isGeneratingReport,
    replacementGroups,
    isReplacementGroupsLoading,
    projectAssets,
    isAssetsLoading,
    workOrders,
    isWorkOrdersLoading,
    isWorkOrdersFetching,
    openDeleteModal,
    openClientIdModal,
    clientId,
    setClientId,
    handleDeleteModalOpen,
    handleDeleteModalClose,
    handleClientIdModalOpen,
    handleClientIdModalClose,
    handleClientIdSubmit,
    workOrderMapData,
  } = useProjectDetailsData();

  if (isLoading || isFetching) {
    return null;
  }

  if ((projectId && isNaN(Number(projectId))) || project?.status !== ProjectStatus.COMPLETE) {
    return <Navigate to={{ pathname: paths.PROJECTS, search: location.state || '' }} />;
  }

  const renderProgressStatus = (status: ProjectProgress) => {
    switch (status) {
      case ProjectProgress.ON_SCHEDULE:
        return (
          <Typography component="span" sx={{ color: 'success.main' }}>
            {t('translation.enum.ON_SCHEDULE')}
          </Typography>
        );
      case ProjectProgress.BEHIND_SCHEDULE:
        return (
          <Typography component="span" sx={{ color: 'error.main' }}>
            {t('translation.enum.BEHIND_SCHEDULE')}
          </Typography>
        );
      default:
        return <Typography component="span">{t(`translation.enum.${status}`)}</Typography>;
    }
  };

  return (
    <>
      <Paper sx={{ px: { xs: 3, md: 6 }, py: 2, borderRadius: 0 }}>
        <Grid container>
          <Grid item xs={6} sm={4} display="flex" alignItems="center">
            <Link
              component={RouterLink}
              to={{ pathname: paths.PROJECTS, search: location.state || '' }}
              color="inherit"
              sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              <ArrowBack sx={{ mr: 1 }} />
              {t('translation.common.goBack')}
            </Link>
          </Grid>
          <Grid item xs={6} sm={8} textAlign="right">
            <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
              <PermissionWrapper whitelist={allAdminRoles} ownerId={project.company.id}>
                <Button color="error" variant="text" onClick={handleDeleteModalOpen} sx={{ mr: 3 }}>
                  {t('translation.button.delete')}
                </Button>
                <LoadingButton
                  loading={isArchiveLoading}
                  disabled={project.archived}
                  color="inherit"
                  variant="text"
                  sx={{ mr: 3 }}
                  onClick={() => {
                    if (!isArchiveLoading) {
                      archiveProject(Number(projectId));
                    }
                  }}
                >
                  {t('translation.button.archive')}
                </LoadingButton>
              </PermissionWrapper>
              <LoadingButton
                color="inherit"
                variant="outlined"
                sx={{ mr: 3 }}
                loading={isGeneratingReport}
                onClick={() => {
                  if (!isGeneratingReport) {
                    generateReport(Number(projectId));
                  }
                }}
              >
                {t('translation.button.generateReport')}
              </LoadingButton>
              <PermissionWrapper whitelist={allAdminRoles} ownerId={project.company.id}>
                <LoadingButton
                  loading={isCompleteLoading}
                  disabled={project.markedComplete}
                  variant="contained"
                  onClick={() => {
                    if (!isCompleteLoading) {
                      completeProject(Number(projectId));
                    }
                  }}
                >
                  {t('translation.button.complete')}
                </LoadingButton>
              </PermissionWrapper>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none', textAlign: 'right' } }}>
              <TableActionsMenu>
                <PermissionWrapper whitelist={allAdminRoles} ownerId={project.company.id}>
                  <MenuItem
                    sx={{ minWidth: 120, height: 40 }}
                    disabled={isCompleteLoading || project.markedComplete}
                    onClick={() => {
                      if (!isCompleteLoading) {
                        completeProject(Number(projectId));
                      }
                    }}
                  >
                    {t('translation.button.complete')}
                  </MenuItem>
                </PermissionWrapper>
                <MenuItem
                  sx={{ minWidth: 120, height: 40 }}
                  disabled={isGeneratingReport}
                  onClick={() => {
                    if (!isGeneratingReport) {
                      generateReport(Number(projectId));
                    }
                  }}
                >
                  {t('translation.button.generateReport')}
                </MenuItem>
                <PermissionWrapper whitelist={allAdminRoles} ownerId={project.company.id}>
                  <MenuItem
                    sx={{ minWidth: 120, height: 40 }}
                    disabled={isArchiveLoading || project.archived}
                    onClick={() => {
                      if (!isArchiveLoading) {
                        archiveProject(Number(projectId));
                      }
                    }}
                  >
                    {t('translation.button.archive')}
                  </MenuItem>
                  <MenuItem
                    sx={{ minWidth: 120, height: 40, color: 'error.main' }}
                    onClick={handleDeleteModalOpen}
                  >
                    {t('translation.button.delete')}
                  </MenuItem>
                </PermissionWrapper>
              </TableActionsMenu>
            </Box>
            <ConfirmationModal
              open={openDeleteModal}
              handleClose={handleDeleteModalClose}
              title="translation.projectDetailsPage.deleteProjectModal.title"
              text="translation.projectDetailsPage.deleteProjectModal.text"
              confirmActionButton={
                <LoadingButton
                  loading={isDeleteLoading}
                  color="error"
                  variant="text"
                  onClick={() => {
                    if (!isDeleteLoading) {
                      deleteProject(Number(projectId));
                      handleDeleteModalClose();
                    }
                  }}
                >
                  {t('translation.button.delete')}
                </LoadingButton>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ my: 2, height: { xs: 'auto', sm: 30 }, flexWrap: 'wrap' }}
            display="flex"
            alignItems="center"
          >
            <Typography component="span" mr={1}>
              {t(`translation.label.projectName`)}:
            </Typography>
            <Typography component="span" fontWeight="bold" fontSize={18}>
              {project.name}
            </Typography>

            {project.photoUrl && (
              <Box
                sx={{
                  display: { xs: 'block', sm: 'inline-flex' },
                  width: { xs: '100%', sm: 'auto' },
                  textAlign: 'center',
                  mt: { xs: 2, sm: 0 },
                }}
              >
                <Box
                  sx={{ maxHeight: 30, width: 'auto', mx: 2 }}
                  component="img"
                  src={project.photoUrl}
                  alt="project logo"
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={5} md={4} lg={3} sx={{ mb: 0.5 }}>
            <Box>
              <Typography component="span" mr={1}>
                {t(`translation.label.clientId`)}:
              </Typography>
              <Typography component="span" fontWeight="bold">
                {project.clientId}
              </Typography>
              <PermissionWrapper
                whitelist={allAdminRolesAndAdminTypeEmployee}
                ownerId={project.company.id}
              >
                <Tooltip title={t('translation.button.updateClientId')} placement="top" arrow>
                  <IconButton
                    size="small"
                    sx={{ ml: project.clientId ? 1 : 0, p: 0 }}
                    color="primary"
                    onClick={handleClientIdModalOpen}
                  >
                    <ModeEditOutline sx={{ fontSize: 17 }} />
                  </IconButton>
                </Tooltip>
                <ProjectDetailsActionModal
                  open={openClientIdModal}
                  handleClose={handleClientIdModalClose}
                  handleSubmit={handleClientIdSubmit}
                  title={t('translation.projectDetailsPage.updateClientIdModal.title')}
                  isSubmitting={false}
                >
                  <Grid container pt={2}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        sx={{ my: 1 }}
                        fullWidth
                        label={t('translation.label.clientId')}
                        name="clientId"
                        value={clientId}
                        onChange={(event) => setClientId(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </ProjectDetailsActionModal>
              </PermissionWrapper>
            </Box>
            <Box>
              <Typography component="span" mr={1}>
                {t(`translation.label.isLoyalty`)}:
              </Typography>
              <Typography component="span" fontWeight="bold">
                {t(`translation.common.${project?.isLoyalty ? 'yes' : 'no'}`)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={4} sx={{ mb: 0.5 }}>
            <Box>
              <Typography component="span" mr={1}>
                {t(`translation.label.startDate`)}:
              </Typography>
              <Typography component="span" fontWeight="bold">
                {DateTime.fromISO(project.startDate).toFormat(DATE_FORMAT)}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" mr={1}>
                {t(`translation.label.dueDate`)}:
              </Typography>
              <Typography component="span" fontWeight="bold" sx={{ wordBreak: 'break-all' }}>
                {DateTime.fromISO(project.dueDate).toFormat(DATE_FORMAT)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={4} sx={{ mb: 0.5 }}>
            <Box>
              <Typography component="span" mr={1}>
                {t(`translation.label.progressStatus`)}:
              </Typography>
              <Typography component="span" fontWeight="bold">
                {renderProgressStatus(project.progressStatus)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
        <Grid container mb={{ xs: 4, md: 0 }}>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <TextField
              sx={{ mr: 2, width: 150 }}
              variant="standard"
              select
              label={t(`translation.label.subcontractor`)}
              value={companyId ?? 'all'}
              onChange={(event) =>
                setCompanyId(event.target.value === 'all' ? undefined : Number(event.target.value))
              }
            >
              <MenuItem key="all" value="all">
                {t(`translation.common.all`)}
              </MenuItem>
              {project.subcontractors.map((subcontractor) => (
                <MenuItem key={subcontractor.id} value={subcontractor.id}>
                  {subcontractor.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: 150 }}
              variant="standard"
              select
              label={t(`translation.label.zoneId`)}
              value={zoneId ?? 'all'}
              onChange={(event) =>
                setZoneId(event.target.value === 'all' ? undefined : event.target.value)
              }
            >
              <MenuItem key="all" value="all">
                {t(`translation.common.all`)}
              </MenuItem>
              {projectDetails &&
                projectDetails?.zones.map((zone) => (
                  <MenuItem key={zone ?? 'null'} value={zone ?? 'null'}>
                    {zone ?? t('translation.label.noZone')}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
        <TabsUnstyled value={pathname}>
          <TabsListUnstyled>
            <CustomTab
              // @ts-ignore
              component={RouterLink}
              to={paths.build(paths.PROJECT_DETAILS, projectId)}
              state={location.state || null}
              value={paths.build(paths.PROJECT_DETAILS, projectId)}
            >
              {t('translation.projectDetailsPage.details')}
            </CustomTab>
            <CustomTab
              // @ts-ignore
              component={RouterLink}
              to={paths.build(paths.PROJECT_WORK_ORDERS, projectId)}
              state={location.state || null}
              value={paths.build(paths.PROJECT_WORK_ORDERS, projectId)}
            >
              {t('translation.projectDetailsPage.workOrders')}
            </CustomTab>
            <CustomTab
              // @ts-ignore
              component={RouterLink}
              to={paths.build(paths.PROJECT_REPLACEMENT_GROUPS, projectId)}
              state={location.state || null}
              value={paths.build(paths.PROJECT_REPLACEMENT_GROUPS, projectId)}
            >
              {t('translation.projectDetailsPage.assets')}
            </CustomTab>
          </TabsListUnstyled>

          <Paper sx={{ px: 3, py: 4, borderTopLeftRadius: 0 }}>
            <Outlet
              context={{
                project,
                projectDetails,
                workOrdersPieData,
                projectDocuments,
                isDocumentsLoading,
                zoneId,
                companyId,
                granularity,
                setGranularity,
                replacementGroups,
                isReplacementGroupsLoading,
                projectAssets,
                isAssetsLoading,
                workOrders,
                isWorkOrdersLoading,
                isWorkOrdersFetching,
                workOrderMapData,
              }}
            />
          </Paper>
        </TabsUnstyled>
      </Container>
    </>
  );
};
