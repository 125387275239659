import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Filters } from './components/Filters';
import { ProjectListTable } from './components/ProjectListTable';
import { WorkOrdersMap } from './components/WorkOrdersMap';
import { WorkOrdersPieChart } from '../../components/WorkOrdersPieChart';
import { useDashboard } from './hooks/useDashboard';

export const Dashboard = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    projects,
    isProjectsLoading,
    isProjectsFetching,
    workOrders,
    isLoading,
    statusFilter,
    projectFilter,
    startDateStart,
    startDateEnd,
    setStartDateStart,
    setStartDateEnd,
    setStatusFilter,
    setProjectFilter,
    generatePieChartData,
    getTotal,
  } = useDashboard();

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Grid container>
        <Grid item xs={12} md={4} lg={3} pr={{ xs: 0, md: 2 }} mb={{ xs: 4, md: 0 }}>
          <Filters
            projects={projects}
            isFetching={isProjectsFetching}
            statuses={statusFilter}
            setStatus={setStatusFilter}
            projectFilter={projectFilter}
            setProjectFilter={setProjectFilter}
            startDateStart={startDateStart}
            startDateEnd={startDateEnd}
            setStartDateStart={setStartDateStart}
            setStartDateEnd={setStartDateEnd}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9} pl={{ xs: 0, md: 2 }}>
          <Paper sx={{ p: { xs: 2, xl: 3 }, height: '100%' }}>
            <Grid container>
              <Grid item xs={12} md={12} lg={4} pr={{ lg: 1, xl: 1.5 }} mb={2}>
                <Card
                  sx={{
                    background:
                      theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{ fontSize: 18, fontWeight: 'bold' }}
                    title={t('translation.dashboardPage.workOrders')}
                  />
                  <CardContent>
                    <Box mb={2}>
                      {t('translation.dashboardPage.total')}:{' '}
                      {isLoading ? (
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          sx={{ ml: 3, display: 'inline-block' }}
                          width={60}
                          height={17}
                        />
                      ) : (
                        <Typography ml={3} component="span" fontWeight="bold">
                          {getTotal()}
                        </Typography>
                      )}
                    </Box>
                    <WorkOrdersPieChart pieData={generatePieChartData()} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={8} pl={{ lg: 1, xl: 1.5 }} mb={2}>
                <WorkOrdersMap workOrders={workOrders} />
              </Grid>
              <Grid item xs={12} mt={{ xl: 1 }}>
                <ProjectListTable
                  data={projects}
                  isLoading={isProjectsLoading}
                  isFetching={isProjectsFetching}
                  projectFilter={projectFilter}
                  setProjectFilter={setProjectFilter}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
