import { Navigate, RouteObject } from 'react-router-dom';
import { AuthFlow, AuthWrapper, PermissionWrapper, ProjectImportFlow } from '../containers';
import { AssetComponentsList, AssetModelsList } from '../view/Assets';
import { AssetTabsNav } from '../view/Assets/components/AssetTabsNav';
import { CreateAssetModel } from '../view/Assets';
import { SignIn, ForgotPassword, ResetPassword, ChangePassword } from '../view/AuthPages';
import { CreateCompany, ListCompanies } from '../view/Companies';
import { Dashboard } from '../view/Dashboard';
import { ListProjectImports, ProjectImportErrors, UploadUpdate } from '../view/ProjectImports';
import { ProjectList } from '../view/Project/List';
import { UserSettings } from '../view/Settings';
import { ListUsers, CreateUser } from '../view/Users';
import { paths } from './paths';
import { allAdminRoles } from './permissionGroups';
import { UploadImport, Mapping } from '../view/ProjectImports';
import { CreateProject, FinalizeProject, ProjectDetails } from '../view/Project';
import { ProjectPreview } from '../view/Project/Details/ProjectPreview';
import { ProjectAssets } from '../view/Project/Details/ProjectAssets';
import { ProjectWorkOrders } from '../view/Project/Details/ProjectWorkOrders';
import { WorkOrderDetails, WorkOrderEdit } from '../view/WorkOrder';
import { ProjectRecovery } from '../view/ProjectRecovery';

export const routes: RouteObject[] = [
  {
    path: paths.SIGN_IN,
    element: <AuthFlow />,
    errorElement: <Navigate to={paths.SIGN_IN} />,
    children: [
      {
        path: paths.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: paths.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: paths.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: paths.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
    ],
  },
  {
    element: <AuthWrapper />,
    children: [
      {
        path: paths.SETTINGS,
        element: <UserSettings />,
      },
      {
        path: paths.DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: paths.PROJECTS,
        element: <ProjectList />,
      },
      {
        path: paths.USERS,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <ListUsers />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.USER_CREATE,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <CreateUser />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.USER_EDIT,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <CreateUser />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.COMPANIES,
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <ListCompanies />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.COMPANY_CREATE,
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <CreateCompany />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.COMPANY_EDIT,
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <CreateCompany />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.PROJECT_RECOVERY,
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <ProjectRecovery />
          </PermissionWrapper>
        ),
      },
      {
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <AssetTabsNav />
          </PermissionWrapper>
        ),
        children: [
          {
            path: paths.ASSET_MODELS,
            element: <AssetModelsList />,
          },
          {
            path: paths.ASSET_MODEL_COMPONENTS,
            element: <AssetComponentsList />,
          },
        ],
      },
      {
        path: paths.ASSET_MODEL_CREATE,
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <CreateAssetModel />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.ASSET_MODEL_EDIT,
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <CreateAssetModel />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.PROJECT_IMPORTS,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <ListProjectImports />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.PROJECT_IMPORT_ERRORS,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <ProjectImportErrors />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.PROJECT_IMPORT_CREATE,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <UploadImport />
          </PermissionWrapper>
        ),
      },
      {
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <ProjectImportFlow />
          </PermissionWrapper>
        ),
        children: [
          {
            path: paths.PROJECT_IMPORT_CONTINUE,
            element: <UploadImport />,
          },
          {
            path: paths.PROJECT_IMPORT_MAPPING,
            element: <Mapping />,
          },
          {
            path: paths.PROJECT_IMPORT_MAPPING_NEW,
            element: <Mapping />,
          },
        ],
      },
      {
        path: paths.PROJECT_UPDATE,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <UploadUpdate />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.PROJECT_CREATE,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <CreateProject />
          </PermissionWrapper>
        ),
      },
      {
        path: paths.PROJECT_FINALIZE,
        element: (
          <PermissionWrapper navigate whitelist={allAdminRoles}>
            <FinalizeProject />
          </PermissionWrapper>
        ),
      },
      {
        element: (
          <PermissionWrapper navigate whitelist={[]}>
            <ProjectDetails />
          </PermissionWrapper>
        ),
        children: [
          {
            path: paths.PROJECT_DETAILS,
            element: <ProjectPreview />,
          },
          {
            path: paths.PROJECT_WORK_ORDERS,
            element: <ProjectWorkOrders />,
          },
          {
            path: paths.PROJECT_REPLACEMENT_GROUPS,
            element: <ProjectAssets />,
          },
        ],
      },
      {
        path: paths.WORK_ORDER_CREATE,
        element: null,
      },
      {
        path: paths.WORK_ORDER_DETAILS,
        element: <WorkOrderDetails />,
      },
      {
        path: paths.WORK_ORDER_EDIT,
        element: <WorkOrderEdit />,
      },
    ],
  },
];
