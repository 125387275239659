import { FC, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails,
  AccordionSummaryProps,
  styled,
  Button,
  Box,
  AccordionProps,
  MenuItem,
} from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AssetModelCard } from '../AssetModelCard';
import { TableActionsMenu } from '../TableActionsMenu';
import { ReplacementGroupModal } from '../ReplacementGroupModal';
import { AssetModelData } from '../../hooks/assetModels';
import { ReplacementGroupData, ReplacementGroupInput } from '../../hooks/replacementGroups';
import { allAdminRoles } from '../../constants/permissionGroups';
import { CompanyType, UserRole } from '../../constants/enum';
import { PermissionWrapper } from '../../containers';

interface ReplacementGroupCardProps {
  replacementGroup: ReplacementGroupData;
  assetModels: AssetModelData[];
  onEditGroup?: (id: number, group: ReplacementGroupInput) => void;
  onDeleteGroup?: (id: number) => void;
  defaultExpanded?: boolean;
  modifyWhitelist?: {
    type: CompanyType;
    roles: UserRole[];
  }[];
  preview?: boolean;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
  backgroundImage: 'none',
  borderRadius: '4px',
  '&:not(:last-child)': {
    borderBottom: 0,
    marginBottom: '16px',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
  borderRadius: '4px',
  height: 64,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '&.Mui-expanded': {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export const ReplacementGroupCard: FC<ReplacementGroupCardProps> = ({
  replacementGroup,
  assetModels,
  onEditGroup,
  onDeleteGroup,
  defaultExpanded = true,
  preview = false,
  modifyWhitelist = allAdminRoles,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(defaultExpanded);

  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };

  const handleChange = () => {
    setExpanded((exp) => !exp);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography>{replacementGroup.name}</Typography>
          {!preview && (
            <PermissionWrapper whitelist={modifyWhitelist}>
              <Box
                sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}
                onClick={(event) => event.stopPropagation()}
              >
                <Button
                  color="error"
                  variant="text"
                  size="small"
                  sx={{ minWidth: 126, mr: 2 }}
                  onClick={() =>
                    typeof onDeleteGroup === 'function'
                      ? onDeleteGroup(replacementGroup.id)
                      : undefined
                  }
                >
                  {t('translation.button.deleteGroup')}
                </Button>
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 126 }}
                  onClick={toggleModal}
                >
                  {t('translation.button.editGroup')}
                </Button>
              </Box>
              <Box
                sx={{ display: { xs: 'flex', md: 'none', width: 28 } }}
                onClick={(event) => event.stopPropagation()}
              >
                <TableActionsMenu>
                  <MenuItem sx={{ minWidth: 120, height: 40 }} onClick={toggleModal}>
                    {t('translation.button.editGroup')}
                  </MenuItem>
                  <MenuItem
                    sx={{ minWidth: 120, height: 40, color: 'error.main' }}
                    onClick={() =>
                      typeof onDeleteGroup === 'function'
                        ? onDeleteGroup(replacementGroup.id)
                        : undefined
                    }
                  >
                    {t('translation.button.deleteGroup')}
                  </MenuItem>
                </TableActionsMenu>
              </Box>
            </PermissionWrapper>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography mb={2}>{replacementGroup.notes}</Typography>
        {replacementGroup.assetModels.map((assetModel, index) => (
          <AssetModelCard
            key={assetModel.id}
            assetModel={assetModel}
            index={index}
            preview={preview}
          />
        ))}
      </AccordionDetails>
      <ReplacementGroupModal
        open={open}
        toggleModal={toggleModal}
        assetModels={assetModels ?? []}
        replacementGroup={replacementGroup}
        onSubmitGroup={(group) =>
          typeof onEditGroup === 'function' ? onEditGroup(replacementGroup.id, group) : undefined
        }
      />
    </Accordion>
  );
};
