import { FC, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails,
  AccordionSummaryProps,
  styled,
  Stack,
  Box,
  Grid,
  AccordionProps,
  IconButton,
  Tooltip,
} from '@mui/material';
import { ArrowForwardIosSharp, DeleteOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AssetModelData } from '../../hooks/assetModels';

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.default : '#3D4347',
  backgroundImage: 'none',
  borderRadius: '4px',
  '&:not(:last-child)': {
    borderBottom: 0,
    marginBottom: '16px',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.default : '#3D4347',
  borderRadius: '4px',
  flexDirection: 'row-reverse',
  height: 56,
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
  '&.Mui-expanded': {
    minHeight: 56,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&.Mui-expanded': {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

interface AssetModelCardProps {
  assetModel: AssetModelData;
  preview?: boolean;
  index: number;
  onDeleteAsset?: (index: number) => void;
}

export const AssetModelCard: FC<AssetModelCardProps> = ({
  preview = true,
  assetModel,
  index,
  onDeleteAsset,
}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded((exp) => !exp);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize={14}>{assetModel.name}</Typography>
          {!preview && typeof onDeleteAsset === 'function' && (
            <Box
              sx={{ width: 28, justifyContent: 'right' }}
              onClick={(event) => event.stopPropagation()}
            >
              <Tooltip title={t('translation.button.removeAsset')} placement="top" arrow>
                <IconButton
                  edge="end"
                  aria-label="remove asset"
                  aria-controls="more"
                  aria-haspopup="true"
                  color="error"
                  sx={{ p: 0.5 }}
                  onClick={() => onDeleteAsset(index)}
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.manufacturer')}:
                </Typography>
                <Typography component="div" sx={{ minHeight: 24 }}>
                  {assetModel.manufacturer?.name}
                </Typography>
              </Box>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.classification')}:
                </Typography>
                <Typography component="div" sx={{ minHeight: 24 }}>
                  {assetModel.classification?.name}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.size')}:
                </Typography>
                <Typography component="div" sx={{ minHeight: 24 }}>
                  {assetModel.size?.name}
                </Typography>
              </Box>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.partNumber')}:
                </Typography>
                <Typography component="div" sx={{ minHeight: 24 }}>
                  {assetModel.partNumber}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
