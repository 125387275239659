import { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, IconButton } from '@mui/material';
import { Delete, InsertDriveFileOutlined, VideoFileOutlined } from '@mui/icons-material';
import { FileType } from '../../constants/enum';
import { ProjectDocumentData } from '../../hooks/projectDocuments';
import { allAdminAndEmployeeRoles } from '../../constants/permissionGroups';
import { PermissionWrapper } from '../../containers';
import { WorkOrderDocumentData } from '../../hooks/workOrderDocuments';

interface ImageMediaCardProps {
  document: ProjectDocumentData | WorkOrderDocumentData;
  onDelete: (id: number) => void;
  onClick: () => void;
}

export const ImageMediaCard: FC<ImageMediaCardProps> = ({ document, onDelete, onClick }) => {
  return (
    <Card sx={{ width: 285, position: 'relative' }}>
      <PermissionWrapper whitelist={allAdminAndEmployeeRoles}>
        {document.fileType === FileType.IMAGE ? (
          <Button
            sx={{ position: 'absolute', right: 5, top: 5, p: 1, minWidth: 50, zIndex: 10 }}
            color="secondary"
            variant="contained"
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(document.id);
            }}
          >
            <Delete color="error" />
          </Button>
        ) : (
          <IconButton
            color="error"
            sx={{ position: 'absolute', right: 5, top: 5, zIndex: 10 }}
            onClick={() => onDelete(document.id)}
          >
            <Delete />
          </IconButton>
        )}
      </PermissionWrapper>
      <CardActionArea
        onClick={document.fileType !== FileType.DOCUMENT ? onClick : undefined}
        disabled={document.fileType === FileType.DOCUMENT}
      >
        {document.fileType === FileType.IMAGE && (
          <CardMedia component="img" alt="file" height="145" image={document.fileUrl} />
        )}
        <CardContent>
          {document.fileType !== FileType.IMAGE && (
            <Box
              sx={{
                height: 129,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <Box textAlign="center">
                {document.fileType === FileType.VIDEO ? (
                  <VideoFileOutlined sx={{ fontSize: 60 }} />
                ) : (
                  <InsertDriveFileOutlined sx={{ fontSize: 60 }} />
                )}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  fontWeight="bold"
                  sx={{ wordBreak: 'break-word' }}
                >
                  {document.originalname}
                </Typography>
              </Box>
            </Box>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ height: 20, textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {document.fileType === FileType.IMAGE && document.originalname}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
