import { Button, Container, Grid, MenuItem, Paper, TextField } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useCompanies } from '../../../hooks';
import { CompanyType } from '../../../constants/enum';
import { paths } from '../../../constants/paths';
import { DataTable } from '../../../components';
import { CompaniesActionsColumn } from '../components/ActionsColumn';
import { PermissionWrapper } from '../../../containers/PermissionWrapper';
import { useFilterParams } from '../../../hooks/filterParams';

export const ListCompanies = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [{ where }] = useFilterParams();
  const { data, isLoading, isFetching } = useCompanies();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
        filterList: where?.id ? [where.id] : undefined,
      },
    },
    {
      label: t('translation.label.name'),
      name: 'name',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filterList: where?.name ? [where.name] : undefined,
      },
    },
    {
      label: t('translation.label.companyType'),
      name: 'companyType',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filterList: where?.companyType ? [where.companyType] : undefined,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                variant="standard"
                select
                label={t('translation.label.companyType')}
                name="companyType"
                value={filterList[index][0] ?? ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                {Object.keys(CompanyType).map((companyType) => (
                  <MenuItem key={companyType} value={companyType}>
                    {t(`translation.companyType.${companyType}`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
        customBodyRender: (value) => {
          return <span>{t(`translation.companyType.${value}`)}</span>;
        },
      },
    },
    {
      label: t('translation.label.city'),
      name: 'city',
      options: {
        setCellProps: () => ({ style: { minWidth: '175px' } }),
        filterList: where?.city ? [where.city] : undefined,
      },
    },
    {
      label: t('translation.label.state'),
      name: 'state',
      options: {
        setCellProps: () => ({ style: { minWidth: '150px' } }),
        filterList: where?.state ? [where.state] : undefined,
      },
    },
    {
      label: t('translation.label.zip'),
      name: 'zip',
      options: {
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        filterList: where?.zip ? [where.zip] : undefined,
      },
    },
    {
      label: t('translation.label.address'),
      name: 'address',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filterList: where?.address ? [where.address] : undefined,
      },
    },
    {
      label: t('translation.label.phoneNumber'),
      name: 'phoneNumber',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '120px' } }),
      },
    },
    {
      label: t('translation.label.activeProjects'),
      name: 'activeProjects',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '115px' } }),
      },
    },
    {
      label: t('translation.label.users'),
      name: 'activeUsers',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '80px' } }),
      },
    },
    {
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '50px' } }),
        customBodyRenderLite: (dataIndex) => {
          if (data?.data[dataIndex].id) {
            return <CompaniesActionsColumn companyId={data?.data[dataIndex].id} />;
          }
          return null;
        },
      },
    },
  ];

  // table component returs weird params on filter change
  // iterating over 2d array of filter values and constructing
  // filter object with properties of format { [columnName]: filterValue }
  const buildFilters = (filterList: Array<Array<string>>) => {
    if (filterList.length > 0) {
      const filters: Record<string, string> = {};
      filterList.forEach((filter, index) => {
        if (filter[0]) {
          const colName = columns[index].name;
          const key = colName === 'company.name' ? 'where[companyName]' : `where[${colName}]`;
          filters[key] = filter[0];
        }
      });
      if (Object.keys(filters).length < 1) {
        return null;
      }
      return filters;
    }
    return null;
  };

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <PermissionWrapper>
            <Grid item xs={12} sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                component={Link}
                state={location.search || null}
                to={paths.COMPANY_CREATE}
              >
                {t('translation.button.add')} +
              </Button>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12}>
            <DataTable
              data={data?.data}
              columns={columns}
              count={data?.meta.count}
              isLoading={isLoading}
              isFetching={isFetching}
              customBuildFilters={buildFilters}
              title="translation.companiesListPage.title"
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
