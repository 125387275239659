import { Button, Container, Grid, MenuItem, Paper, TextField } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useUsers } from '../../../hooks';
import { UserRole } from '../../../constants/enum';
import { paths } from '../../../constants/paths';
import { DataTable } from '../../../components';
import { UsersActionsColumn } from '../components/ActionsColumn';
import { PermissionWrapper } from '../../../containers/PermissionWrapper';
import { allAdminRoles } from '../../../constants/permissionGroups';
import { useFilterParams } from '../../../hooks/filterParams';

export const ListUsers = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [{ order, where }] = useFilterParams();
  const { data, isLoading, isFetching } = useUsers();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
        filterList: where?.id ? [where.id] : undefined,
      },
    },
    {
      label: t('translation.label.role'),
      name: 'role',
      options: {
        setCellProps: () => ({ style: { minWidth: '130px' } }),
        filterList: where?.role ? [where.role] : undefined,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                variant="standard"
                select
                label={t('translation.label.role')}
                name="role"
                value={filterList[index][0] ?? ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                {Object.keys(UserRole).map((role) => (
                  <MenuItem key={role} value={role}>
                    {t(`translation.userRole.${role}`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
        customBodyRender: (value) => {
          return <span>{t(`translation.userRole.${value}`)}</span>;
        },
      },
    },
    {
      label: t('translation.label.firstName'),
      name: 'firstName',
      options: {
        setCellProps: () => ({ style: { minWidth: '225px' } }),
        filterList: where?.firstName ? [where.firstName] : undefined,
      },
    },
    {
      label: t('translation.label.lastName'),
      name: 'lastName',
      options: {
        setCellProps: () => ({ style: { minWidth: '225px' } }),
        filterList: where?.lastName ? [where.lastName] : undefined,
      },
    },
    {
      label: t('translation.label.email'),
      name: 'email',
      options: {
        setCellProps: () => ({
          style: { minWidth: '250px', overflowWrap: 'break-word', wordBreak: 'break-all' },
        }),
        filterList: where?.email ? [where.email] : undefined,
      },
    },
    {
      label: t('translation.label.phoneNumber'),
      name: 'phoneNumber',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '170px' } }),
      },
    },
    {
      label: t('translation.label.company'),
      name: 'company.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '250px' } }),
        filterList: where?.companyName ? [where.companyName] : undefined,
      },
    },
    {
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '50px' } }),
        customBodyRenderLite: (dataIndex) => {
          if (data?.data[dataIndex].id) {
            return <UsersActionsColumn userId={data?.data[dataIndex].id} />;
          }
          return null;
        },
      },
    },
  ];

  // table component returs weird params on filter change
  // iterating over 2d array of filter values and constructing
  // filter object with properties of format { [columnName]: filterValue }
  const buildFilters = (filterList: Array<Array<string>>) => {
    if (filterList.length > 0) {
      const filters: Record<string, string> = {};
      filterList.forEach((filter, index) => {
        if (filter[0]) {
          const colName = columns[index].name;
          const key = colName === 'company.name' ? 'where[companyName]' : `where[${colName}]`;
          filters[key] = filter[0];
        }
      });
      if (Object.keys(filters).length < 1) {
        return null;
      }
      return filters;
    }
    return null;
  };

  const buildSortOrder = (property: string, direction: string) => {
    if (property && direction) {
      const colName = property === 'company.name' ? 'companyName' : property;
      const key = `order[${colName}]`;
      return { [key]: direction };
    }
    return null;
  };

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <PermissionWrapper whitelist={allAdminRoles}>
            <Grid item xs={12} sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
              <Button
                sx={{ minWidth: 120 }}
                variant="contained"
                component={Link}
                state={location.search || null}
                to={paths.USER_CREATE}
              >
                {t('translation.button.add')} +
              </Button>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12}>
            <DataTable
              data={data?.data}
              columns={columns}
              count={data?.meta.count}
              isLoading={isLoading}
              isFetching={isFetching}
              customBuildFilters={buildFilters}
              customBuildSortOrder={buildSortOrder}
              title="translation.usersListPage.title"
              options={{
                sortOrder: order
                  ? Object.entries(order).map(([key, value]) => ({
                      name: key === 'companyName' ? 'company.name' : key,
                      direction: value.toLowerCase(),
                    }))[0]
                  : undefined,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
