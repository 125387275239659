import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { ReplacementGroupCard } from '../../../../components/ReplacementGroupCard';
import {
  useCreateReplacementGroup,
  useDeleteReplacementGroup,
  useFinalizeProject,
  useReplacementGroups,
  useSingleProject,
} from '../../../../hooks';
import { ProjectStatus } from '../../../../constants/enum';
import { paths } from '../../../../constants/paths';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { ReplacementGroupModal } from '../../../../components';
import { AssetModelData } from '../../../../hooks/assetModels';
import { ProjectImportGrouperWorkOrderData } from '../../../../hooks/projectImports';
import {
  ReplacementGroupInput,
  useUpdateReplacementGroup,
} from '../../../../hooks/replacementGroups';
import { AssetGroupCard } from './AssetGroupCard';
import { LoadingButton } from '@mui/lab';

const steps = ['translation.createProjectPage.step1', 'translation.createProjectPage.step2'];

interface ManageAssetsProps {
  projectId: number;
  groupedWorkOrders: ProjectImportGrouperWorkOrderData[];
  assetModels: AssetModelData[];
}

export const ManageAssets: FC<ManageAssetsProps> = ({
  projectId,
  groupedWorkOrders,
  assetModels,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [workOrdersReplacementGroup, setWorkOrdersReplacementGroup] = useState(() =>
    Array(groupedWorkOrders.length).fill(''),
  );

  const [open, setOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const { data: project, isLoading: isProjectLoading } = useSingleProject(projectId);
  const { data: replacementGroups, isLoading: isGroupsLoading } = useReplacementGroups(projectId);
  const { mutate: createGroup } = useCreateReplacementGroup();
  const { mutate: deleteGroup } = useDeleteReplacementGroup();
  const { mutate: updateGroup } = useUpdateReplacementGroup();
  const { mutate: finalizeProject, isLoading: isSubmitting } = useFinalizeProject();

  const handleCheck = useCallback(
    (index: number) => (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setCheckedList((prevState) => {
        const newState = [...prevState];
        if (checked) {
          newState.push(index);
        } else {
          const itemIndex = newState.findIndex((item) => item === index);
          if (itemIndex > -1) {
            newState.splice(itemIndex, 1);
          }
        }
        return newState;
      });
    },
    [],
  );

  const handleChangeValue = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      setWorkOrdersReplacementGroup((prevState) => {
        const newState = [...prevState];
        newState[index] = event.target.value;
        return newState;
      });
    },
    [],
  );

  const handleSubmit = () => {
    if (!isSubmitting && !workOrdersReplacementGroup.some((group) => group === '')) {
      const temp: Record<number, number[]> = {};
      workOrdersReplacementGroup.forEach((groupId, index) => {
        if (temp[groupId]) {
          temp[groupId].push(index);
        } else {
          temp[groupId] = [index];
        }
      });
      const params: Array<{
        replacementGroupId: number;
        workOrders: number[];
      }> = [];
      Object.entries(temp).forEach(([key, values]) => {
        const workOrders = values.map((index) => groupedWorkOrders[index].workOrders);
        params.push({
          replacementGroupId: Number(key),
          workOrders: workOrders.flat(),
        });
      });
      finalizeProject({
        id: projectId,
        data: {
          replacementGroupAssignments: params,
        },
      });
    }
  };

  const handleAddReplacementGroup = useCallback(
    (group: ReplacementGroupInput) => {
      createGroup({
        ...group,
        assetModels: group.assetModels.map((assetModel) => ({ id: assetModel.id })),
        projectId: projectId,
      });
    },
    [createGroup, projectId],
  );

  const handleEditReplacementGroup = useCallback(
    (id: number, group: ReplacementGroupInput) => {
      updateGroup({
        id,
        data: {
          ...group,
          assetModels: group.assetModels.map((assetModel) => ({ id: assetModel.id })),
        },
      });
    },
    [updateGroup],
  );

  const handleDeleteReplacementGroup = useCallback(
    (id: number) => {
      setWorkOrdersReplacementGroup((prevState) => {
        const newState = [...prevState].map((value) => (value === id ? '' : value));
        return newState;
      });
      deleteGroup(id);
    },
    [deleteGroup],
  );

  if (isProjectLoading) {
    return null;
  }

  if (project?.status !== ProjectStatus.CREATED) {
    return <Navigate to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }} />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.createProjectPage.title')}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" mb={4}>
            <Box sx={{ width: { xs: '100%', md: '70%' } }}>
              <Stepper activeStep={2} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ mb: 3, px: { xs: 1, md: 3 } }}
            display="flex"
            alignItems="center"
          >
            <Typography variant="body1">
              {t('translation.createProjectPage.replacementGroups')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ mb: 3, px: { xs: 1, md: 3 } }}
            display="flex"
            justifyContent="right"
          >
            {!replacementGroups || isGroupsLoading ? (
              <Skeleton variant="rounded" width={120} height={30.75} />
            ) : (
              <Button variant="contained" size="small" sx={{ minWidth: 120 }} onClick={toggleModal}>
                {t('translation.button.addGroup')} +
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            {!replacementGroups || isGroupsLoading ? (
              <Skeleton variant="rounded" width="100%" height={150} />
            ) : replacementGroups.length < 1 ? (
              <Typography textAlign="center" my={5}>
                {t('translation.createProjectPage.noReplacementGroups')}
              </Typography>
            ) : (
              replacementGroups.map((group, index) => (
                <ReplacementGroupCard
                  key={index}
                  replacementGroup={group}
                  assetModels={assetModels || []}
                  onDeleteGroup={handleDeleteReplacementGroup}
                  onEditGroup={handleEditReplacementGroup}
                />
              ))
            )}
          </Grid>
          <Grid
            item
            xs={4}
            sm={6}
            md={8}
            sx={{ my: 3, px: { xs: 1, md: 3 } }}
            display="flex"
            alignItems="center"
          >
            <Typography>{t('translation.createProjectPage.assets')}</Typography>
          </Grid>
          <Grid item xs={8} sm={6} md={4} sx={{ my: 3, pr: { xs: 1, md: 3 } }}>
            <Box sx={{ pr: 0, pl: { sm: 3, md: 1 }, width: '100%' }}>
              {!replacementGroups || isGroupsLoading ? (
                <Skeleton variant="rounded" width="100%" height={40} />
              ) : (
                <TextField
                  id="replacement-group"
                  select
                  fullWidth
                  size="small"
                  label={t('translation.label.replacementGroup')}
                  disabled={!replacementGroups || replacementGroups.length < 1}
                  value=""
                  onChange={(event) => {
                    setWorkOrdersReplacementGroup((prevState) => {
                      const newState = [...prevState];
                      checkedList.forEach((item) => (newState[item] = event.target.value));
                      return newState;
                    });
                    setCheckedList([]);
                  }}
                >
                  {replacementGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            {groupedWorkOrders?.map((group, index) => (
              <AssetGroupCard
                key={JSON.stringify(group)}
                assetGroup={group}
                replacementGroups={replacementGroups || []}
                value={workOrdersReplacementGroup[index]}
                checked={checkedList.includes(index)}
                onChange={handleChangeValue(index)}
                onCheckChange={handleCheck(index)}
              />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
          >
            <Button
              color="inherit"
              variant="text"
              sx={{ minWidth: 120, mr: 2 }}
              component={Link}
              to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }}
            >
              {t('translation.button.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ minWidth: 120 }}
              loading={isSubmitting}
              disabled={workOrdersReplacementGroup.some((group) => group === '')}
              onClick={handleSubmit}
            >
              {t('translation.button.create')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
      <ReplacementGroupModal
        open={open}
        toggleModal={toggleModal}
        assetModels={assetModels ?? []}
        replacementGroup={undefined}
        onSubmitGroup={handleAddReplacementGroup}
      />
    </Container>
  );
};
