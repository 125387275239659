import { FC } from 'react';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useProjectAssets } from './hooks/useProjectAssets';
import { DataTable, ReplacementGroupCard, ReplacementGroupModal } from '../../../components';
import { PermissionWrapper } from '../../../containers';

interface ProjectAssetsProps {}

export const ProjectAssets: FC<ProjectAssetsProps> = () => {
  const { t } = useTranslation();
  const {
    open,
    replacementGroups,
    isReplacementGroupsLoading,
    assetModels,
    assetsSummary,
    assetsBySubcontractor,
    assetsSummaryColumns,
    assetsBySubcontractorColumns,
    isAssetsLoading,
    toggleModal,
    handleAddReplacementGroup,
    handleEditReplacementGroup,
    handleDeleteReplacementGroup,
  } = useProjectAssets();

  return (
    <Grid container>
      <Grid item xs={6} sm={6} sx={{ mb: 3 }} display="flex" alignItems="center">
        <Typography variant="body1">
          {t('translation.projectDetailsPage.replacementGroups')}
        </Typography>
      </Grid>
      <PermissionWrapper whitelist={[]}>
        <Grid item xs={6} sm={6} sx={{ mb: 3 }} display="flex" justifyContent="right">
          {!replacementGroups || isReplacementGroupsLoading ? (
            <Skeleton variant="rounded" width={120} height={30.75} />
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ minWidth: 120 }}
              onClick={toggleModal}
            >
              {t('translation.button.addGroup')} +
            </Button>
          )}
        </Grid>
      </PermissionWrapper>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid container spacing={4}>
          {!replacementGroups || isReplacementGroupsLoading ? (
            <>
              <Grid item xs={12} lg={6}>
                <Skeleton variant="rounded" width="100%" height={150} />
              </Grid>
              <Grid item xs={12} lg={6} display={{ xs: 'none', lg: 'block' }}>
                <Skeleton variant="rounded" width="100%" height={150} />
              </Grid>
            </>
          ) : replacementGroups.length < 1 ? (
            <Grid item xs={12}>
              <Typography textAlign="center" my={5}>
                {t('translation.createProjectPage.noReplacementGroups')}
              </Typography>
            </Grid>
          ) : (
            replacementGroups.map((group) => (
              <Grid item xs={12} lg={6} key={group.id}>
                <ReplacementGroupCard
                  defaultExpanded={false}
                  replacementGroup={group}
                  assetModels={assetModels || []}
                  onDeleteGroup={handleDeleteReplacementGroup}
                  onEditGroup={handleEditReplacementGroup}
                  // Superadmin only
                  modifyWhitelist={[]}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} my={4}>
        <DataTable
          title={t('translation.projectDetailsPage.assetsSummary')}
          data={assetsSummary}
          columns={assetsSummaryColumns}
          isLoading={isAssetsLoading}
          isFetching={false}
          options={{
            serverSide: false,
            filter: false,
            responsive: 'vertical',
            pagination: false,
            onTableChange: undefined,
          }}
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <DataTable
          title={t('translation.projectDetailsPage.assetsBySubcontractor')}
          data={assetsBySubcontractor}
          columns={assetsBySubcontractorColumns}
          isLoading={isAssetsLoading}
          isFetching={false}
          options={{
            serverSide: false,
            filter: false,
            responsive: 'vertical',
            pagination: false,
            onTableChange: undefined,
          }}
        />
      </Grid>
      <ReplacementGroupModal
        open={open}
        toggleModal={toggleModal}
        assetModels={assetModels ?? []}
        replacementGroup={undefined}
        onSubmitGroup={handleAddReplacementGroup}
      />
    </Grid>
  );
};
