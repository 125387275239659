import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { UserData } from '../../../hooks/users';
import { useUpdateProfile } from '../../../hooks';

interface SettingsFormProps {
  user: UserData;
}

export const SettingsForm: FC<SettingsFormProps> = ({ user }) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useUpdateProfile();

  const [userData, setUser] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (userData.firstName && userData.lastName && !isLoading) {
      mutate(userData);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.email')}
            name="email"
            disabled
            value={user.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.phoneNumber')}
            name="phoneNumber"
            value={userData.phoneNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.firstName')}
            name="firstName"
            value={userData.firstName}
            error={submitted && !userData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.lastName')}
            name="lastName"
            value={userData.lastName}
            error={submitted && !userData.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
        >
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 120 }}
            type="submit"
            loading={isLoading}
          >
            {t('translation.button.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
