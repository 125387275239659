import { FC, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails,
  AccordionSummaryProps,
  styled,
  Stack,
  Box,
  Grid,
  AccordionProps,
} from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { WorkOrderZoneSummary } from '../../../../hooks/projectDetails';

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.default : '#3D4347',
  backgroundImage: 'none',
  borderRadius: '4px',
  '&:not(:last-child)': {
    borderBottom: 0,
    marginBottom: '16px',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.default : '#3D4347',
  borderRadius: '4px',
  flexDirection: 'row-reverse',
  height: 56,
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
  '&.Mui-expanded': {
    minHeight: 56,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&.Mui-expanded': {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

interface ZoneCardProps {
  zone: WorkOrderZoneSummary;
}

export const ZoneCard: FC<ZoneCardProps> = ({ zone }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((exp) => !exp);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize={14} fontWeight="bold">
            {zone.zoneId ?? t('translation.label.noZone')}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.totalInZone')}:
                </Typography>
                <Typography component="div">{zone.total}</Typography>
              </Box>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.enum.COMPLETED')}:
                </Typography>
                <Typography component="div">{zone.completed}</Typography>
              </Box>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.remaining')}:
                </Typography>
                <Typography component="div">{zone.remaining}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.enum.UNABLE')}:
                </Typography>
                <Typography component="div">{zone.unable}</Typography>
              </Box>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.enum.RTU')}:
                </Typography>
                <Typography component="div">{zone.rtu}</Typography>
              </Box>
              <Box>
                <Typography component="div" color="text.secondary">
                  {t('translation.label.completionRate')}:
                </Typography>
                <Typography component="div">{(zone.completionRate * 100).toFixed()}%</Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
