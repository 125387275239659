import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import {
  ResponsiveContainer,
  Tooltip,
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

interface WorkOrdersLineChartProps {
  chartData: any[];
}

export const WorkOrdersLineChart: FC<WorkOrdersLineChartProps> = ({ chartData }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // https://github.com/recharts/recharts/issues/172#issuecomment-240036906
        flex: 1,
        width: 0,
      }}
    >
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="0" />
          <XAxis minTickGap={10} tick={{ fontSize: '15px' }} dataKey="date" />
          <YAxis
            allowDecimals={false}
            dataKey="total"
            tick={{ fontSize: '15px' }}
            type="number"
            name="completed"
          />
          <Tooltip
            contentStyle={{ backgroundColor: theme.palette.mode === 'light' ? 'white' : '#3D4347' }}
          />
          <Area
            type="monotone"
            dataKey="total"
            name={t('translation.label.completed')}
            stroke={
              theme.palette.mode === 'light'
                ? theme.palette.success.dark
                : theme.palette.success.main
            }
            fill={theme.palette.success.main}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
