import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Button, Box, Autocomplete } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AssetModelData, AssetModelInput } from '../../../../hooks/assetModels';
import { useCreateAssetModel, useUpdateAssetModel } from '../../../../hooks';
import { paths } from '../../../../constants/paths';
import { AssetManufacturerData } from '../../../../hooks/assetManufacturers';
import { AssetSizeData } from '../../../../hooks/assetSizes';

interface AssetModelFormProps {
  assetModel?: AssetModelData;
  manufacturers: AssetManufacturerData[];
  sizes: AssetSizeData[];
}

export const AssetModelForm: FC<AssetModelFormProps> = ({ assetModel, manufacturers, sizes }) => {
  const location = useLocation();

  const [assetModelData, setAssetModel] = useState<AssetModelInput>({
    name: assetModel?.name || '',
    partNumber: assetModel?.partNumber || '',
    manufacturerId: assetModel?.manufacturer?.id || null,
    classificationId: assetModel?.classification?.id || null,
    sizeId: assetModel?.size?.id || null,
  });

  const [submitted, setSubmitted] = useState(false);

  const createAssetModel = useCreateAssetModel();
  const updateAssetModel = useUpdateAssetModel();

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      assetModelData.name &&
      assetModelData.partNumber &&
      assetModelData.manufacturerId &&
      assetModelData.classificationId &&
      assetModelData.sizeId &&
      !createAssetModel.isLoading &&
      !updateAssetModel.isLoading
    ) {
      if (assetModel) {
        updateAssetModel.mutate({ id: assetModel.id, data: assetModelData });
      } else {
        createAssetModel.mutate(assetModelData);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setAssetModel((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.name')}
            name="name"
            value={assetModelData.name}
            error={submitted && !assetModelData.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.partNumber')}
            name="partNumber"
            value={assetModelData.partNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <Autocomplete
            disablePortal
            id="manufacturer"
            options={manufacturers}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={manufacturers.find((m) => m.id === assetModelData.manufacturerId) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translation.label.manufacturer')}
                error={submitted && !assetModelData.manufacturerId}
              />
            )}
            onChange={(_, value) => {
              setSubmitted(false);
              setAssetModel((prevState) => ({
                ...prevState,
                manufacturerId: value?.id || null,
                classificationId: null,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <Autocomplete
            disablePortal
            id="classification"
            options={
              manufacturers.find((m) => m.id === assetModelData.manufacturerId)?.classifications ||
              []
            }
            getOptionLabel={(option) => option.name}
            fullWidth
            disabled={!assetModelData.manufacturerId}
            value={
              manufacturers
                .find((m) => m.id === assetModelData.manufacturerId)
                ?.classifications.find((c) => c.id === assetModelData.classificationId) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translation.label.classification')}
                error={submitted && !assetModelData.classificationId}
              />
            )}
            onChange={(_, value) => {
              setSubmitted(false);
              setAssetModel((prevState) => ({
                ...prevState,
                classificationId: value?.id || null,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <Autocomplete
            disablePortal
            id="size"
            options={sizes}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={sizes.find((m) => m.id === assetModelData.sizeId) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translation.label.size')}
                error={submitted && !assetModelData.sizeId}
              />
            )}
            onChange={(_, value) => {
              setSubmitted(false);
              setAssetModel((prevState) => ({
                ...prevState,
                sizeId: value?.id || null,
              }));
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
        >
          <Button
            color="inherit"
            variant="text"
            sx={{ minWidth: 120, mr: 2 }}
            component={Link}
            to={{ pathname: paths.ASSET_MODELS, search: location.state || '' }}
          >
            <Trans i18nKey="translation.button.cancel" />
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 120 }}
            type="submit"
            loading={createAssetModel.isLoading || updateAssetModel.isLoading}
          >
            <Trans i18nKey="translation.button.save" />
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
