import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import queryString from 'qs';
import { ProjectImportStatus } from '../../constants/enum';
import { paths } from '../../constants/paths';
import { useSingleProjectImport } from '../../hooks';

export const ProjectImportFlow = () => {
  const location = useLocation();
  const { projectImportId } = useParams();
  const [searchParams] = useSearchParams();
  const { newMapping } = queryString.parse(searchParams.toString());

  const { data, isLoading } = useSingleProjectImport(Number(projectImportId));

  if (isLoading) {
    return null;
  }

  if (
    data?.status === ProjectImportStatus.UPLOADED &&
    ![
      paths.build(paths.PROJECT_IMPORT_MAPPING, projectImportId),
      paths.build(paths.PROJECT_IMPORT_CONTINUE, projectImportId),
    ].includes(decodeURI(location.pathname))
  ) {
    if (data.mapping || newMapping) {
      return (
        <Navigate
          state={location.state || ''}
          to={paths.build(paths.PROJECT_IMPORT_MAPPING, projectImportId)}
        />
      );
    }
    return (
      <Navigate
        state={location.state || ''}
        to={paths.build(paths.PROJECT_IMPORT_CONTINUE, projectImportId)}
      />
    );
  }

  return <Outlet />;
};
