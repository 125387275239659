import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { Layout } from '../Layout';
import { paths } from '../../constants/paths';

export const AuthWrapper = () => {
  const { route, authStatus } = useAuthenticator((context) => [context.route, context.authStatus]);

  if (authStatus === 'configuring' && route === 'idle') {
    return null;
  }

  if (authStatus === 'authenticated') {
    return (
      <Authenticator>
        {() => (
          <Layout>
            <Outlet />
            <ScrollRestoration />
          </Layout>
        )}
      </Authenticator>
    );
  }

  return <Navigate to={paths.SIGN_IN} />;
};
