import { useSearchParams } from 'react-router-dom';
import queryString from 'qs';

interface FilterData {
  where?: Record<string, any>;
  order?: Record<string, any>;
  take?: number;
  page?: number;
}

export const useFilterParams = (): [FilterData, ReturnType<typeof useSearchParams>[1]] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const parsedQuery = queryString.parse(searchParams.toString());
  const { where, order, take, page } = parsedQuery;

  const filteredParams = {
    where: where && typeof where === 'object' ? where : undefined,
    order: order && typeof order === 'object' ? order : undefined,
    take: typeof take === 'string' && !isNaN(Number(take)) ? Number(take) : undefined,
    page: typeof page === 'string' && !isNaN(Number(page)) ? Number(page) : undefined,
  };

  return [filteredParams, setSearchParams];
};
