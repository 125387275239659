import { FormEventHandler, useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { AuthBoxLayout } from '../../../containers';

export const ForgotPassword = () => {
  const { t } = useTranslation();

  const { error, submitForm, toSignIn, isPending } = useAuthenticator();

  const [submit, setSubmit] = useState(false);
  const [username, setUsername] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSubmit(true);
    if (username && !isPending) {
      submitForm({ username });
    }
  };

  return (
    <AuthBoxLayout
      cardActions={
        <>
          <Button
            disabled={isPending}
            sx={{ minWidth: 120, textTransform: 'none' }}
            color="inherit"
            variant="text"
            onClick={() => {
              toSignIn();
            }}
          >
            <Trans i18nKey="translation.forgotPasswordPage.backToSignIn" />
          </Button>
          <LoadingButton
            form="sendCode"
            loading={isPending}
            sx={{ minWidth: 120 }}
            variant="contained"
            type="submit"
          >
            <Trans i18nKey="translation.forgotPasswordPage.sendCode" />
          </LoadingButton>
        </>
      }
    >
      <Typography sx={{ my: 3, fontWeight: 'bold' }} variant="h5" component="div">
        <Trans i18nKey="translation.forgotPasswordPage.title" />
      </Typography>
      {error && (
        <Alert sx={{ mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Box>
        <form id="sendCode" onSubmit={handleSubmit}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="username"
            autoComplete="username"
            label={t('translation.label.username')}
            name="username"
            error={submit && !username}
            onChange={(event) => {
              setSubmit(false);
              setUsername(event.target.value);
            }}
          />
        </form>
      </Box>
    </AuthBoxLayout>
  );
};
