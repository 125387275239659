import { FC, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline, ModeEditOutline } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../components';
import { paths } from '../../../../constants/paths';
import { useDeleteWorkOrder } from '../../../../hooks';
import { UserData } from '../../../../hooks/users';
import { CompanyType } from '../../../../constants/enum';

interface WorkOrdersActionsColumnProps {
  workOrderId: number;
  projectId: number;
  companyId?: number;
  zoneId?: string;
  user: UserData;
}

export const WorkOrdersActionsColumn: FC<WorkOrdersActionsColumnProps> = ({
  workOrderId,
  projectId,
  companyId,
  zoneId,
  user,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useDeleteWorkOrder({ projectId, companyId, zoneId });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'space-evenly' } }}>
      <Tooltip title={t('translation.button.updateWorkOrder')} placement="top" arrow>
        <IconButton
          component={Link}
          size="small"
          disabled={
            user.company.companyType !== CompanyType.ADMIN &&
            !!companyId &&
            companyId !== user.company.id
          }
          sx={{ mx: { xs: 1, md: 0 } }}
          to={paths.build(paths.WORK_ORDER_EDIT, projectId, workOrderId)}
          state={location.search || null}
          color="primary"
        >
          <ModeEditOutline fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('translation.button.deleteWorkOrder')} placement="top" arrow>
        <IconButton
          size="small"
          disabled={
            isLoading ||
            (user.company.companyType !== CompanyType.ADMIN &&
              !!companyId &&
              companyId !== user.company.id)
          }
          onClick={handleClickOpen}
          sx={{ mx: { xs: 1, md: 0 } }}
          color="error"
        >
          <DeleteOutline fontSize="small" />
        </IconButton>
      </Tooltip>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        title="translation.projectDetailsPage.deleteWorkOrderModal.title"
        text="translation.projectDetailsPage.deleteWorkOrderModal.text"
        confirmActionButton={
          <LoadingButton
            loading={isLoading}
            color="error"
            variant="text"
            onClick={() => {
              if (!isLoading) {
                mutate(workOrderId);
                handleClose();
              }
            }}
          >
            {t('translation.button.delete')}
          </LoadingButton>
        }
      />
    </Box>
  );
};
