import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import axiosInstance from '../config/axios';
import { TimePeriod } from '../constants/enum';

export interface ProjectDetailsParams {
  projectId: number;
  companyId?: number;
  zoneId?: string;
  granularity?: TimePeriod;
}

export interface WorkOrderChartData {
  total: number;
  completed: number;
  assigned: number;
  unassigned: number;
  unable: number;
  rtu: number;
  date: string;
}
export interface WorkOrderZoneSummary {
  zoneId: string | null;
  total: number;
  completed: number;
  remaining: number;
  unable: number;
  rtu: number;
  completionRate: number;
}
export interface SubcontractorsSummary {
  total: number;
  completed: number;
  assigned: number;
  unable: number;
  rtu: number;
  pendingReturn: number;
  unassigned: number;
  returned: number;
  completionRate: number;
  companyName: string;
}
export interface SubcontractorAverages {
  companyId: number;
  name: string;
  remaining: number;
  completed: number;
  last7Days: {
    max: number;
    min: number;
    total: number;
  };
  last30Days: {
    max: number;
    min: number;
    total: number;
  };
}

export interface ProjectDetailsData {
  chartData: WorkOrderChartData[];
  zoneSummary: WorkOrderZoneSummary[];
  subcontractorsSummary: SubcontractorsSummary[];
  subcontractorAverages: SubcontractorAverages[];
  zones: Array<string | null>;
}

export interface ProjectAssetsSummary {
  id: number;
  assetId: number;
  assetManufacturer: string;
  assetClassification: string;
  assetName: string;
  assetSize: string;
  totalWorkOrders: number;
  totalToReplace: number;
  unassigned: number;
  completed: number;
  assigned: number;
  rtu: number;
  returned: number;
  unable: number;
}

export interface ProjectAssetsBySubcontractor {
  id: number;
  assetId: number;
  assetManufacturer: string;
  assetClassification: string;
  assetName: string;
  assetSize: string;
  companyName: string;
  installed: number;
  unable: number;
  rtu: number;
  returned: number;
}

export interface ProjectAssetsData {
  assetsSummary: ProjectAssetsSummary[];
  assetsBySubcontractor: ProjectAssetsBySubcontractor[];
}

const getProjectDetails = async (params: ProjectDetailsParams) => {
  try {
    const { projectId, ...rest } = params;

    const { data } = await axiosInstance.get(`/project/${projectId}/details`, {
      params: rest,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getProjectAssets = async (params: ProjectDetailsParams) => {
  try {
    const { projectId, ...rest } = params;

    const { data } = await axiosInstance.get(`/project/${projectId}/assets`, {
      params: rest,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getProjectWorkOrderIds = async (projectId: number) => {
  try {
    const { data } = await axiosInstance.get(`/project/${projectId}/work-orders`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useProjectDetails(
  params: ProjectDetailsParams,
  options?: UseQueryOptions<ProjectDetailsData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['project-details', ...Object.entries(params)],
    queryFn: () => getProjectDetails(params),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useProjectAssets(
  params: ProjectDetailsParams,
  options?: UseQueryOptions<ProjectAssetsData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['project-assets', ...Object.entries(params)],
    queryFn: () => getProjectAssets(params),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useProjectWorkOrderIds(
  projectId: number,
  options?: UseQueryOptions<number[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['project-work-order-ids', projectId],
    queryFn: () => getProjectWorkOrderIds(projectId),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}
