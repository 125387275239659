import { FC } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Skeleton,
  TableHead,
  TableBody,
  useTheme,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

interface SkeletonTableProps {
  title?: string;
}

export const SkeletonTable: FC<SkeletonTableProps> = ({ title }) => {
  const theme = useTheme();

  const backgroundColor = theme.palette.mode === 'dark' ? 'background.default' : 'white';

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          {title && (
            <Grid container alignItems="center" sx={{ backgroundColor, px: 3, minHeight: 64 }}>
              <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            </Grid>
          )}
          <Box sx={{ display: 'table', tableLayout: 'fixed', width: '100%', overflowY: 'scroll' }}>
            <TableContainer sx={{ maxHeight: 470 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={{ height: 50 }}>
                    {Array(6)
                      .fill('')
                      .map((_, index) => (
                        <TableCell key={index} sx={{ backgroundColor }}>
                          <Box sx={{ maxWidth: 200, my: 'auto', mx: 1 }}>
                            <Skeleton animation="wave" />
                          </Box>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array(5)
                    .fill('')
                    .map((_, index) => (
                      <TableRow key={index} sx={{ height: 50 }}>
                        {Array(6)
                          .fill('')
                          .map((_, idx) => (
                            <TableCell key={index + idx}>
                              <Box sx={{ maxWidth: 200, my: 'auto', mx: 1 }}>
                                <Skeleton animation="wave" />
                              </Box>
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
