import { Grid, Card, CardContent, Box, CardActions, useTheme } from '@mui/material';
import { BugWorkaround } from '../../components';
import { FC, ReactNode } from 'react';

interface AuthBoxLayoutProps {
  children: ReactNode;
  cardActions: ReactNode;
}

export const AuthBoxLayout: FC<AuthBoxLayoutProps> = ({ children, cardActions }) => {
  const theme = useTheme();

  return (
    <Grid container sx={{ justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
      <Grid item>
        <BugWorkaround />
        <Card sx={{ height: 'auto', minWidth: { xs: 'auto', sm: 450 } }}>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
              <Box
                sx={{ height: 'auto', width: 150 }}
                component="img"
                src={theme.palette.mode === 'dark' ? '/HP-Logo.png' : '/HP-Logo-dark.png'}
              />
            </Box>
            {children}
          </CardContent>
          <CardActions sx={{ px: 3, pb: 3, pt: 0, justifyContent: 'end' }}>
            {cardActions}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
