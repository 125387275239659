import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { ProjectData } from './projects';
import { useTranslation } from 'react-i18next';

const getDeletedProjects = async () => {
  try {
    const { data } = await axiosInstance.get('/project/deleted');

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const restoreProject = async (id: number) => {
  try {
    const { data } = await axiosInstance.post(`/project/${id}/restore`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useDeletedProjects(options?: UseQueryOptions<ProjectData[], CustomApiError>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['deleted-projects'],
    queryFn: () => getDeletedProjects(),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useRestoreProject(options?: UseMutationOptions<number, CustomApiError, number>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>((projectId) => restoreProject(projectId), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (project) => {
      // ✅ update detail view directly
      queryClient.invalidateQueries(['deleted-projects']);
      enqueueSnackbar(t('translation.notification.projectRestoreCompleted'), {
        variant: 'success',
      });
    },
    ...options,
  });
}
