import { NavItem } from './interface';
import { paths } from '../../constants/paths';
import { CompanyType, UserRole } from '../../constants/enum';
import { isAdminRole, isEmployeeRole, isSuperAdmin } from '../../utils/permissions';

const navItems: NavItem[] = [
  { name: 'translation.nav.dashboard', id: 'dashboard', to: paths.DASHBOARD },
];

const adminProjectsItem: NavItem = {
  name: 'translation.nav.projects',
  id: 'projects-menu',
  to: '#',
  children: [
    { name: 'translation.nav.projectList', to: paths.PROJECTS },
    { name: 'translation.nav.uploadList', to: paths.PROJECT_IMPORTS },
  ],
};

const projectsItem: NavItem = {
  name: 'translation.nav.projects',
  id: 'projects-menu',
  to: paths.PROJECTS,
};

const navAdminItems: NavItem[] = [
  {
    name: 'translation.nav.administration',
    id: 'administration-menu',
    to: '#',
    children: [{ name: 'translation.nav.users', to: paths.USERS }],
  },
];

const navSuperAdminItems: NavItem[] = [
  {
    name: 'translation.nav.administration',
    id: 'administration-menu',
    to: '#',
    children: [
      { name: 'translation.nav.users', to: paths.USERS },
      { name: 'translation.nav.assets', to: paths.ASSET_MODELS },
      { name: 'translation.nav.companies', to: paths.COMPANIES },
      { name: 'translation.nav.projectRecovery', to: paths.PROJECT_RECOVERY },
    ],
  },
];

export const getNavItemsForRole = (userType?: CompanyType, userRole?: UserRole) => {
  const items = [...navItems];

  if (!userType || !userRole) {
    return items;
  }

  if (isAdminRole(userRole) || isEmployeeRole(userRole)) {
    items.push(adminProjectsItem);
  } else {
    items.push(projectsItem);
  }

  if (isSuperAdmin(userType, userRole)) {
    items.push(...navSuperAdminItems);
  } else if (isAdminRole(userRole)) {
    items.push(...navAdminItems);
  }

  return items;
};
