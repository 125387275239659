import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  TextField,
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Popper,
  PopperProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useAssetClassifications,
  useCreateAssetManufacturer,
  useUpdateAssetManufacturer,
} from '../../../../hooks';
import { AssetManufacturerData } from '../../../../hooks/assetManufacturers';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

interface AssetManufacturerFormProps {
  assetManufacturer?: AssetManufacturerData;
  handleClose: () => void;
}

const CustomPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      modifiers={[
        {
          name: 'computeStyles',
          enabled: true,
          fn({ state }) {
            state.styles.popper = {
              ...state.styles.popper,
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            };

            return state;
          },
        },
      ]}
    />
  );
};

export const AssetManufacturerForm: FC<AssetManufacturerFormProps> = ({
  handleClose,
  assetManufacturer,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [assetManufacturerData, setAssetManufacturer] = useState({
    name: assetManufacturer?.name || '',
    classifications: assetManufacturer?.classifications ?? [],
  });

  const [submitted, setSubmitted] = useState(false);

  const { data, isLoading } = useAssetClassifications({
    staleTime: 3 * 60 * 1000, // 3 min
    cacheTime: 5 * 60 * 1000, // 5 min
  });

  const createAssetManufacturer = useCreateAssetManufacturer({
    onSuccess: (newAssetManufacturer) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['asset-manufacturers'],
        (oldData: AssetManufacturerData[] | undefined) =>
          oldData ? [...oldData, newAssetManufacturer] : oldData,
      );
      enqueueSnackbar(t('translation.notification.assetManufacturerCreated'), {
        variant: 'success',
      });
      handleClose();
    },
  });
  const updateAssetManufacturer = useUpdateAssetManufacturer({
    onSuccess: (updatedManufacturer) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['asset-manufacturers'],
        (oldData: AssetManufacturerData[] | undefined) => {
          if (oldData) {
            const updatedAssetManufacturers = [...oldData];
            const index = updatedAssetManufacturers.findIndex(
              (manufacturer) => manufacturer.id === updatedManufacturer.id,
            );

            if (index !== -1) {
              updatedAssetManufacturers[index] = {
                ...updatedAssetManufacturers[index],
                ...updatedManufacturer,
              };
            }
            return updatedAssetManufacturers;
          }
          return oldData;
        },
      );
      enqueueSnackbar(t('translation.notification.assetManufacturerUpdated'), {
        variant: 'success',
      });
      handleClose();
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      assetManufacturerData.name &&
      !createAssetManufacturer.isLoading &&
      !updateAssetManufacturer.isLoading
    ) {
      const formattedManufacturerData = {
        name: assetManufacturerData.name,
        classifications: assetManufacturerData.classifications.map((c) => ({ id: c.id })),
      };
      if (assetManufacturer) {
        updateAssetManufacturer.mutate({
          id: assetManufacturer.id,
          data: formattedManufacturerData,
        });
      } else {
        createAssetManufacturer.mutate(formattedManufacturerData);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setAssetManufacturer((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {t(
          `translation.assetModelsListPage.${
            assetManufacturer ? 'edit' : 'create'
          }ManufacturerModal.title`,
        )}
      </DialogTitle>
      <DialogContent>
        <Box component="form" id="assetManufacturer" onSubmit={handleSubmit}>
          <Grid container py={4} my={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('translation.label.manufacturer')}
                name="name"
                value={assetManufacturerData.name}
                error={submitted && !assetManufacturerData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                PopperComponent={
                  assetManufacturerData.classifications.length > 4 ? CustomPopper : undefined
                }
                id="classification"
                loading={isLoading}
                loadingText={t('translation.common.loading')}
                options={data ?? []}
                getOptionLabel={(option) => option.name}
                value={assetManufacturerData.classifications ?? []}
                onChange={(_, value) => {
                  setSubmitted(false);
                  setAssetManufacturer((prevState) => ({
                    ...prevState,
                    classifications: value,
                  }));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('translation.label.classification')}
                    placeholder={t('translation.label.classification')}
                    error={submitted && !assetManufacturerData.classifications}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth: 120 }} color="inherit" variant="text" onClick={handleClose}>
          {t('translation.button.cancel')}
        </Button>
        <LoadingButton
          form="assetManufacturer"
          variant="contained"
          sx={{ minWidth: 120 }}
          type="submit"
          loading={createAssetManufacturer.isLoading || updateAssetManufacturer.isLoading}
        >
          {t('translation.button.save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};
