import { FC, useState } from 'react';
import { MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableActionsMenu, ConfirmationModal } from '../../../../components';
import { paths } from '../../../../constants/paths';
import { useDeleteAssetModel } from '../../../../hooks';

interface AssetModelsActionsColumnProps {
  assetModelId: number;
}

export const AssetModelsActionsColumn: FC<AssetModelsActionsColumnProps> = ({ assetModelId }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useDeleteAssetModel();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableActionsMenu>
      <MenuItem
        sx={{ minWidth: 120, height: 40 }}
        component={Link}
        to={paths.build(paths.ASSET_MODEL_EDIT, assetModelId)}
      >
        {t('translation.button.edit')}
      </MenuItem>
      <MenuItem sx={{ minWidth: 120, height: 40, color: 'error.main' }} onClick={handleClickOpen}>
        {t('translation.button.delete')}
      </MenuItem>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        title="translation.assetModelsListPage.deleteAssetModelModal.title"
        text="translation.assetModelsListPage.deleteAssetModelModal.text"
        confirmActionButton={
          <LoadingButton
            loading={isLoading}
            color="error"
            variant="text"
            onClick={() => {
              if (!isLoading) {
                mutate(assetModelId);
                handleClose();
              }
            }}
          >
            {t('translation.button.delete')}
          </LoadingButton>
        }
      />
    </TableActionsMenu>
  );
};
