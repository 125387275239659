import {
  UseMutationOptions,
  useQueryClient,
  useMutation,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AxiosProgressEvent } from 'axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { FileType } from '../constants/enum';
import axiosInstance from '../config/axios';

export interface WorkOrderDocumentData {
  id: number;
  workOrderId: number;
  fileType: FileType;
  filename: string;
  originalname: string;
  fileUrl: string;
  deletedAt: string | null;
}

export interface WorkOrderDocumentsParams {
  workOrderId: number;
  files: File[];
  progressCallback?: (progressEvent: AxiosProgressEvent) => void;
  onSuccess?: () => void;
}

const getWorkOrderDocuments = async (id: number) => {
  try {
    const { data } = await axiosInstance.get('/work-order/file', {
      params: { workOrderId: id },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const uploadWorkOrderDocuments = async (workOrderImportVariables: WorkOrderDocumentsParams) => {
  try {
    const { files, workOrderId, progressCallback } = workOrderImportVariables;

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    const { data } = await axiosInstance.post(`/work-order/file/${workOrderId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progressCallback && progressCallback(progressEvent);
      },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteWorkOrderDocument = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/work-order/file/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useWorkOrderDocuments(
  id: number,
  options?: UseQueryOptions<WorkOrderDocumentData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['work-order-documents', id],
    queryFn: () => getWorkOrderDocuments(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useUploadWorkOrderDocuments(
  workOrderId: number,
  options?: UseMutationOptions<WorkOrderDocumentData[], CustomApiError, WorkOrderDocumentsParams>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<WorkOrderDocumentData[], CustomApiError, WorkOrderDocumentsParams>(
    (data) => uploadWorkOrderDocuments({ ...data, workOrderId }),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newWorkOrderDocuments) => {
        // ✅ update detail view directly
        queryClient.setQueryData(
          ['work-order-documents', workOrderId],
          (oldData: WorkOrderDocumentData[] | undefined) => {
            if (oldData) {
              return [...oldData, ...newWorkOrderDocuments];
            }
            return newWorkOrderDocuments;
          },
        );
        enqueueSnackbar(t('translation.notification.workOrderDocumentsUploaded'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useDeleteWorkOrderDocument(
  workOrderId: number,
  options?: UseMutationOptions<number, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (workOrderId) => deleteWorkOrderDocument(workOrderId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (id) => {
        queryClient.setQueryData(
          ['work-order-documents', workOrderId],
          (oldData: WorkOrderDocumentData[] | undefined) => {
            if (oldData) {
              const documents = [...oldData];
              const newDocuments = documents.filter((doc) => doc.id !== id);

              return newDocuments;
            }
            return oldData;
          },
        );

        enqueueSnackbar(t('translation.notification.workOrderDocumentDeleted'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}
