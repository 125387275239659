import { Authenticator } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';

// https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1063553150
export const BugWorkaround = () => (
  <Box
    sx={{
      '[data-amplify-authenticator]': {
        display: 'none',
      },
    }}
  >
    <Authenticator />
  </Box>
);
