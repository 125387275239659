import { CompanyType, UserRole } from './enum';

// Combination CompanyType.ADMIN, UserRole.ADMIN is omitted as it has access to everything by default
// Refer to RolesGuard component for more info

export const adminTypeEmployeeRole = { type: CompanyType.ADMIN, roles: [UserRole.EMPLOYEE] };
export const utilityAdminRole = { type: CompanyType.UTILITY, roles: [UserRole.ADMIN] };
export const subcontractorAdminRole = { type: CompanyType.SUBCONTRACTOR, roles: [UserRole.ADMIN] };

export const utilityAdminAndEmployeeRole = {
  type: CompanyType.UTILITY,
  roles: [UserRole.ADMIN, UserRole.EMPLOYEE],
};
export const subcontractorAdminAndEmployeeRole = {
  type: CompanyType.SUBCONTRACTOR,
  roles: [UserRole.ADMIN, UserRole.EMPLOYEE],
};

export const allAdminRoles = [utilityAdminRole, subcontractorAdminRole];

export const allAdminRolesAndAdminTypeEmployee = [
  adminTypeEmployeeRole,
  utilityAdminRole,
  subcontractorAdminRole,
];

export const adminAndUtilityEmployeeRoles = [adminTypeEmployeeRole, utilityAdminAndEmployeeRole];

export const allAdminAndEmployeeRoles = [
  adminTypeEmployeeRole,
  utilityAdminAndEmployeeRole,
  subcontractorAdminAndEmployeeRole,
];
