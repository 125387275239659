import { useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { WorkOrderEditFormProps } from '../interface';
import { useUpdateWorkOrder } from '../../../../hooks';
import { useUserDataContext } from '../../../../containers/Layout/context';
import { isSuperAdmin } from '../../../../utils/permissions';

export const useWorkOrderForm = ({ workOrder, project }: WorkOrderEditFormProps) => {
  const user = useUserDataContext();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    accountNumber: workOrder.accountNumber ?? '',
    accountReadSequence: workOrder.accountReadSequence ?? '',
    address: workOrder.address ?? '',
    latitude: workOrder.latitude ?? '',
    longitude: workOrder.longitude ?? '',
    locationCode: workOrder.locationCode ?? '',
    locationDescription: workOrder.locationDescription ?? '',
    customerName: workOrder.customerName ?? '',
    cityServiceLineMaterial: workOrder.cityServiceLineMaterial ?? null,
    customerServiceLineMaterial: workOrder.customerServiceLineMaterial ?? null,
    zoneId: workOrder.zoneId ?? '',
    companyId: workOrder.company?.id,
    status: workOrder.status ?? '',
    assetNotes: workOrder.assetNotes ?? '',
    installNotes: workOrder.installNotes ?? '',

    newAssetClassification: workOrder.newAssetClassification ?? '',
    installDate: workOrder.installDate ?? null,
    newAssetId: workOrder.newAssetId ?? '',
    newAssetSerialNumber: workOrder.newAssetSerialNumber ?? '',
    newAssetType: workOrder.newAssetType ?? '',
    newAssetSize: workOrder.newAssetSize ?? '',
    newAssetMake: workOrder.newAssetMake ?? '',
    newAssetGroup: workOrder.newAssetGroup ?? '',
    installedBy: workOrder.installedBy || `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
    newAssetLatitude: workOrder.newAssetLatitude ?? '',
    newAssetLongitude: workOrder.newAssetLongitude ?? '',
    newAssetActivation: workOrder.newAssetActivation ?? '',
    newAssetRead: workOrder.newAssetRead ?? '',
    newAssetDemandRead: workOrder.newAssetDemandRead ?? '',
    newAssetDials: workOrder.newAssetDials ?? '',
    newAssetScale: workOrder.newAssetScale ?? '',
    newAssetRateTable: workOrder.newAssetRateTable ?? '',
    boxChangeout: workOrder.boxChangeout ?? '',
    curbStop: workOrder.curbStop ?? '',
    assetSpud: workOrder.assetSpud ?? '',

    assetId: workOrder.assetId ?? '',
    assetSerialNumber: workOrder.assetSerialNumber ?? '',
    assetGroup: workOrder.assetGroup ?? '',
    assetMake: workOrder.assetMake ?? '',
    assetType: workOrder.assetType ?? '',
    assetSize: workOrder.assetSize ?? '',
    assetDials: workOrder.assetDials ?? '',
    assetScale: workOrder.assetScale ?? '',
    assetRateTable: workOrder.assetRateTable ?? '',
    finalRead: workOrder.finalRead ?? '',
    assetLastRead: workOrder.assetLastRead ?? '',
    assetLastReadDate: workOrder.assetLastReadDate ?? null,
    assetInstallDate: workOrder.assetInstallDate ?? null,
  });

  const [serialNumbersMatch, setSerialNumbersMatch] = useState('no');

  const sizeOptions = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '5/8',
      value: '5/8',
    },
    {
      label: '3',
      value: '3',
    },
    {
      label: '4',
      value: '4',
    },
    {
      label: '6',
      value: '6',
    },
    {
      label: '8',
      value: '8',
    },
    {
      label: '3/4',
      value: '3/4',
    },
    {
      label: '5/8x3/4',
      value: '5/8x3/4',
    },
    {
      label: '1 1/2',
      value: '1 1/2',
    },
  ];

  const boxOptions = [
    {
      label: t('translation.label.dugUpBox'),
      value: 'Yes',
    },
    {
      label: t('translation.common.no'),
      value: 'No',
    },
    {
      label: t('translation.label.box1Deep'),
      value: 'Box Reset',
    },
    {
      label: t('translation.label.box2Deep'),
      value: '2 Boxes',
    },
    {
      label: t('translation.label.box3Deep'),
      value: '3 Boxes',
    },
    {
      label: t('translation.label.box4Deep'),
      value: '4 Boxes',
    },
  ];

  const disableSubcontractorChange =
    !isSuperAdmin(user.company.companyType, user.role) && user.company.id !== project.company.id;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateChange = (name: string, newValue: DateTime | null) => {
    if (newValue && newValue.isValid) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue.toISO(),
      }));
    }
  };

  const handleSerialNumbersMatchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumbersMatch(event.target.value);
    if (event.target.value === 'yes') {
      setFormData((prevState) => ({
        ...prevState,
        newAssetSerialNumber: prevState.assetSerialNumber,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        newAssetSerialNumber: '',
      }));
    }
  };

  const handleGetlocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setFormData((prevState) => ({
          ...prevState,
          newAssetLatitude: position.coords.latitude.toString(),
          newAssetLongitude: position.coords.longitude.toString(),
          installTime: new Date().toUTCString(),
        }));
      });
    }
  };

  const { mutate, isLoading } = useUpdateWorkOrder();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isLoading) {
      mutate({ id: workOrder.id, data: formData });
    }
  };

  return {
    formData,
    isLoading,
    disableSubcontractorChange,
    serialNumbersMatch,
    sizeOptions,
    boxOptions,
    setFormData,
    handleChange,
    handleDateChange,
    handleSerialNumbersMatchChange,
    handleGetlocation,
    handleSubmit,
  };
};
