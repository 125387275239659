/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_PROJECT_REGION,
  Auth: {
    region: process.env.REACT_APP_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: process.env.REACT_APP_OAUTH_SCOPE ? process.env.REACT_APP_OAUTH_SCOPE.split(',') : '',
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
      responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
    },
    mandatorySignIn: true,
    federationTarget: process.env.REACT_APP_FEDERATION_TARGET,
  },
};

export default awsmobile;
