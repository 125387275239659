import React, { FC, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  useTheme,
  FormControlLabel,
  Switch,
  Box,
} from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ColorModeContext } from '../../ColorModeProvider/context';
import { ProfileMenuProps } from '../interface';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { paths } from '../../../constants/paths';

export const ProfileMenu: FC<ProfileMenuProps> = ({ anchorRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const queryClient = useQueryClient();
  const colorMode = React.useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    queryClient.removeQueries();
    signOut();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      sx={{ marginTop: 1 }}
      anchorEl={anchorRef.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        sx={{ minWidth: 200, height: 50 }}
        component={Link}
        to={paths.SETTINGS}
        onClick={handleMenuClose}
      >
        <Trans i18nKey="translation.nav.userSettings" />
      </MenuItem>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={theme.palette.mode === 'dark'}
              onChange={colorMode.toggleColorMode}
              name="Dark Mode"
            />
          }
          label={t('translation.nav.darkMode')}
          labelPlacement="start"
        />
      </div>
      <Divider />
      <div>
        <MenuItem
          sx={{
            minWidth: 200,
            height: 50,
            color: 'error.main',
          }}
          onClick={handleSignOut}
        >
          <Trans i18nKey="translation.nav.logout" />
        </MenuItem>
      </div>
    </Menu>
  );

  return (
    <Box>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircleOutlined fontSize="large" />
      </IconButton>
      {renderMenu}
    </Box>
  );
};
