import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../../constants/paths';
import { CompanySummaryData } from '../../../../hooks/company';
import { useCreateProject } from '../../../../hooks';
import { ProjectInput, useAddProjectImage } from '../../../../hooks/projects';
import { AxiosProgressEvent } from 'axios';

interface ProjectCreateFormProps {
  utilities: CompanySummaryData[];
  subcontractors: CompanySummaryData[];
  projectImportId: number;
}

interface ProjectCreateData {
  name: string;
  companyId: number | undefined;
  startDate: string;
  dueDate: string;
  subcontractors: CompanySummaryData[];
  isLoyalty: boolean;
}

export const ProjectCreateForm: FC<ProjectCreateFormProps> = ({
  projectImportId,
  utilities,
  subcontractors,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [projectData, setProject] = useState<ProjectCreateData>({
    name: '',
    companyId: undefined,
    startDate: '',
    dueDate: '',
    subcontractors: [],
    isLoyalty: false,
  });

  const [photo, setPhoto] = useState<File>();
  const [submitted, setSubmitted] = useState(false);

  const [progress, setProgress] = useState(0);

  const progressCallback = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = Math.round(percentComplete * 100);
      setProgress(percentComplete);
    }
  };

  const { mutate, isLoading } = useCreateProject({
    onSuccess: (newProject) => {
      if (photo) {
        uploadImage({ projectId: newProject.id, file: photo, progressCallback });
      } else {
        navigate({
          pathname: paths.build(paths.PROJECT_FINALIZE, projectImportId),
          search: location.state || '',
        });
      }
    },
  });
  const { mutate: uploadImage, isLoading: isImageUploading } = useAddProjectImage({
    onSuccess: () => {
      navigate({
        pathname: paths.build(paths.PROJECT_FINALIZE, projectImportId),
        search: location.state || '',
      });
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (projectData.name && projectData.companyId && projectData.subcontractors && !isLoading) {
      const formattedProjectDate = {
        ...projectData,
        projectImportId,
        subcontractors: projectData.subcontractors.map((subc) => ({ id: subc.id })),
      } as ProjectInput;
      mutate(formattedProjectDate);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setProject((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.name')}
            name="name"
            value={projectData.name}
            error={submitted && !projectData.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <Autocomplete
            sx={{ my: 1 }}
            fullWidth
            disablePortal
            id="client"
            options={utilities}
            getOptionLabel={(option) => option.name}
            value={utilities.find((m) => m.id === projectData.companyId) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translation.label.client')}
                error={submitted && !projectData.companyId}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            onChange={(_, value) => {
              setSubmitted(false);
              setProject((prevState) => ({
                ...prevState,
                companyId: value?.id,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              sx={{ width: '100%', my: 1 }}
              label={t('translation.label.startDate')}
              value={projectData.startDate}
              onChange={(newValue) => {
                if (newValue) {
                  setSubmitted(false);
                  if (DateTime.fromISO(newValue).isValid) {
                    setProject((prevState) => ({
                      ...prevState,
                      startDate: newValue,
                    }));
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              sx={{ width: '100%', my: 1 }}
              label={t('translation.label.dueDate')}
              value={projectData.dueDate}
              onChange={(newValue) => {
                if (newValue) {
                  setSubmitted(false);
                  if (DateTime.fromISO(newValue).isValid) {
                    setProject((prevState) => ({
                      ...prevState,
                      dueDate: newValue,
                    }));
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            type="file"
            label={t('translation.label.projectPhoto')}
            name="photo"
            inputProps={{
              accept: 'image/*',
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.files) {
                setPhoto(event.target.files[0]);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <Autocomplete
            sx={{ my: 1 }}
            fullWidth
            disablePortal
            multiple
            id="subcontractors"
            options={subcontractors}
            getOptionLabel={(option) => option.name}
            value={projectData.subcontractors || []}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translation.label.subcontractor')}
                error={submitted && !projectData.subcontractors}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            onChange={(_, value) => {
              setSubmitted(false);
              setProject((prevState) => ({
                ...prevState,
                subcontractors: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label={t('translation.label.isLoyalty')}
              name="isLoyalty"
              checked={projectData.isLoyalty}
              onChange={(_, checked) => {
                setSubmitted(false);
                setProject((prevState) => ({
                  ...prevState,
                  isLoyalty: checked,
                }));
              }}
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
        >
          <Button
            color="inherit"
            variant="text"
            sx={{ minWidth: 120, mr: 2 }}
            component={Link}
            to={{ pathname: paths.PROJECT_IMPORTS, search: location.state || '' }}
          >
            {t('translation.button.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 120 }}
            type="submit"
            loading={isLoading || isImageUploading}
            loadingPosition={photo && (isLoading || isImageUploading) ? 'start' : undefined}
          >
            {photo && (isLoading || isImageUploading)
              ? `${progress}%`
              : t('translation.button.next')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
