import { FC } from 'react';
import { Grid, Container, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MUIDataTableColumn } from 'mui-datatables';
import { DateTime } from 'luxon';
import { useDeletedProjects } from '../../hooks';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../constants/date';
import { DataTable } from '../../components';
import { RestoreButtonColumn } from './components/RestoreButtonColumn';

export const ProjectRecovery: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useDeletedProjects();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px', cursor: 'pointer' } }),
      },
    },
    {
      label: t('translation.label.projectName'),
      name: 'name',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
      },
    },
    {
      label: t('translation.label.company'),
      name: 'company.name',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
      },
    },
    {
      label: t('translation.label.startDate'),
      name: 'startDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
      },
    },
    {
      label: t('translation.label.dueDate'),
      name: 'dueDate',
      options: {
        setCellProps: () => ({ style: { minWidth: '170px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_FORMAT);
          return <span>{dateTime}</span>;
        },
      },
    },
    {
      label: t('translation.label.deletedAt'),
      name: 'deletedAt',
      options: {
        setCellProps: () => ({ style: { minWidth: '225px', cursor: 'pointer' } }),
        customBodyRender: (value) => {
          const dateTime = DateTime.fromISO(value).toFormat(DATE_TIME_FORMAT);
          return <span>{dateTime}</span>;
        },
      },
    },
    {
      label: '',
      name: '',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '120px' } }),
        customBodyRenderLite: (dataIndex) => {
          const id = data?.[dataIndex].id;
          if (id) {
            return <RestoreButtonColumn projectId={id} />;
          }
          return null;
        },
      },
    },
  ];

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12}>
            <DataTable
              data={data ?? []}
              columns={columns}
              count={undefined}
              isLoading={isLoading}
              isFetching={isFetching}
              title="translation.projectRecoveryPage.title"
              options={{
                filter: false,
                serverSide: false,
                onTableChange: undefined,
                sortOrder: {
                  name: 'deletedAt',
                  direction: 'desc',
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
