import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetDashboardProjects,
  useGetWorkOrdersSummary,
  useGetWorkOrdersMapData,
} from '../../../hooks';
import { WorkOrdersMapData, WorkOrdersSummaryData } from '../../../hooks/workOrders';
import { WorkOrderStatus } from '../../../constants/enum';
import { WorkOrdersMapDataEnhanced } from '../interface';

export const useDashboard = () => {
  const { t } = useTranslation();

  const [projectFilter, setProjectFilter] = useState<number[]>([]);
  const [statusFilter, setStatusFilter] = useState({
    assigned: false,
    completed: false,
    rtu: false,
    unable: false,
    unassigned: false,
  });

  const [startDateStart, setStartDateStart] = useState<string | null>(null);
  const [startDateEnd, setStartDateEnd] = useState<string | null>(null);

  const {
    data: projectData,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
  } = useGetDashboardProjects({
    startDate: startDateStart || undefined,
    endDate: startDateEnd || undefined,
  });

  const { data, isLoading } = useGetWorkOrdersSummary({
    projectId: projectFilter.length > 0 ? projectFilter : undefined,
    startDate: startDateStart || undefined,
    endDate: startDateEnd || undefined,
  });

  const { data: workOrderData, isLoading: isWorkOrdersLoading } = useGetWorkOrdersMapData();

  const generatePieChartData = useCallback(() => {
    const pieData: Array<{
      name: string;
      value: number | undefined;
    }> = [];

    const entries = Object.entries(statusFilter);

    if (!entries.some(([_, value]) => value)) {
      pieData.push(
        { name: t('translation.enum.ASSIGNED').toUpperCase(), value: data?.assigned },
        {
          name: t('translation.enum.COMPLETED').toUpperCase(),
          value: (data?.completed || 0) + (data?.returned || 0),
        },
        { name: t('translation.enum.RTU').toUpperCase(), value: data?.rtu },
        { name: t('translation.enum.UNABLE').toUpperCase(), value: data?.unable },
        { name: t('translation.enum.UNASSIGNED').toUpperCase(), value: data?.unassigned },
      );
    } else {
      entries.forEach(([key, value]) => {
        if (value) {
          pieData.push({
            name: t(`translation.enum.${key.toUpperCase()}`).toUpperCase(),
            value: data?.[key as keyof WorkOrdersSummaryData],
          });
        }
      });
    }

    return pieData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, statusFilter]);

  const getTotal = () => {
    if (!data) return null;

    const entries = Object.entries(statusFilter);

    if (!entries.some(([_, value]) => value)) {
      return data?.total;
    }

    let total = 0;
    entries.forEach(([key, value]) => {
      if (value) {
        total += data?.[key as keyof WorkOrdersSummaryData];
      }
    });

    return total;
  };

  const projects = useMemo(() => projectData ?? [], [projectData]);
  const workOrders: WorkOrdersMapDataEnhanced[] = useMemo(() => {
    const statuses = [
      statusFilter.assigned,
      statusFilter.completed,
      statusFilter.rtu,
      statusFilter.unable,
    ];

    if (!workOrderData) {
      return [];
    }

    let filteredWorkOrders: WorkOrdersMapData[] = [];

    if (statuses.filter(Boolean).length < 1) {
      filteredWorkOrders.push(...workOrderData);
    } else {
      if (statusFilter.assigned) {
        filteredWorkOrders.push(
          ...workOrderData.filter((wo) => wo.status === WorkOrderStatus.ASSIGNED),
        );
      }

      if (statusFilter.completed) {
        filteredWorkOrders.push(
          ...workOrderData.filter((wo) =>
            [WorkOrderStatus.COMPLETED, WorkOrderStatus.RETURNED].includes(wo.status),
          ),
        );
      }

      if (statusFilter.rtu) {
        filteredWorkOrders.push(...workOrderData.filter((wo) => wo.status === WorkOrderStatus.RTU));
      }

      if (statusFilter.unable) {
        filteredWorkOrders.push(
          ...workOrderData.filter((wo) => wo.status === WorkOrderStatus.UNABLE),
        );
      }
    }

    if (projectFilter.length > 0) {
      filteredWorkOrders = filteredWorkOrders.filter((wo) => projectFilter.includes(wo.projectId));
    } else {
      filteredWorkOrders = filteredWorkOrders.filter((wo) =>
        projects.some((project) => project.id === wo.projectId),
      );
    }

    return filteredWorkOrders.map((wo) => {
      const enchancedWo: WorkOrdersMapDataEnhanced = {
        ...wo,
        projectName: projects.find((project) => project.id === wo.projectId)!.name,
      };
      return enchancedWo;
    });
  }, [
    workOrderData,
    statusFilter.assigned,
    statusFilter.completed,
    statusFilter.rtu,
    statusFilter.unable,
    projectFilter,
    projects,
  ]);

  return {
    projects,
    isProjectsLoading,
    isProjectsFetching,
    workOrders,
    isWorkOrdersLoading,
    isLoading,
    statusFilter,
    projectFilter,
    startDateStart,
    startDateEnd,
    setStartDateEnd,
    setStartDateStart,
    setStatusFilter,
    setProjectFilter,
    generatePieChartData,
    getTotal,
  };
};
