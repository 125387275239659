import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  TextField,
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateAssetSize, useUpdateAssetSize } from '../../../../hooks';
import { AssetSizeData, AssetSizeInput } from '../../../../hooks/assetSizes';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

interface AssetSizeFormProps {
  assetSize?: AssetSizeData;
  handleClose: () => void;
}

export const AssetSizeForm: FC<AssetSizeFormProps> = ({ handleClose, assetSize }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [assetSizeData, setAssetSize] = useState<AssetSizeInput>({
    name: assetSize?.name || '',
  });

  const [submitted, setSubmitted] = useState(false);

  const createAssetSize = useCreateAssetSize({
    onSuccess: (newAssetSize) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['asset-sizes'], (oldData: AssetSizeData[] | undefined) =>
        oldData ? [...oldData, newAssetSize] : oldData,
      );
      enqueueSnackbar(t('translation.notification.assetSizeCreated'), {
        variant: 'success',
      });
      handleClose();
    },
  });
  const updateAssetSize = useUpdateAssetSize({
    onSuccess: (updatedSize) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['asset-sizes'], (oldData: AssetSizeData[] | undefined) => {
        if (oldData) {
          const updatedAssetSizes = [...oldData];
          const index = updatedAssetSizes.findIndex((size) => size.id === updatedSize.id);

          if (index !== -1) {
            updatedAssetSizes[index] = {
              ...updatedAssetSizes[index],
              ...updatedSize,
            };
          }
          return updatedAssetSizes;
        }
        return oldData;
      });
      enqueueSnackbar(t('translation.notification.assetSizeUpdated'), {
        variant: 'success',
      });
      handleClose();
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (assetSizeData.name && !createAssetSize.isLoading && !updateAssetSize.isLoading) {
      if (assetSize) {
        updateAssetSize.mutate({
          id: assetSize.id,
          data: assetSizeData,
        });
      } else {
        createAssetSize.mutate(assetSizeData);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setAssetSize((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {t(`translation.assetModelsListPage.${assetSize ? 'edit' : 'create'}SizeModal.title`)}
      </DialogTitle>
      <DialogContent sx={{ width: 600, maxWidth: '100%' }}>
        <Box component="form" id="assetSize" onSubmit={handleSubmit}>
          <Grid container py={4}>
            <Grid item xs={12} sm={7}>
              <TextField
                sx={{ my: 1 }}
                fullWidth
                label={t('translation.label.size')}
                name="name"
                value={assetSizeData.name}
                error={submitted && !assetSizeData.name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth: 120 }} color="inherit" variant="text" onClick={handleClose}>
          {t('translation.button.cancel')}
        </Button>
        <LoadingButton
          form="assetSize"
          variant="contained"
          sx={{ minWidth: 120 }}
          type="submit"
          loading={createAssetSize.isLoading || updateAssetSize.isLoading}
        >
          {t('translation.button.save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};
