import { Grid, Typography, Container, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useSingleCompany } from '../../../hooks';
import { CompanyForm } from './components/CompanyForm';
import { paths } from '../../../constants/paths';

export const CreateCompany = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();

  const { data, isInitialLoading } = useSingleCompany(Number(companyId));

  if (isInitialLoading) {
    return null;
  }

  if (companyId && isNaN(Number(companyId))) {
    return <Navigate to={paths.COMPANIES} />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.createCompanyPage.title')}</Typography>
          </Grid>
        </Grid>
        <CompanyForm company={data} />
      </Paper>
    </Container>
  );
};
