import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { paths } from '../../constants/paths';

export const AuthFlow = () => {
  const location = useLocation();
  const { route, authStatus, skipVerification } = useAuthenticator();

  if (route === 'authenticated') {
    return <Navigate to={paths.DASHBOARD} />;
  }

  if (authStatus === 'configuring' && route === 'idle') {
    return null;
  }

  if (route === 'verifyUser' || route === 'confirmVerifyUser') {
    skipVerification();
    return null;
  }

  if (route === 'confirmResetPassword' && location.pathname !== paths.RESET_PASSWORD) {
    return <Navigate to={paths.RESET_PASSWORD} />;
  }

  if (route === 'forceNewPassword' && location.pathname !== paths.CHANGE_PASSWORD) {
    return <Navigate to={paths.CHANGE_PASSWORD} />;
  }

  if (route === 'resetPassword' && location.pathname !== paths.FORGOT_PASSWORD) {
    return <Navigate to={paths.FORGOT_PASSWORD} />;
  }

  if (
    !['resetPassword', 'confirmResetPassword', 'forceNewPassword'].includes(route) &&
    location.pathname !== paths.SIGN_IN
  ) {
    return <Navigate to={paths.SIGN_IN} />;
  }

  return <Outlet />;
};
