import { FC } from 'react';
import { Container, Paper, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useSingleProject, useSingleWorkOrder } from '../../../hooks';
import { paths } from '../../../constants/paths';
import { WorkOrderEditMobileForm } from './components/WorkOrderEditMobileForm';
import { WorkOrderEditForm } from './components/WorkOrderEditForm';

export const WorkOrderEdit: FC = () => {
  const { t } = useTranslation();
  const { projectId, workOrderId } = useParams();

  const { data: workOrder, isLoading } = useSingleWorkOrder(Number(workOrderId));
  const { data: project, isLoading: isProjectLoading } = useSingleProject(Number(projectId));

  if (isNaN(Number(workOrderId))) {
    if (!projectId || isNaN(Number(projectId))) {
      return <Navigate to={paths.PROJECTS} />;
    }
    return <Navigate to={paths.build(paths.PROJECT_WORK_ORDERS, projectId, workOrderId)} />;
  }

  if (isLoading || isProjectLoading || !workOrder || !project) {
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography variant="h6">
              {t('translation.workOrderEditPage.title')}{' '}
              <Typography component="span" variant="h6" fontWeight="bold">
                {workOrder.id}
              </Typography>
            </Typography>
          </Grid>

          <WorkOrderEditForm workOrder={workOrder} project={project} />

          <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
            <WorkOrderEditMobileForm workOrder={workOrder} project={project} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
