import { Grid, Typography, Container, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useAssetManufacturers, useAssetSizes, useSingleAssetModel } from '../../../hooks';
import { AssetModelForm } from './components/AssetModelForm';
import { paths } from '../../../constants/paths';

export const CreateAssetModel = () => {
  const { t } = useTranslation();
  const { assetModelId } = useParams();

  const { data, isInitialLoading } = useSingleAssetModel(Number(assetModelId));
  const { data: manufacturers, isLoading: isManufacturersLoading } = useAssetManufacturers();
  const { data: sizes, isLoading: isSizesLoading } = useAssetSizes();

  if (isInitialLoading || isManufacturersLoading || isSizesLoading) {
    return null;
  }

  if (assetModelId && isNaN(Number(assetModelId))) {
    return <Navigate to={paths.ASSET_MODELS} />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.createAssetModelPage.title')}</Typography>
          </Grid>
        </Grid>
        <AssetModelForm assetModel={data} manufacturers={manufacturers || []} sizes={sizes || []} />
      </Paper>
    </Container>
  );
};
