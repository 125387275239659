import {
  UseQueryOptions,
  useQuery,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { AssetModelData } from './assetModels';

export interface ReplacementGroupData {
  assetModels: AssetModelData[];
  id: number;
  name: string;
  notes: string;
  projectId: number;
  deletedAt?: string;
}

export interface ReplacementGroupInput {
  assetModels: Array<{ id: number }>;
  name: string;
  notes: string;
  projectId: number;
}

const getReplacementGroups = async (projectId?: number) => {
  try {
    const { data } = await axiosInstance.get('/replacement-group', {
      params: { where: { projectId } },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createReplacementGroup = async (replacementGroupData: ReplacementGroupInput) => {
  try {
    const { data } = await axiosInstance.post(`/replacement-group`, replacementGroupData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateReplacementGroup = async (id: number, replacementGroupData: ReplacementGroupInput) => {
  try {
    const { data } = await axiosInstance.put(`/replacement-group/${id}`, replacementGroupData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteReplacementGroup = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/replacement-group/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useReplacementGroups(
  projectId: number,
  options?: UseQueryOptions<ReplacementGroupData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['replacement-groups', projectId],
    queryFn: () => getReplacementGroups(projectId),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    enabled: !!projectId,
    ...options,
  });
}

export function useCreateReplacementGroup(
  options?: UseMutationOptions<ReplacementGroupData, CustomApiError, ReplacementGroupInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<ReplacementGroupData, CustomApiError, ReplacementGroupInput>(
    (data) => createReplacementGroup(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newReplacementGroup) => {
        // ✅ update detail view directly
        queryClient.setQueryData(
          ['replacement-groups', newReplacementGroup.projectId],
          (oldData: ReplacementGroupData[] | undefined) =>
            oldData ? [...oldData, newReplacementGroup] : oldData,
        );
        enqueueSnackbar(t('translation.notification.replacementGroupCreated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useUpdateReplacementGroup(
  options?: UseMutationOptions<
    ReplacementGroupData,
    CustomApiError,
    { id: number; data: ReplacementGroupInput }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<
    ReplacementGroupData,
    CustomApiError,
    { id: number; data: ReplacementGroupInput }
  >(({ id, data }) => updateReplacementGroup(id, data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (updatedReplacementGroup) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['replacement-groups', updatedReplacementGroup.projectId],
        (oldData: ReplacementGroupData[] | undefined) => {
          if (oldData) {
            const updatedReplacementGroups = [...oldData];
            const index = updatedReplacementGroups.findIndex(
              (group) => group.id === updatedReplacementGroup.id,
            );

            if (index !== -1) {
              updatedReplacementGroups[index] = {
                ...updatedReplacementGroups[index],
                ...updatedReplacementGroup,
              };
            }
            return updatedReplacementGroups;
          }
          return oldData;
        },
      );
      queryClient.invalidateQueries({ queryKey: ['project-assets'] });
      enqueueSnackbar(t('translation.notification.replacementGroupUpdated'), {
        variant: 'success',
      });
    },
    ...options,
  });
}

export function useDeleteReplacementGroup(
  options?: UseMutationOptions<number, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (replacementGroupId) => deleteReplacementGroup(replacementGroupId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar(t('translation.notification.replacementGroupDeleted'), {
          variant: 'success',
        });
        queryClient.invalidateQueries({ queryKey: ['replacement-groups'] });
      },
      ...options,
    },
  );
}
