import { FC, useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { TableActionsMenu, ConfirmationModal } from '../../../../components';
import {
  useDeleteAssetClassification,
  useDeleteAssetManufacturer,
  useDeleteAssetSize,
} from '../../../../hooks';
import { AssetClassificationData } from '../../../../hooks/assetClassifications';
import { AssetSizeData } from '../../../../hooks/assetSizes';
import { AssetManufacturerData } from '../../../../hooks/assetManufacturers';

interface ComponentsActionsColumnProps {
  type: 'Classification' | 'Manufacturer' | 'Size';
  data: AssetClassificationData | AssetSizeData | AssetManufacturerData;
  handleOpenEdit: (
    currentType: 'Classification' | 'Manufacturer' | 'Size',
    data: AssetClassificationData | AssetSizeData | AssetManufacturerData,
  ) => void;
}

export const ComponentsActionsColumn: FC<ComponentsActionsColumnProps> = ({
  type,
  data,
  handleOpenEdit,
}) => {
  const { t } = useTranslation();

  const assetClassification = useDeleteAssetClassification();
  const assetManufacturer = useDeleteAssetManufacturer();
  const assetSize = useDeleteAssetSize();

  const [open, setOpen] = useState(false);

  const handleOpenDelete = () => {
    setOpen(true);
  };

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <TableActionsMenu>
        <MenuItem sx={{ minWidth: 120, height: 40 }} onClick={() => handleOpenEdit(type, data)}>
          {t('translation.button.edit')}
        </MenuItem>
        <MenuItem
          sx={{ minWidth: 120, height: 40, color: 'error.main' }}
          onClick={handleOpenDelete}
        >
          {t('translation.button.delete')}
        </MenuItem>
        <ConfirmationModal
          open={open}
          handleClose={handleCloseDelete}
          title={`translation.assetModelsListPage.delete${type}Modal.title`}
          text={`translation.assetModelsListPage.delete${type}Modal.text`}
          confirmActionButton={
            <LoadingButton
              loading={
                assetClassification.isLoading || assetManufacturer.isLoading || assetSize.isLoading
              }
              color="error"
              variant="text"
              onClick={() => {
                if (
                  !assetClassification.isLoading &&
                  !assetManufacturer.isLoading &&
                  !assetSize.isLoading
                ) {
                  switch (type) {
                    case 'Classification':
                      assetClassification.mutate(data.id);
                      break;
                    case 'Manufacturer':
                      assetManufacturer.mutate(data.id);
                      break;
                    case 'Size':
                      assetSize.mutate(data.id);
                      break;
                    default:
                      break;
                  }
                  handleCloseDelete();
                }
              }}
            >
              {t('translation.button.delete')}
            </LoadingButton>
          }
        />
      </TableActionsMenu>
    </Box>
  );
};
