import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import axiosInstance from '../config/axios';
import { errorHandling, CustomApiError, getErrorDisplayMessage } from '../utils/errors';
import { ServiceLineMaterial, WorkOrderStatus } from '../constants/enum';
import { GetManyQueryParams, PaginatedApiResponse } from '../interfaces';
import { useFilterParams } from './filterParams';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { paths } from '../constants/paths';
import { CompanyData } from './company';
import { ReplacementGroupData } from './replacementGroups';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { ProjectData } from './projects';

export interface WorkOrdersSummaryData {
  total: number;
  completed: number;
  assigned: number;
  inProgress: number;
  unable: number;
  rtu: number;
  unassigned: number;
  pendingReturn: number;
  returned: number;
}

export interface WorkOrdersMapData {
  id: number;
  status: WorkOrderStatus;
  latitude: number;
  longitude: number;
  projectId: number;
}

export interface WorkOrdersParams {
  projectId: number;
  zoneId?: string;
  companyId?: number;
}

export interface WorkOrdersStatusChange {
  workOrders: number[];
  status: WorkOrderStatus;
}

export interface WorkOrdersSummaryParams {
  startDate?: string;
  endDate?: string;
  projectId?: number[];
  zoneId?: string;
  companyId?: number;
}

export interface WorkOrderData {
  id: number;
  version: number;
  projectId: number;
  project: ProjectData | undefined;
  projectImportId: number;
  company: CompanyData | undefined;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  assignedDate: string;
  lastStatusChangeDate: string;
  replacementGroup: ReplacementGroupData;
  status: WorkOrderStatus;
  accountNumber: string;
  accountReadSequence: string;
  address: string;
  longitude: string;
  latitude: string;
  locationDescription: string;
  locationCode: string;
  customerName: string;
  assetId: string;
  assetSerialNumber: string;
  assetGroup: string;
  assetMake: string;
  assetType: string;
  assetSize: string;
  assetDials: string;
  assetScale: string;
  assetMxuId: string;
  assetMxuType: string;
  assetNotes: string;
  assetRateTable: string;
  assetLastRead: string;
  assetLastReadDate: string;
  assetInstallDate: string;
  zoneId: string;
  finalRead: string;
  finalDemandRead: string;
  newAssetId: string;
  newAssetLongitude: string;
  newAssetLatitude: string;
  newAssetSerialNumber: string;
  newAssetClassification: string;
  newAssetGroup: string;
  newAssetMake: string;
  newAssetType: string;
  newAssetSize: string;
  newAssetDials: string;
  newAssetScale: string;
  newAssetMxuId: string;
  newAssetMxuType: string;
  newAssetRead: string;
  newAssetDemandRead: string;
  newAssetRateTable: string;
  newAssetActivation: string;
  installedBy: string;
  installDate: string;
  installTime: string;
  installNotes: string;
  serviceStatusPostSwap: string;
  serviceStatusPreSwap: string;
  deletedAt: string;
  oldAssetEIDNumber: string;
  oldAssetReturnDate: string;
  boxChangeout: string;
  curbStop: string;
  assetSpud: string;
  cityServiceLineMaterial: ServiceLineMaterial;
  customerServiceLineMaterial: ServiceLineMaterial;
}

export interface WorkOrderInput
  extends Partial<
    Omit<
      WorkOrderData,
      | 'id'
      | 'projectId'
      | 'projectImportId'
      | 'createdDate'
      | 'assignedDate'
      | 'lastStatusChangeDate'
      | 'replacementGroup'
      | 'replacementGroupId'
      | 'company'
      | 'project'
      | 'deletedAt'
      | 'createdBy'
      | 'updatedBy'
      | 'updatedDate'
      | 'version'
    >
  > {
  companyId?: number;
}

const getWorkOrders = async (extraParams: WorkOrdersParams, params?: GetManyQueryParams) => {
  try {
    const { data } = await axiosInstance.get('/work-order', {
      params: {
        ...params,
        where: {
          ...params?.where,
          ...extraParams,
        },
      },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getWorkOrdersSummary = async (params?: WorkOrdersSummaryParams) => {
  try {
    const { data } = await axiosInstance.get('/work-order/summary', {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getWorkOrdersMapData = async (projectId?: number) => {
  try {
    const { data } = await axiosInstance.get('/work-order/map', {
      params: projectId ? { projectId } : {},
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getSingleWorkOrder = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/work-order/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateWorkOrderStatus = async (params: WorkOrdersStatusChange) => {
  try {
    const response: AxiosResponse<WorkOrderData>[] = await Promise.all(
      params.workOrders.map((id) => {
        return axiosInstance.put(`/work-order/${id}`, {
          status: params.status,
        });
      }),
    );
    return response.map((res) => res.data);
  } catch (error) {
    errorHandling(error);
    return undefined;
  }
};

const updateWorkOrder = async (id: number, workOrderData: WorkOrderInput) => {
  try {
    const { data } = await axiosInstance.put(`/work-order/${id}`, workOrderData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteWorkOrder = async (workOrders: number[]) => {
  try {
    const response: AxiosResponse[] = await Promise.all(
      workOrders.map((id) => {
        return axiosInstance.delete(`/work-order/${id}`);
      }),
    );
    return response.map((res) => res.data);
  } catch (error) {
    errorHandling(error);
    return undefined;
  }
};

export function useWorkOrders(
  params: WorkOrdersParams,
  options?: UseQueryOptions<PaginatedApiResponse<WorkOrderData>, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useQuery({
    queryKey: ['work-orders', ...Object.values(params), ...parsedParams],
    queryFn: () => getWorkOrders(params, searchParams),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useGetWorkOrdersSummary(
  params?: WorkOrdersSummaryParams,
  options?: UseQueryOptions<WorkOrdersSummaryData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: params
      ? ['work-orders', 'summary', ...Object.entries(params)]
      : ['work-orders', 'summary'],
    queryFn: () => getWorkOrdersSummary(params),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useGetWorkOrdersMapData(
  params?: WorkOrdersSummaryParams,
  options?: UseQueryOptions<WorkOrdersMapData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: params ? ['work-orders', 'map', ...Object.entries(params)] : ['work-orders', 'map'],
    queryFn: () => getWorkOrdersMapData(params?.projectId?.[0]),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useSingleWorkOrder(
  id: number,
  options?: UseQueryOptions<WorkOrderData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['work-orders', id],
    queryFn: () => getSingleWorkOrder(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      if (projectId) {
        navigate(paths.build(paths.PROJECT_WORK_ORDERS, projectId));
      } else {
        navigate(paths.PROJECT_WORK_ORDERS);
      }
    },
    ...options,
    enabled: !!id,
  });
}

export function useBatchWorkOrdersStatusChange(
  queryParams: WorkOrdersParams,
  options?: UseMutationOptions<WorkOrderData[] | undefined, CustomApiError, WorkOrdersStatusChange>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useMutation<WorkOrderData[] | undefined, CustomApiError, WorkOrdersStatusChange>(
    (params) => updateWorkOrderStatus(params),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (updatedWorkOrders) => {
        // ✅ update detail view directly
        queryClient.setQueryData(
          ['work-orders', ...Object.values(queryParams), ...parsedParams],
          (oldData: PaginatedApiResponse<WorkOrderData> | undefined) => {
            if (oldData && oldData.data && updatedWorkOrders) {
              const newData = oldData.data.map((data) => {
                const newWorkOrder = updatedWorkOrders.find((item) => item.id === data.id);
                return newWorkOrder || data;
              });
              return { ...oldData, data: newData };
            }
            return oldData;
          },
        );
        enqueueSnackbar(t('translation.notification.workOrdersUpdated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useDeleteWorkOrder(
  queryParams: WorkOrdersParams,
  options?: UseMutationOptions<number[] | undefined, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useMutation<number[] | undefined, CustomApiError, number>(
    (workOrderId) => deleteWorkOrder([workOrderId]),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (workOrderId) => {
        queryClient.setQueryData(
          ['work-orders', ...Object.values(queryParams), ...parsedParams],
          (oldData: PaginatedApiResponse<WorkOrderData> | undefined) => {
            if (oldData && oldData.data && workOrderId) {
              const newData = oldData.data.filter((data) => data.id !== workOrderId[0]);
              return { ...oldData, data: newData };
            }
            return oldData;
          },
        );
        enqueueSnackbar(t('translation.notification.workOrderDeleted'), {
          variant: 'success',
        });
        if (
          !matchPath(
            { path: paths.build(paths.PROJECT_WORK_ORDERS, queryParams.projectId) },
            location.pathname,
          )
        ) {
          navigate(paths.build(paths.PROJECT_WORK_ORDERS, queryParams.projectId));
        }
      },
      ...options,
    },
  );
}

export function useUpdateWorkOrder(
  options?: UseMutationOptions<WorkOrderData, CustomApiError, { id: number; data: WorkOrderInput }>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation<WorkOrderData, CustomApiError, { id: number; data: WorkOrderInput }>(
    ({ id, data }) => updateWorkOrder(id, data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (updatedWorkOrder) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['work-orders', updatedWorkOrder.id], updatedWorkOrder);
        enqueueSnackbar(t('translation.notification.workOrderUpdated'), {
          variant: 'success',
        });
        navigate({
          pathname: paths.build(
            paths.WORK_ORDER_DETAILS,
            updatedWorkOrder.projectId,
            updatedWorkOrder.id,
          ),
        });
      },
      ...options,
    },
  );
}

export function useDeleteWorkOrdersBatch(
  queryParams: WorkOrdersParams,
  options?: UseMutationOptions<number[] | undefined, CustomApiError, number[]>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useMutation<number[] | undefined, CustomApiError, number[]>(
    (workOrderIds) => deleteWorkOrder(workOrderIds),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (workOrderIds) => {
        queryClient.setQueryData(
          ['work-orders', ...Object.values(queryParams), ...parsedParams],
          (oldData: PaginatedApiResponse<WorkOrderData> | undefined) => {
            if (oldData && oldData.data && workOrderIds) {
              const newData = oldData.data.filter((data) => !workOrderIds.includes(data.id));
              return { ...oldData, data: newData };
            }
            return oldData;
          },
        );
        enqueueSnackbar(t('translation.notification.workOrdersDeleted'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}
