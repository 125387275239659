import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectDetailsContext } from './useProjectDetailsContext';
import { useBatchWorkOrdersStatusChange, useDeleteWorkOrdersBatch } from '../../../../hooks';
import { WorkOrderStatus } from '../../../../constants/enum';

export const useProjectWorkOrders = () => {
  const { projectId } = useParams();
  const {
    workOrders,
    isWorkOrdersLoading,
    isWorkOrdersFetching,
    zoneId,
    companyId,
    workOrderMapData,
  } = useProjectDetailsContext();

  const { mutateAsync: deleteWorkOrders, isLoading: isDeleting } = useDeleteWorkOrdersBatch({
    projectId: Number(projectId),
    zoneId,
    companyId,
  });

  const { mutateAsync: updateWorkOrders, isLoading: isUpdating } = useBatchWorkOrdersStatusChange({
    projectId: Number(projectId),
    zoneId,
    companyId,
  });

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [status, setStatus] = useState<WorkOrderStatus | undefined>();

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleUpdateOpen = () => {
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => {
    setStatus(undefined);
    setUpdateOpen(false);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as WorkOrderStatus);
  };

  const handleStatusChangeSubmit = async (workOrders: number[]) => {
    if (status && !isUpdating) {
      await updateWorkOrders({ workOrders, status });
    }
  };

  return {
    projectId: Number(projectId),
    workOrders,
    isWorkOrdersLoading,
    isWorkOrdersFetching,
    isDeleting,
    deleteWorkOrders,
    deleteOpen,
    handleDeleteOpen,
    handleDeleteClose,
    updateWorkOrders,
    isUpdating,
    handleUpdateOpen,
    handleUpdateClose,
    updateOpen,
    status,
    handleStatusChange,
    handleStatusChangeSubmit,
    workOrderMapData,
  };
};
