import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosProgressEvent } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { Flow, ProjectImportErrorType, ProjectImportStatus } from '../constants/enum';
import { paths } from '../constants/paths';
import { GetManyQueryParams, PaginatedApiResponse } from '../interfaces';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { useFilterParams } from './filterParams';

export interface ProjectImportData {
  id: number;
  companyId: number;
  projectId: number;
  processedWorkOrders: number;
  totalWorkOrders: number;
  lastChangeDate: string;
  flow: Flow;
  startedDate: string;
  completedDate: string;
  mapping: Record<string, any> | null;
  isParsed: boolean;
  hasHeaders: boolean;
  filename: string;
  fileDownloadUrl: string;
  status: ProjectImportStatus;
  deletedAt: string;
}

export interface ProjectImportCreateVars {
  file: File;
  mappingId: number | null;
  projectId?: number;
  progressCallback?: (progressEvent: AxiosProgressEvent) => void;
}

export interface ProjectImportUpdateVars {
  mappingId?: number;
  hasHeaders?: boolean;
  progressCallback?: (progressEvent: AxiosProgressEvent) => void;
}

export interface ProjectImportFinalizeVars {
  mappingId?: number;
  hasHeaders?: boolean;
  companyId?: number;
}

export interface SampleData {
  fields: Record<string, number | null>;
  sample: string[][];
}

export interface ProjectImportErrorData {
  id: number;
  value: string;
  errorType: ProjectImportErrorType;
  rowNumber: number;
  columnNumber: number;
  columnName: string;
  line: string;
  validationRegex: string;
  deletedAt: string;
}

export interface ProjectImportGrouperWorkOrderData {
  workOrders: number[];
  assetGroup: string;
  assetMake: string;
  assetType: string;
  assetSize: string;
}

const getProjectImports = async (params?: GetManyQueryParams) => {
  try {
    const { data } = await axiosInstance.get('/project-import', {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getProjectImportSample = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/project-import/${id}/sample`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getSingleProjectImport = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/project-import/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getProjectImportErrors = async (id: number, params?: GetManyQueryParams) => {
  try {
    const { data } = await axiosInstance.get(`/project-import/${id}/errors`, {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getProjectImportWorkOrders = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/project-import/${id}/work-orders`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createProjectImport = async (projectImportVariables: ProjectImportCreateVars) => {
  try {
    const { file, mappingId, projectId, progressCallback } = projectImportVariables;

    const formData = new FormData();
    if (mappingId) {
      formData.append('mappingId', mappingId.toString());
    }
    formData.append('file', file);

    if (projectId) {
      formData.append('projectId', projectId.toString());
    }

    const { data } = await axiosInstance.post('/project-import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progressCallback && progressCallback(progressEvent);
      },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateProjectImport = async (id: number, projectImportData: ProjectImportUpdateVars) => {
  try {
    const { data } = await axiosInstance.patch(`/project-import/${id}`, projectImportData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const finalizeProjectImport = async (id: number, projectImportData: ProjectImportFinalizeVars) => {
  try {
    const { data } = await axiosInstance.put(`/project-import/${id}`, projectImportData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteProjectImport = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/project-import/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useProjectImports(
  options?: UseQueryOptions<PaginatedApiResponse<ProjectImportData>, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useQuery({
    queryKey: ['project-imports', ...parsedParams],
    queryFn: () => getProjectImports(searchParams),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useSingleProjectImport(
  id: number,
  options?: UseQueryOptions<ProjectImportData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useQuery({
    queryKey: id ? ['project-import', id] : undefined,
    queryFn: () => getSingleProjectImport(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      navigate(paths.PROJECT_IMPORTS);
    },
    ...options,
    enabled: !!id,
  });
}

export function useProjectImportSample(
  id: number,
  options?: UseQueryOptions<SampleData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['project-import-sample', id],
    queryFn: () => getProjectImportSample(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      navigate(paths.PROJECT_IMPORTS);
    },
    ...options,
    enabled: !!id,
  });
}

export function useProjectImportErrors(
  id: number,
  options?: UseQueryOptions<PaginatedApiResponse<ProjectImportErrorData>, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useQuery({
    queryKey: ['project-import-errors', id, ...parsedParams],
    queryFn: () => getProjectImportErrors(id, searchParams),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    enabled: !!id,
    ...options,
  });
}

export function useProjectImportWorkOrders(
  id: number,
  options?: UseQueryOptions<ProjectImportGrouperWorkOrderData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<ProjectImportGrouperWorkOrderData[], CustomApiError>(
    ['grouped-work-orders', id],
    () => getProjectImportWorkOrders(id),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      ...options,
    },
  );
}

export function useCreateProjectImport(
  newMapping: string | null,
  options?: UseMutationOptions<ProjectImportData, CustomApiError, ProjectImportCreateVars>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<ProjectImportData, CustomApiError, ProjectImportCreateVars>(
    (data) => createProjectImport(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newProjectImport) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['project-import', newProjectImport.id], newProjectImport);
        enqueueSnackbar(t('translation.notification.projectImportCreated'), {
          variant: 'success',
        });
        if (newMapping) {
          navigate(
            {
              pathname: paths.build(paths.PROJECT_IMPORT_MAPPING, newProjectImport.id),
              search: newMapping ? `mappingName=${encodeURIComponent(newMapping)}` : undefined,
            },
            { state: location.state || '' },
          );
        } else {
          navigate(
            {
              pathname: paths.build(paths.PROJECT_IMPORT_MAPPING, newProjectImport.id),
            },
            { state: location.state || '' },
          );
        }
      },
      ...options,
    },
  );
}

export function useUpdateProjectImport(
  newMapping: string | null,
  options?: UseMutationOptions<
    ProjectImportData,
    CustomApiError,
    { id: number; data: ProjectImportUpdateVars }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<
    ProjectImportData,
    CustomApiError,
    { id: number; data: ProjectImportUpdateVars }
  >(({ id, data }) => updateProjectImport(id, data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (newProjectImport) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['project-import', newProjectImport.id], newProjectImport);
      enqueueSnackbar(t('translation.notification.projectImportUpdated'), {
        variant: 'success',
      });
      if (newMapping) {
        navigate(
          {
            pathname: paths.build(paths.PROJECT_IMPORT_MAPPING, newProjectImport.id),
            search: newMapping ? `mappingName=${encodeURIComponent(newMapping)}` : undefined,
          },
          { state: location.state || '' },
        );
      } else {
        navigate(
          {
            pathname: paths.build(paths.PROJECT_IMPORT_MAPPING, newProjectImport.id),
          },
          { state: location.state || '' },
        );
      }
    },
    ...options,
  });
}

export function useFinalizeProjectImport(
  options?: UseMutationOptions<
    ProjectImportData,
    CustomApiError,
    { id: number; data: ProjectImportFinalizeVars }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return useMutation<
    ProjectImportData,
    CustomApiError,
    { id: number; data: ProjectImportFinalizeVars }
  >(({ id, data }) => finalizeProjectImport(id, data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (updatedProjectImport) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['project-import', updatedProjectImport.id], updatedProjectImport);
      queryClient.invalidateQueries(['project-imports']);
      enqueueSnackbar(t('translation.notification.projectImportFinalized'), {
        variant: 'success',
      });
      navigate({ pathname: paths.PROJECT_IMPORTS, search: location.state || '' });
    },
    ...options,
  });
}

export function useDeleteProjectImport(
  options?: UseMutationOptions<number, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return useMutation<number, CustomApiError, number>((id) => deleteProjectImport(id), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar(t('translation.notification.projectImportDeleted'), {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['project-imports', 'project-import-errors'] });
      navigate({ pathname: paths.PROJECT_IMPORTS, search: location.state || '' });
    },
    ...options,
  });
}
