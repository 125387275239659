import { Container, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useCreateProjectImport, useSingleProject } from '../../../hooks';
import { ImportFileUploadForm } from '../components/ImportFileUploadForm';
import { Flow, ProjectStatus } from '../../../constants/enum';
import { paths } from '../../../constants/paths';
import { useTranslation } from 'react-i18next';

export const UploadUpdate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { projectId } = useParams();
  const { data, isInitialLoading } = useSingleProject(Number(projectId));
  const [newMapping, setNewMapping] = useState<string | null>(null);

  const { mutate: createMutate, isLoading: isCreateLoading } = useCreateProjectImport(newMapping);

  if (isInitialLoading) {
    return null;
  }

  if (data?.status !== ProjectStatus.COMPLETE || data.archived || data.markedComplete) {
    return <Navigate to={{ pathname: paths.PROJECTS, search: location.state || '' }} />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography variant="h6">{t('translation.uploadNewUpdatePage.title')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, px: { xs: 1, md: 3 } }}>
            <Typography>{t('translation.uploadNewUpdatePage.text')}</Typography>
          </Grid>
          <ImportFileUploadForm
            mutate={createMutate}
            isSubmitting={isCreateLoading}
            projectId={Number(projectId)}
            setNewMapping={setNewMapping}
            newMapping={newMapping}
            flow={Flow.UPDATE}
          />
        </Grid>
      </Paper>
    </Container>
  );
};
