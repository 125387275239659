import { FC } from 'react';
import {
  Container,
  Paper,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MUIDataTableColumn } from 'mui-datatables';
import { ProjectImportErrorData } from '../../../../hooks/projectImports';
import { PaginatedApiResponse } from '../../../../interfaces';
import { DataTable } from '../../../../components';
import { useFilterParams } from '../../../../hooks/filterParams';
import { ProjectImportErrorType } from '../../../../constants/enum';
import { ErrorDetailsColumn } from './ErrorDetailsColumn';

interface ErrorsListProps {
  data?: PaginatedApiResponse<ProjectImportErrorData>;
  isLoading: boolean;
  isFetching: boolean;
}

export const ErrorsList: FC<ErrorsListProps> = ({ data, isLoading, isFetching }) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [{ where }] = useFilterParams();

  const columns: MUIDataTableColumn[] = [
    {
      label: t('translation.label.id'),
      name: 'id',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
        filterList: where?.id ? [where.id] : undefined,
      },
    },
    {
      label: `${t('translation.label.row')} #`,
      name: 'rowNumber',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
        filterList: where?.rowNumber ? [where.rowNumber] : undefined,
      },
    },
    {
      label: `${t('translation.label.column')} #`,
      name: 'columnNumber',
      options: {
        setCellProps: () => ({ style: { minWidth: '80px' } }),
        filterList: where?.columnNumber ? [where.columnNumber] : undefined,
      },
    },
    {
      label: t('translation.label.columnName'),
      name: 'columnName',
      options: {
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        filterList: where?.columnName ? [where.columnName] : undefined,
      },
    },
    {
      label: t('translation.label.value'),
      name: 'value',
      options: {
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        filterList: where?.value ? [where.value] : undefined,
      },
    },
    {
      label: t('translation.label.validationRegex'),
      name: 'validationRegex',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '150px' } }),
        filterList: where?.validationRegex ? [where.validationRegex] : undefined,
      },
    },
    {
      label: t('translation.label.errorType'),
      name: 'errorType',
      options: {
        setCellProps: () => ({ style: { minWidth: '150px' } }),
        filterList: where?.errorType ? [where.errorType] : undefined,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                variant="standard"
                select
                label={t('translation.label.errorType')}
                name="errorType"
                value={filterList[index][0] ?? ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                {Object.keys(ProjectImportErrorType).map((errorType) => (
                  <MenuItem key={errorType} value={errorType}>
                    {t(`translation.projectImportErrorType.${errorType}`)}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
        customBodyRender: (value) => {
          return <Box component="span">{t(`translation.projectImportErrorType.${value}`)}</Box>;
        },
      },
    },
    {
      label: ' ',
      name: 'line',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: lessThanSmall ? { width: '100%' } : { width: '180px' } }),
        customBodyRender: (value) => {
          if (value) {
            return <ErrorDetailsColumn data={value} />;
          }
          return null;
        },
      },
    },
  ];

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        <Grid container>
          <Grid item xs={12}>
            <DataTable
              data={data?.data}
              columns={columns}
              count={data?.meta.count}
              isLoading={isLoading}
              isFetching={isFetching}
              title="translation.projectImportErrorsListPage.title"
              options={{
                tableBodyMaxHeight: '435px',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
