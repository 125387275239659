import { FC, useState } from 'react';
import { MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableActionsMenu, ConfirmationModal } from '../../../components';
import { paths } from '../../../constants/paths';
import { useDeleteCompany } from '../../../hooks';

interface CompaniesActionsColumnProps {
  companyId: number;
}

export const CompaniesActionsColumn: FC<CompaniesActionsColumnProps> = ({ companyId }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useDeleteCompany();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableActionsMenu>
      <MenuItem
        sx={{ minWidth: 120, height: 40 }}
        component={Link}
        state={location.search || null}
        to={paths.build(paths.COMPANY_EDIT, companyId)}
      >
        {t('translation.button.edit')}
      </MenuItem>
      <MenuItem sx={{ minWidth: 120, height: 40, color: 'error.main' }} onClick={handleClickOpen}>
        {t('translation.button.delete')}
      </MenuItem>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        title="translation.companiesListPage.deleteCompanyModal.title"
        text="translation.companiesListPage.deleteCompanyModal.text"
        confirmActionButton={
          <LoadingButton
            loading={isLoading}
            color="error"
            variant="text"
            onClick={() => {
              if (!isLoading) {
                mutate(companyId);
                handleClose();
              }
            }}
          >
            {t('translation.button.delete')}
          </LoadingButton>
        }
      />
    </TableActionsMenu>
  );
};
