import { FC, memo } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useMapMarkers } from './useMapMarkers';
import { WorkOrdersMapDataEnhanced } from '../../view/Dashboard/interface';

const darkModeStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
];

interface WorkOrdersMapProps {
  workOrders: WorkOrdersMapDataEnhanced[];
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  zoom?: number;
}

export const WorkOrdersMap: FC<WorkOrdersMapProps> = memo(
  ({ workOrders, center = { lat: 30, lng: -99 }, zoom = 6 }) => {
    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });
    const theme = useTheme();
    const lessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const lessThanMed = useMediaQuery(theme.breakpoints.down('md'));

    const { setMap } = useMapMarkers({ workOrders });

    if (!process.env.REACT_APP_GOOGLE_API_KEY) {
      return null;
    }

    if (isLoaded) {
      return (
        <GoogleMap
          zoom={zoom}
          center={center}
          mapContainerStyle={{
            width: '100%',
            height: lessThanLarge ? (lessThanMed ? 300 : 400) : '100%',
            borderRadius: 4,
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            styles: theme.palette.mode === 'dark' ? darkModeStyles : null,
          }}
          onLoad={(map) => setMap(map)}
          onUnmount={() => setMap(null)}
        />
      );
    }

    if (loadError) {
      return null;
    }

    return <Box width="100%" height={lessThanLarge ? (lessThanMed ? 300 : 400) : '100%'} />;
  },
);
