import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface WorkOrderDataPointProps {
  label: string;
  value?: string | number | null;
}

export const WorkOrderDataPoint: FC<WorkOrderDataPointProps> = ({ label, value }) => {
  return (
    <Box>
      <Typography component="div" color="text.secondary">
        {label}:
      </Typography>
      <Typography component="div" sx={{ minHeight: 24 }}>
        {value ?? '-'}
      </Typography>
    </Box>
  );
};
