import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { UserData, UserInput } from './users';

interface ProfileUpdateInput extends Pick<UserInput, 'firstName' | 'lastName' | 'phoneNumber'> {}

const getProfile = async () => {
  try {
    const { data } = await axiosInstance.get('/profile');

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateProfile = async (userData: ProfileUpdateInput) => {
  try {
    const { data } = await axiosInstance.put('/profile', userData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useProfile(options?: UseQueryOptions<UserData, unknown>) {
  return useQuery<UserData, unknown>(['profile'], () => getProfile(), options);
}

export function useUpdateProfile(
  options?: UseMutationOptions<UserData, CustomApiError, ProfileUpdateInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<UserData, CustomApiError, ProfileUpdateInput>((data) => updateProfile(data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (user) => {
      // ✅ update detail view directly
      queryClient.setQueryData(['profile'], user);
      enqueueSnackbar(t('translation.notification.profileUpdated'), {
        variant: 'success',
      });
    },
    ...options,
  });
}
