import { FC, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { t } from 'i18next';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import { AxiosProgressEvent } from 'axios';
import { FileType } from '../../constants/enum';
import { ProjectDocumentTypes } from '../../constants/fileTypes';
import { ProjectDocumentData } from '../../hooks/projectDocuments';
import { FilesDropzone } from '../FilesDropzone';
import { ImageMediaCard } from '../ImageMediaCard';
import { PermissionWrapper } from '../../containers';
import { allAdminAndEmployeeRoles } from '../../constants/permissionGroups';
import { WorkOrderDocumentData } from '../../hooks/workOrderDocuments';

interface PhotosAndDocumentsProps {
  documents: ProjectDocumentData[] | WorkOrderDocumentData[];
  deleteDocument: (id: number) => void;
  uploadDocuments: (data: any) => Promise<any>;
  isUploadingDocuments: boolean;
}

export const PhotosAndDocuments: FC<PhotosAndDocumentsProps> = ({
  documents,
  deleteDocument,
  uploadDocuments,
  isUploadingDocuments,
}) => {
  const [files, setFiles] = useState<File[]>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleClose = () => {
    setFiles(undefined);
    setIsOpen(false);
  };

  const progressCallback = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = Math.round(percentComplete * 100);
      setProgress(percentComplete);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontSize={20} variant="h6">
            {t('translation.photosAndDocs.title')}
          </Typography>
          <PermissionWrapper whitelist={allAdminAndEmployeeRoles}>
            <Box>
              <Button
                sx={{ minWidth: 120 }}
                size="small"
                variant="outlined"
                color="inherit"
                onClick={() => setIsOpen(true)}
              >
                {t('translation.button.upload')}
              </Button>
            </Box>
          </PermissionWrapper>
          <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: { width: 800, maxWidth: '100%' },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {t('translation.photosAndDocs.newUploadModal.title')}
            </DialogTitle>
            <DialogContent>
              <Grid container display="flex" justifyContent="center">
                <Grid item xs={12}>
                  <Typography>{t('translation.photosAndDocs.newUploadModal.text')}</Typography>
                </Grid>
                <Grid item xs={10} mt={4}>
                  <FilesDropzone
                    title={t('translation.photosAndDocs.newUploadModal.dropFile')}
                    setFiles={setFiles}
                    files={files}
                    fileTypes={ProjectDocumentTypes}
                  />
                </Grid>
                <Grid item xs={10} my={4}>
                  <Grid container display="flex">
                    {files?.map((file, index) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        key={index}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={index % 2 === 0 ? { pr: 4 } : { pl: 4 }}
                      >
                        <Typography>{file.name}</Typography>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => {
                            setFiles((prevState) => {
                              if (!prevState) {
                                return undefined;
                              }
                              const newState = [...prevState];
                              newState.splice(index, 1);
                              return newState;
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button sx={{ minWidth: 120 }} color="inherit" variant="text" onClick={handleClose}>
                {t('translation.button.cancel')}
              </Button>
              <LoadingButton
                sx={{ minWidth: 120 }}
                loading={isUploadingDocuments}
                variant="contained"
                onClick={async () => {
                  if (!isUploadingDocuments && files && files.length > 0) {
                    await uploadDocuments({ files, progressCallback });
                    handleClose();
                  }
                }}
                loadingPosition={isUploadingDocuments ? 'start' : undefined}
              >
                {isUploadingDocuments ? `${progress}%` : t('translation.button.upload')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        gap={2}
        flexWrap="wrap"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        <Lightbox
          open={selectedIndex >= 0}
          close={() => setSelectedIndex(-1)}
          index={selectedIndex}
          slides={documents?.map((document) => ({
            type: document.fileType === FileType.VIDEO ? 'video' : 'image',
            src: document.fileUrl,
            sources: document.fileType === FileType.VIDEO && [
              {
                src: document.fileUrl,
                type: 'video/mp4',
              },
            ],
          }))}
          plugins={[Video]}
        />
        {documents && documents?.length > 0 ? (
          documents.map((document, index) => (
            <ImageMediaCard
              key={document.id}
              document={document}
              onDelete={deleteDocument}
              onClick={() => setSelectedIndex(index)}
            />
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 100,
              width: '100%',
            }}
          >
            <Typography>{t('translation.photosAndDocs.noDocuments')}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
