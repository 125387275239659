import { ChangeEvent, FC, FormEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Autocomplete,
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { UserRole } from '../../../../constants/enum';
import { paths } from '../../../../constants/paths';
import { CompanySummaryData } from '../../../../hooks/company';
import { UserData, UserInput } from '../../../../hooks/users';
import { useCreateUser, useUpdateUser } from '../../../../hooks';

interface UserFormProps {
  user?: UserData;
  companies: CompanySummaryData[];
}

export const UserForm: FC<UserFormProps> = ({ user, companies }) => {
  const location = useLocation();

  const [userData, setUser] = useState<UserInput>({
    email: user?.email || '',
    role: user?.role || undefined,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
    companyId: user?.company.id || undefined,
    hasLoginAccess: user?.hasLoginAccess || false,
  });

  const [submitted, setSubmitted] = useState(false);

  const createUser = useCreateUser();
  const updateUser = useUpdateUser();

  const handleSubmit: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      userData.email &&
      userData.role &&
      userData.firstName &&
      userData.lastName &&
      userData.companyId &&
      !createUser.isLoading &&
      !updateUser.isLoading
    ) {
      if (user) {
        const { email, ...otherData } = userData;
        updateUser.mutate({ id: user.id, data: otherData });
      } else {
        createUser.mutate(userData);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.email')}
            name="email"
            disabled={!!user}
            value={userData.email}
            error={submitted && !userData.email}
            onChange={!user ? handleChange : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            select
            fullWidth
            label={t('translation.label.role')}
            name="role"
            value={userData.role ?? ''}
            error={submitted && !userData.role}
            onChange={handleChange}
          >
            {Object.keys(UserRole).map((role) => (
              <MenuItem key={role} value={role}>
                {t(`translation.userRole.${role}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.firstName')}
            name="firstName"
            value={userData.firstName}
            error={submitted && !userData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.lastName')}
            name="lastName"
            value={userData.lastName}
            error={submitted && !userData.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            label={t('translation.label.phoneNumber')}
            name="phoneNumber"
            value={userData.phoneNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <Autocomplete
            sx={{ my: 1 }}
            disablePortal
            id="companyId"
            options={companies}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={companies.find((m) => m.id === userData.companyId) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translation.label.company')}
                error={submitted && !userData.companyId}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            onChange={(_, value) => {
              setSubmitted(false);
              setUser((prevState) => ({
                ...prevState,
                companyId: value?.id || undefined,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label={t('translation.label.hasLoginAccess')}
              name="hasLoginAccess"
              checked={userData.hasLoginAccess}
              onChange={(_, checked) => {
                setSubmitted(false);
                setUser((prevState) => ({
                  ...prevState,
                  hasLoginAccess: checked,
                }));
              }}
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', mt: 4, px: { xs: 1, md: 3 } }}
        >
          <Button
            color="inherit"
            variant="text"
            sx={{ minWidth: 120, mr: 2 }}
            component={Link}
            to={{ pathname: paths.USERS, search: location.state || '' }}
          >
            <Trans i18nKey="translation.button.cancel" />
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 120 }}
            type="submit"
            loading={createUser.isLoading || updateUser.isLoading}
          >
            <Trans i18nKey="translation.button.save" />
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
