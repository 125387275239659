import { CompanyType, UserRole } from '../constants/enum';

export const isSubcontractorType = (userType: CompanyType) =>
  userType === CompanyType.SUBCONTRACTOR;
export const isAdminType = (userType: CompanyType) => userType === CompanyType.ADMIN;
export const isUtilityType = (userType: CompanyType) => userType === CompanyType.UTILITY;

export const isAdminRole = (userRole: UserRole) => userRole === UserRole.ADMIN;
export const isEmployeeRole = (userRole: UserRole) => userRole === UserRole.EMPLOYEE;
export const isEndUserRole = (userRole: UserRole) => userRole === UserRole.END_USER;

export const isSuperAdmin = (userType: CompanyType, userRole: UserRole) =>
  isAdminType(userType) && isAdminRole(userRole);
export const isSuperAdminOrEmployee = (userType: CompanyType, userRole: UserRole) =>
  isAdminType(userType) && (isAdminRole(userRole) || isEmployeeRole(userRole));

export const isUtilityTypeAdminRole = (userType: CompanyType, userRole: UserRole) =>
  isUtilityType(userType) && isAdminRole(userRole);
