import { FormEventHandler, useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { AuthBoxLayout } from '../../../containers';

export const SignIn = () => {
  const { t } = useTranslation();
  const { error, submitForm, isPending, toResetPassword } = useAuthenticator();

  const [submit, setSubmit] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSubmit(true);
    if (username && password && !isPending) {
      submitForm({ username, password });
    }
  };

  return (
    <AuthBoxLayout
      cardActions={
        <>
          <Button
            variant="text"
            color="inherit"
            sx={{ textTransform: 'none' }}
            onClick={() => {
              toResetPassword();
            }}
          >
            <Trans i18nKey="translation.signInPage.forgotPassword" />
          </Button>
          <LoadingButton
            form="signIn"
            loading={isPending}
            sx={{ minWidth: 120 }}
            variant="contained"
            type="submit"
          >
            <Trans i18nKey="translation.signInPage.login" />
          </LoadingButton>
        </>
      }
    >
      <Typography sx={{ my: 3, fontWeight: 'bold' }} variant="h5" component="div">
        <Trans i18nKey="translation.signInPage.title" />
      </Typography>
      <Typography sx={{ my: 3 }} variant="body1" component="div">
        <Trans i18nKey="translation.signInPage.subtitle" />
      </Typography>
      {error && (
        <Alert sx={{ mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Box>
        <form id="signIn" onSubmit={handleSubmit}>
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="username"
            autoComplete="username"
            label={t('translation.label.username')}
            name="username"
            error={submit && !username}
            onChange={(event) => {
              setSubmit(false);
              setUsername(event.target.value);
            }}
          />
          <TextField
            sx={{ my: 1 }}
            fullWidth
            key="password"
            autoComplete="current-password"
            label={t('translation.label.password')}
            name="password"
            type="password"
            error={submit && !password}
            onChange={(event) => {
              setSubmit(false);
              setPassword(event.target.value);
            }}
          />
        </form>
      </Box>
    </AuthBoxLayout>
  );
};
