import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole, CompanyType } from '../../constants/enum';
import { paths } from '../../constants/paths';
import { useUserDataContext } from '../Layout/context';
import { isSuperAdmin } from '../../utils/permissions';

type CompanyTypeUserRoles = Array<{
  type: CompanyType;
  roles: UserRole[];
}>;

interface PermissionWrapperProps {
  whitelist?: CompanyTypeUserRoles;
  children?: ReactNode;
  navigate?: boolean;
  ownerId?: number;
}

const matchRoles = (
  whitelist: CompanyTypeUserRoles | undefined,
  userType: CompanyType | undefined,
  userRole: UserRole | undefined,
) => {
  if (!userType || !userRole) {
    return false;
  }

  // Allow if user is God Almighty (company type = ADMIN & role = ADMIN)
  if (userType === CompanyType.ADMIN && userRole === UserRole.ADMIN) {
    return true;
  }
  // If Roles decorator with no roles is applied, only users that pass the
  // (type = ADMIN & role = ADMIN) check are permitted to use the protected endpoint
  if (!whitelist || whitelist.length < 1) {
    return false;
  }

  // True if company type and user role for that company type are whitelisted
  // If no roles are specified for a listed company type - allow all roles of that company type
  const isWhitelisted = whitelist.some(
    (wl) => wl.type === userType && (!wl.roles || wl.roles.includes(userRole)),
  );

  return isWhitelisted;
};

export const PermissionWrapper: FC<PermissionWrapperProps> = ({
  whitelist,
  children,
  navigate = false,
  ownerId,
}) => {
  const data = useUserDataContext();

  const userRole = data?.role;
  const userType = data?.company.companyType;

  const matchFound = matchRoles(whitelist, userType, userRole);

  const ownerCheck = ownerId
    ? ownerId === data?.company.id || isSuperAdmin(userType, userRole)
    : true;

  if (matchFound && ownerCheck) {
    return <>{children}</>;
  }

  if (navigate) {
    return <Navigate to={paths.DASHBOARD} />;
  }
  return null;
};
