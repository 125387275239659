import { CSSProperties, Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useDropzone, FileRejection, Accept } from 'react-dropzone';
import { Box, Typography, useTheme } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';

interface FilesDropzoneProps {
  title: string;
  files?: File[];
  setFiles: Dispatch<SetStateAction<File[] | undefined>>;
  fileTypes?: Accept;
}

export const FilesDropzone: FC<FilesDropzoneProps> = ({ title, setFiles, files, fileTypes }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const baseStyle: CSSProperties = useMemo(
    () => ({
      borderWidth: 1,
      borderRadius: 3,
      borderColor: theme.palette.text.primary,
      borderStyle: 'dashed',
      color: theme.palette.primary.main,
      padding: '3rem 2rem',
      textAlign: 'center',
      outline: 'none',
      cursor: 'copy',
    }),
    [theme.palette.primary.main, theme.palette.text.primary],
  );

  const activeStyle = useMemo(
    () => ({
      borderColor: theme.palette.primary.main,
      background: `${theme.palette.primary.main}30`,
      color: theme.palette.text.primary,
    }),
    [theme.palette.primary.main, theme.palette.text.primary],
  );

  const acceptStyle = useMemo(
    () => ({
      borderColor: theme.palette.primary.main,
      background: `${theme.palette.primary.main}30`,
      color: theme.palette.text.primary,
    }),
    [theme.palette.primary.main, theme.palette.text.primary],
  );

  const rejectStyle = useMemo(
    () => ({
      borderColor: theme.palette.error.main,
      background: `${theme.palette.error.main}30`,
      color: theme.palette.text.primary,
    }),
    [theme.palette.error.main, theme.palette.text.primary],
  );

  const onDrop = useCallback(
    (accFiles: File[], rejFiles: FileRejection[]) => {
      setFiles((prevState) => (prevState ? [...prevState, ...accFiles] : accFiles));
    },
    [setFiles],
  );

  const filesNotEmpty = files && files.length > 0;

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: fileTypes,
    multiple: true,
  });

  // onDrop styles
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(filesNotEmpty ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      isDragActive,
      isDragAccept,
      filesNotEmpty,
      isDragReject,
      acceptStyle,
      activeStyle,
      baseStyle,
      rejectStyle,
    ],
  );

  return (
    <Box {...getRootProps({ style })}>
      <input {...getInputProps()} />

      <UploadFileIcon sx={{ color: 'text.primary', fontSize: '4rem' }} />
      <Typography fontSize={18} sx={{ marginTop: '1.3rem' }}>
        <Box component="span">{title}</Box>
      </Typography>

      <Box sx={{ color: 'text.primary', marginTop: '1rem' }}>
        {filesNotEmpty ? (
          <u>{t('translation.fileDropzone.addFiles')}</u>
        ) : (
          <>
            {t('translation.common.or')} <u>{t('translation.fileDropzone.browseYourPC')}</u>
          </>
        )}
      </Box>
    </Box>
  );
};
