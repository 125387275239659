import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';

export interface AssetClassificationData {
  id: number;
  name: string;
  deletedAt: string;
}

export interface AssetClassificationInput extends Pick<AssetClassificationData, 'name'> {}

const getAssetClassifications = async () => {
  try {
    const { data } = await axiosInstance.get('/asset-classification');

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createAssetClassification = async (assetClassification: AssetClassificationInput) => {
  try {
    const { data } = await axiosInstance.post(`/asset-classification`, assetClassification);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateAssetClassification = async (
  id: number,
  assetClassification: AssetClassificationInput,
) => {
  try {
    const { data } = await axiosInstance.put(`/asset-classification/${id}`, assetClassification);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteAssetClassification = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/asset-classification/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useAssetClassifications(
  options?: UseQueryOptions<AssetClassificationData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<AssetClassificationData[], CustomApiError>(
    ['asset-classifications'],
    () => getAssetClassifications(),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      ...options,
    },
  );
}

export function useCreateAssetClassification(
  options?: UseMutationOptions<AssetClassificationData, CustomApiError, AssetClassificationInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AssetClassificationData, CustomApiError, AssetClassificationInput>(
    (data) => createAssetClassification(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newAssetClassification) => {
        // ✅ update detail view directly
        queryClient.setQueryData(
          ['asset-classifications'],
          (oldData: AssetClassificationData[] | undefined) =>
            oldData ? [newAssetClassification, ...oldData] : oldData,
        );
        enqueueSnackbar(t('translation.notification.assetClassificationCreated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useUpdateAssetClassification(
  options?: UseMutationOptions<
    AssetClassificationData,
    CustomApiError,
    { id: number; data: AssetClassificationInput }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<
    AssetClassificationData,
    CustomApiError,
    { id: number; data: AssetClassificationInput }
  >(({ id, data }) => updateAssetClassification(id, data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (updatedClassification) => {
      // ✅ update detail view directly
      queryClient.setQueryData(
        ['asset-classifications'],
        (oldData: AssetClassificationData[] | undefined) => {
          if (oldData) {
            const updatedAssetClassifications = [...oldData];
            const index = updatedAssetClassifications.findIndex(
              (classification) => classification.id === updatedClassification.id,
            );

            if (index !== -1) {
              updatedAssetClassifications[index] = {
                ...updatedAssetClassifications[index],
                ...updatedClassification,
              };
            }
            return updatedAssetClassifications;
          }
          return oldData;
        },
      );
      enqueueSnackbar(t('translation.notification.assetClassificationUpdated'), {
        variant: 'success',
      });
    },
    ...options,
  });
}

export function useDeleteAssetClassification(
  options?: UseMutationOptions<number, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (assetClassificationId) => deleteAssetClassification(assetClassificationId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar(t('translation.notification.assetClassificationDeleted'), {
          variant: 'success',
        });
        queryClient.invalidateQueries({ queryKey: ['asset-classifications'] });
      },
      ...options,
    },
  );
}
