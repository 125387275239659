import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../config/axios';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';

export interface AssetSizeData {
  id: number;
  name: string;
  deletedAt: string;
}

export interface AssetSizeInput extends Pick<AssetSizeData, 'name'> {}

const getAssetSizes = async () => {
  try {
    const { data } = await axiosInstance.get('/asset-size');

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const createAssetSize = async (assetSize: AssetSizeInput) => {
  try {
    const { data } = await axiosInstance.post(`/asset-size`, assetSize);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateAssetSize = async (id: number, assetSize: AssetSizeInput) => {
  try {
    const { data } = await axiosInstance.put(`/asset-size/${id}`, assetSize);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteAssetSize = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/asset-size/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useAssetSizes(options?: UseQueryOptions<AssetSizeData[], CustomApiError>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<AssetSizeData[], CustomApiError>(['asset-sizes'], () => getAssetSizes(), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    ...options,
  });
}

export function useCreateAssetSize(
  options?: UseMutationOptions<AssetSizeData, CustomApiError, AssetSizeInput>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AssetSizeData, CustomApiError, AssetSizeInput>(
    (data) => createAssetSize(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (newAssetSize) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['asset-sizes'], (oldData: AssetSizeData[] | undefined) =>
          oldData ? [newAssetSize, ...oldData] : oldData,
        );
        enqueueSnackbar(t('translation.notification.assetSizeCreated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useUpdateAssetSize(
  options?: UseMutationOptions<AssetSizeData, CustomApiError, { id: number; data: AssetSizeInput }>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AssetSizeData, CustomApiError, { id: number; data: AssetSizeInput }>(
    ({ id, data }) => updateAssetSize(id, data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (updatedSize) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['asset-sizes'], (oldData: AssetSizeData[] | undefined) => {
          if (oldData) {
            const updatedAssetSizes = [...oldData];
            const index = updatedAssetSizes.findIndex((size) => size.id === updatedSize.id);

            if (index !== -1) {
              updatedAssetSizes[index] = {
                ...updatedAssetSizes[index],
                ...updatedSize,
              };
            }
            return updatedAssetSizes;
          }
          return oldData;
        });
        enqueueSnackbar(t('translation.notification.assetSizeUpdated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useDeleteAssetSize(options?: UseMutationOptions<number, CustomApiError, number>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<number, CustomApiError, number>(
    (assetSizeId) => deleteAssetSize(assetSizeId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar(t('translation.notification.assetSizeDeleted'), {
          variant: 'success',
        });
        queryClient.invalidateQueries({ queryKey: ['asset-sizes'] });
      },
      ...options,
    },
  );
}
