import { Suspense } from 'react';
import { Box } from '@mui/material';
import { Amplify } from '@aws-amplify/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import awsExports from './aws-exports';
import { ColorModeProvider } from './containers/ColorModeProvider';
import { routes } from './constants/routes';

Amplify.configure(awsExports);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const router = createBrowserRouter(routes);

  return (
    <QueryClientProvider client={queryClient}>
      <ColorModeProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Suspense>
              <RouterProvider router={router} />
            </Suspense>
          </Box>
        </SnackbarProvider>
      </ColorModeProvider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}
