import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flow, ProjectImportStatus } from '../../../constants/enum';
import { paths } from '../../../constants/paths';

interface ProjectImportsActionsColumnProps {
  projectImportId: number;
  status: ProjectImportStatus;
  hasMapping: boolean;
  flow: Flow;
}

export const ProjectImportsActionsColumn: FC<ProjectImportsActionsColumnProps> = ({
  projectImportId,
  status,
  hasMapping,
  flow,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const ViewErrorBtn = () => (
    <Button
      sx={{ textTransform: 'none', width: { xs: '100%', sm: 158 }, textAlign: 'center' }}
      color="inherit"
      variant="outlined"
      size="small"
      component={Link}
      state={location.search || null}
      to={paths.build(paths.PROJECT_IMPORT_ERRORS, projectImportId)}
    >
      {t('translation.button.viewError')}
    </Button>
  );

  const ContinueBtn = () => (
    <Button
      sx={{ textTransform: 'none', width: { xs: '100%', sm: 158 }, textAlign: 'center' }}
      color="inherit"
      variant="outlined"
      size="small"
      component={Link}
      state={location.search || null}
      to={paths.build(paths.PROJECT_CREATE, projectImportId)}
      disabled={ProjectImportStatus.PARSE_SUCCESS !== status}
    >
      {t('translation.button.continue')}
    </Button>
  );

  const StartMappingBtn = () => (
    <Button
      sx={{ textTransform: 'none', width: { xs: '100%', sm: 158 }, textAlign: 'center' }}
      color="inherit"
      variant="outlined"
      size="small"
      component={Link}
      state={location.search || null}
      to={
        hasMapping
          ? paths.build(paths.PROJECT_IMPORT_MAPPING, projectImportId)
          : paths.build(paths.PROJECT_IMPORT_CONTINUE, projectImportId)
      }
    >
      {t('translation.button.startMapping')}
    </Button>
  );

  switch (status) {
    case ProjectImportStatus.UPLOADED:
      return StartMappingBtn();
    case ProjectImportStatus.ERROR:
      return ViewErrorBtn();
    case ProjectImportStatus.IN_PROGRESS:
      return flow === Flow.IMPORT ? ContinueBtn() : null;
    case ProjectImportStatus.PARSE_SUCCESS:
      return ContinueBtn();
    default:
      return <Box sx={{ height: 30.75, visibility: 'hidden' }} />;
  }
};
