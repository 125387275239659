import { useParams } from 'react-router-dom';
import { useAssetModels, useProjectImportWorkOrders, useSingleProjectImport } from '../../../hooks';
import { ManageAssets } from './components/ManageAssets';

export const FinalizeProject = () => {
  const { projectImportId } = useParams();

  const {
    data: projectImport,
    isLoading,
    isFetching,
  } = useSingleProjectImport(Number(projectImportId));
  const { data: assetModels } = useAssetModels();

  const { data: groupedWorkOrders, isLoading: isGroupedWorkOrdersLoading } =
    useProjectImportWorkOrders(Number(projectImportId));

  if (
    isLoading ||
    isFetching ||
    !projectImport?.projectId ||
    isGroupedWorkOrdersLoading ||
    !groupedWorkOrders
  ) {
    return null;
  }

  return (
    <ManageAssets
      assetModels={assetModels?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []}
      projectId={projectImport?.projectId}
      groupedWorkOrders={groupedWorkOrders}
    />
  );
};
