import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useProjectDetailsContext } from './useProjectDetailsContext';
import {
  useAssetModels,
  useCreateReplacementGroup,
  useDeleteReplacementGroup,
  useUpdateReplacementGroup,
} from '../../../../hooks';
import { ReplacementGroupInput } from '../../../../hooks/replacementGroups';

export const useProjectAssets = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { projectAssets, isAssetsLoading, replacementGroups, isReplacementGroupsLoading } =
    useProjectDetailsContext();

  const { data: assetModels } = useAssetModels();

  const { mutate: createGroup } = useCreateReplacementGroup();
  const { mutate: deleteGroup } = useDeleteReplacementGroup();
  const { mutate: updateGroup } = useUpdateReplacementGroup();

  const [open, setOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const handleAddReplacementGroup = useCallback(
    (group: ReplacementGroupInput) => {
      createGroup({
        ...group,
        assetModels: group.assetModels.map((assetModel) => ({ id: assetModel.id })),
        projectId: Number(projectId),
      });
    },
    [createGroup, projectId],
  );

  const handleEditReplacementGroup = useCallback(
    (id: number, group: ReplacementGroupInput) => {
      updateGroup({
        id,
        data: {
          ...group,
          assetModels: group.assetModels.map((assetModel) => ({ id: assetModel.id })),
        },
      });
    },
    [updateGroup],
  );

  const handleDeleteReplacementGroup = useCallback(
    (id: number) => {
      deleteGroup(id);
    },
    [deleteGroup],
  );

  const assetsSummary = useMemo(() => projectAssets?.assetsSummary ?? [], [projectAssets]);
  const assetsBySubcontractor = useMemo(
    () => projectAssets?.assetsBySubcontractor ?? [],
    [projectAssets],
  );

  const assetsSummaryColumns: MUIDataTableColumn[] = useMemo(
    () => [
      {
        label: t('translation.label.name'),
        name: 'assetName',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.manufacturer'),
        name: 'assetManufacturer',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.classification'),
        name: 'assetClassification',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.size'),
        name: 'assetSize',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.completed'),
        name: 'completed',
        options: {
          setCellHeaderProps: () => ({ style: { textTransform: 'uppercase !important' } }),
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.enum.RTU'),
        name: 'rtu',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.enum.UNABLE'),
        name: 'unable',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.remaining'),
        name: 'assigned',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.totalWorkOrders'),
        name: 'totalWorkOrders',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
    ],
    [t],
  );

  const assetsBySubcontractorColumns: MUIDataTableColumn[] = useMemo(
    () => [
      {
        label: t('translation.label.name'),
        name: 'assetName',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.manufacturer'),
        name: 'assetManufacturer',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.classification'),
        name: 'assetClassification',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.size'),
        name: 'assetSize',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
      {
        label: t('translation.label.subcontractor'),
        name: 'companyName',
        options: {
          setCellProps: () => ({ style: { minWidth: '170px' } }),
        },
      },
      {
        label: t('translation.label.installed'),
        name: 'installed',
        options: {
          setCellProps: () => ({ style: { minWidth: '80px' } }),
        },
      },
    ],
    [t],
  );

  return {
    assetsSummary,
    assetsBySubcontractor,
    assetsSummaryColumns,
    assetsBySubcontractorColumns,
    isAssetsLoading,
    replacementGroups,
    isReplacementGroupsLoading,
    open,
    assetModels: assetModels?.sort((a, b) => (a.name > b.name ? 1 : -1)) || [],
    toggleModal,
    handleAddReplacementGroup,
    handleEditReplacementGroup,
    handleDeleteReplacementGroup,
  };
};
