import { FC, ReactNode, useState } from 'react';
import { Box, Toolbar } from '@mui/material';
import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { getNavItemsForRole } from './constants';
import { useProfile } from '../../hooks';
import { UserDataContext } from './context';

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const { data, isLoading } = useProfile();

  const userRole = data?.role;
  const userType = data?.company.companyType;

  const navItems = getNavItemsForRole(userType, userRole);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <>
      <Header navItems={navItems} isLoading={isLoading} handleDrawerToggle={handleDrawerToggle} />
      <Sidebar
        navItems={navItems}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        {data ? <UserDataContext.Provider value={data}>{children}</UserDataContext.Provider> : null}
      </Box>
    </>
  );
};
