import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
  useMutation,
  UseMutationOptions,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AxiosProgressEvent } from 'axios';
import FileSaver from 'file-saver';
import axiosInstance from '../config/axios';
import { ProjectProgress, ProjectStatus } from '../constants/enum';
import { GetManyQueryParams, PaginatedApiResponse } from '../interfaces';
import { CustomApiError, errorHandling, getErrorDisplayMessage } from '../utils/errors';
import { useFilterParams } from './filterParams';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../constants/paths';
import { CompanyData } from './company';

export interface ProjectData {
  id: number;
  company: CompanyData;
  status: ProjectStatus;
  isInitialized: true;
  isMultiYear: true;
  name: string;
  startDate: string;
  dueDate: string;
  photoFilename: string;
  photoUrl: string;
  subcontractors: CompanyData[];
  archived: boolean;
  deletedAt: string;
  isLoyalty: boolean;
  markedComplete: boolean;
  clientId: string;
  completedPercent: number;
  progressStatus: ProjectProgress;
}

export interface DashboardProjectData
  extends Pick<ProjectData, 'id' | 'name' | 'startDate' | 'dueDate' | 'completedPercent'> {}

export interface DashboardProjectsParams {
  startDate?: string;
  endDate?: string;
}

export interface ProjectInput {
  projectImportId: number;
  companyId: number;
  subcontractors: Array<{ id: number }>;
  name: string;
  startDate?: string;
  dueDate?: string;
  isLoyalty?: boolean;
}
export interface WorkOrderGroupsData {
  replacementGroupAssignments: Array<{
    replacementGroupId: number;
    workOrders: number[];
  }>;
}

export interface ProjectReportData {
  filename: string;
  contentType: string;
  data: string;
}

export interface UpdateClientIdParams {
  projectId: number;
  clientId: string;
}

export interface UpdateSubcontractorsParams {
  projectId: number;
  subcontractors: number[];
}

interface ProjectImageVariables {
  file: File;
  projectId: number;
  progressCallback?: (progressEvent: AxiosProgressEvent) => void;
}

const getProjects = async (params?: GetManyQueryParams) => {
  try {
    const { data } = await axiosInstance.get('/project', {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getDashboardProjects = async (params?: DashboardProjectsParams) => {
  try {
    const { data } = await axiosInstance.get('/project/dashboard', {
      params,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getSingleProject = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(`/project/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const getProjectReport = async (id: number) => {
  try {
    const { data, headers } = await axiosInstance.get(`/project/${id}/report`, {
      responseType: 'stream',
    });

    const contentDisposition = headers['content-disposition'];
    const startFileNameIndex = contentDisposition.indexOf('"') + 1;
    const endFileNameIndex = contentDisposition.lastIndexOf('"');
    const filename: string =
      contentDisposition.substring(startFileNameIndex, endFileNameIndex) || '';
    const contentType = (headers['content-type'] as string) || '';

    return { filename, contentType, data };
  } catch (error) {
    errorHandling(error);
  }
  return null;
};

const createProject = async (projectData: ProjectInput) => {
  try {
    const { data } = await axiosInstance.post(`/project`, projectData);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const finalizeProject = async (id: number, workOrderGroups: WorkOrderGroupsData) => {
  try {
    const { data } = await axiosInstance.put(`/project/${id}`, workOrderGroups);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const addProjectImage = async (projectImageVariables: ProjectImageVariables) => {
  try {
    const { file, projectId, progressCallback } = projectImageVariables;
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axiosInstance.post(`/project/${projectId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progressCallback && progressCallback(progressEvent);
      },
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const deleteProject = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/project/${id}`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const archiveProject = async (id: number) => {
  try {
    const { data } = await axiosInstance.post(`/project/${id}/archive`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const completeProject = async (id: number) => {
  try {
    const { data } = await axiosInstance.post(`/project/${id}/complete`);

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateClientId = async (params: UpdateClientIdParams) => {
  try {
    const { projectId, clientId } = params;
    const { data } = await axiosInstance.post(`/project/${projectId}/client-id`, {
      clientId,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

const updateSubcontractors = async (params: UpdateSubcontractorsParams) => {
  try {
    const { projectId, subcontractors } = params;
    const { data } = await axiosInstance.post(`/project/${projectId}/subcontractors`, {
      subcontractors,
    });

    return data;
  } catch (error) {
    errorHandling(error);
  }
};

export function useProjects(
  options?: UseQueryOptions<PaginatedApiResponse<ProjectData>, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useFilterParams();

  const parsedParams = Object.entries(searchParams)
    .map(([key, value]) => {
      if (value) return [key, value];
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((param) => JSON.stringify(param));

  return useQuery({
    queryKey: ['projects', ...parsedParams],
    queryFn: () => getProjects(searchParams),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useGetDashboardProjects(
  params?: DashboardProjectsParams,
  options?: UseQueryOptions<DashboardProjectData[], CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery({
    queryKey: params ? ['projects-dashboard', ...Object.entries(params)] : ['projects-dashboard'],
    queryFn: () => getDashboardProjects(params),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    keepPreviousData: true,
    ...options,
  });
}

export function useSingleProject(
  id: number,
  options?: UseQueryOptions<ProjectData, CustomApiError>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['projects', id],
    queryFn: () => getSingleProject(id),
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
      navigate(paths.PROJECTS);
    },
    ...options,
    enabled: !!id,
  });
}

export function useCreateProject(
  options?: UseMutationOptions<ProjectData, CustomApiError, ProjectInput>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<ProjectData, CustomApiError, ProjectInput>((data) => createProject(data), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    ...options,
  });
}

export function useFinalizeProject(
  options?: UseMutationOptions<
    ProjectData,
    CustomApiError,
    { id: number; data: WorkOrderGroupsData }
  >,
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<ProjectData, CustomApiError, { id: number; data: WorkOrderGroupsData }>(
    ({ id, data }) => finalizeProject(id, data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['projects'] });
        enqueueSnackbar(t('translation.notification.projectFinalized'), {
          variant: 'success',
        });
        navigate(paths.PROJECTS);
      },
      ...options,
    },
  );
}

export function useAddProjectImage(
  options?: UseMutationOptions<ProjectData, CustomApiError, ProjectImageVariables>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<ProjectData, CustomApiError, ProjectImageVariables>(
    (data) => addProjectImage(data),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      ...options,
    },
  );
}

export function useDeleteProject(options?: UseMutationOptions<number, CustomApiError, number>) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return useMutation<number, CustomApiError, number>((projectId) => deleteProject(projectId), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      enqueueSnackbar(t('translation.notification.projectDeleted'), {
        variant: 'success',
      });
      if (!matchPath({ path: paths.PROJECTS }, location.pathname)) {
        navigate(paths.PROJECTS);
      }
    },
    ...options,
  });
}

export function useArchiveProject(
  options?: UseMutationOptions<ProjectData, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<ProjectData, CustomApiError, number>(
    (projectId) => archiveProject(projectId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (project) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['projects', project.id], project);
        enqueueSnackbar(t('translation.notification.projectArchived'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useCompleteProject(
  options?: UseMutationOptions<ProjectData, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<ProjectData, CustomApiError, number>(
    (projectId) => completeProject(projectId),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (project) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['projects', project.id], project);
        enqueueSnackbar(t('translation.notification.projectCompleted'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useGetProjectReport(
  options?: UseMutationOptions<ProjectReportData | null, CustomApiError, number>,
) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((projectId) => getProjectReport(projectId), {
    onError: (error) => {
      const errorMessage = getErrorDisplayMessage(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (projectReport) => {
      if (projectReport) {
        const { data, contentType, filename } = projectReport;
        FileSaver.saveAs(new Blob([data], { type: contentType }), filename);
      }
    },
    ...options,
  });
}

export function useProjectUpdateClientId(
  options?: UseMutationOptions<ProjectData, CustomApiError, UpdateClientIdParams>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<ProjectData, CustomApiError, UpdateClientIdParams>(
    (params) => updateClientId(params),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (project) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['projects', project.id], project);
        enqueueSnackbar(t('translation.notification.clientIdUpdated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}

export function useProjectUpdateSubcontractors(
  options?: UseMutationOptions<ProjectData, CustomApiError, UpdateSubcontractorsParams>,
) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<ProjectData, CustomApiError, UpdateSubcontractorsParams>(
    (params) => updateSubcontractors(params),
    {
      onError: (error) => {
        const errorMessage = getErrorDisplayMessage(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      onSuccess: (project) => {
        // ✅ update detail view directly
        queryClient.setQueryData(['projects', project.id], project);
        enqueueSnackbar(t('translation.notification.subcontractorsUpdated'), {
          variant: 'success',
        });
      },
      ...options,
    },
  );
}
