import { FC } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useCompaniesSummary,
  useProjectImportSample,
  useSingleProjectImport,
} from '../../../hooks';
import { MappingTable } from './components/MappingTable';
import { SkeletonTable } from '../../../components';
import { CompanyType } from '../../../constants/enum';

export const Mapping: FC = () => {
  const { projectImportId } = useParams();
  const { t } = useTranslation();

  const { data: projectImport, isLoading: isProjectImportLoading } = useSingleProjectImport(
    Number(projectImportId),
  );
  const { data, isLoading } = useProjectImportSample(Number(projectImportId));
  const { data: companies, isLoading: isCompaniesLoading } = useCompaniesSummary([
    CompanyType.SUBCONTRACTOR,
  ]);

  const rows = data?.sample ?? [];
  const longestSample = data?.sample ? Math.max(...rows.map((row) => row.length)) : 0;
  const headersArray = Array(longestSample).fill('-');

  return (
    <Container maxWidth={false} sx={{ py: 3, mt: 4 }}>
      <Paper sx={{ px: 3, py: 4 }}>
        {isLoading || isProjectImportLoading || isCompaniesLoading ? (
          <Grid container>
            <Grid item>{t('translation.projectImportMappingPage.text1')}</Grid>
            <Grid item mt={3}>
              <SkeletonTable title={t('translation.projectImportMappingPage.title')} />
            </Grid>
          </Grid>
        ) : (
          projectImport && (
            <MappingTable
              headers={headersArray}
              rows={rows}
              projectImport={projectImport}
              mappingFields={data?.fields || {}}
              companies={companies || []}
            />
          )
        )}
      </Paper>
    </Container>
  );
};
